import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Token from '../api/Token';

const LoginRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			Token.getToken() === '' ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/", state: { from: props.location } }} />
			)
		}
	/>
);

export default LoginRoute;
import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';

export class MaskTextField extends Component {

    TextMaskNumberProcess = (props) => {
        const { inputRef, ...other } = props;        
        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}    
                mask={this.props.mask}             
                showMask
            />
        );
    }

    render() {           
        const id = `ID${Math.trunc(Math.random() * 10000000)}`;                            
        return (
            <FormControl fullWidth={this.props.fullWidth} disabled={this.props.disabled} onBlur={this.props.onBlur}>
                <InputLabel htmlFor={id} shrink>{this.props.label}</InputLabel>              
                <Input                                                    
                    value={this.props.value}
                    onChange={(event) => {
                        this.props.onChange(event);
                    }}
                    id={id}                                                    
                    inputComponent={this.TextMaskNumberProcess}
                    onBlur={this.setLaborCourtCode}                    
                />
            </ FormControl>
        );
    }
};

export default MaskTextField;

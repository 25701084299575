import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import DateTextField from '../../../utils/DateTextField';
import DateTimeTextField from '../../../utils/DateTimeTextField';

export class DynamicTextField extends Component {

    state = {
        id: '',
        typeDateValues: [{id: '@FIXED', description: 'Data Fixa'}, 
                        {id: '@TODAY', description: 'Hoje'}, 
                        {id: '@FIRST_DAY_MONTH', description: 'Primeiro dia mês'}, 
                        {id: '@LAST_DAY_MONTH', description: 'Último dia mês'}]
    }

    render() {
        switch (this.props.dataType) {
            case 'INTEGER':
                return (
                    <TextField
                        disabled={this.props.disabled}
                        label="Valor"
                        type="number"
                        fullWidth
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        onBlur={() => {
                            if (this.props.value !== '') {
                                this.props.onChange(parseInt(this.props.value))
                            }
                        }}
                    />
                );
            case 'ID':
                return (
                    <TextField
                        disabled={this.props.disabled}
                        label="Valor"
                        type="number"
                        fullWidth
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        onBlur={() => {
                            if (this.props.value !== '') {
                                this.props.onChange(parseInt(this.props.value))
                            }
                        }}
                    />
                );
            case 'FLOAT':
                return (
                    <TextField
                        disabled={this.props.disabled}
                        label="Valor"
                        fullWidth
                        type="number"
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        onBlur={() => {
                            if (this.props.value !== '') {
                                this.props.onChange(parseFloat(this.props.value).toFixed(2))
                            }
                        }}
                    />
                );
            case 'MONEY':
                return (
                    <TextField
                        disabled={this.props.disabled}
                        label="Valor"
                        fullWidth
                        type="number"
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        onBlur={() => {
                            if (this.props.value !== '') {
                                this.props.onChange(parseFloat(this.props.value).toFixed(2))
                            }
                        }}
                    />
                );
            case 'TEXT':
                return (
                    <TextField
                        disabled={this.props.disabled}
                        label="Valor"
                        fullWidth
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                    />
                );
            case 'DATE':                

                let dateValue = '';
                let typeDateValue = '@FIXED';
                if (this.props.value.dateValue !== undefined) {
                    dateValue = this.props.value.dateValue
                }
                if (this.props.value.typeDateValue !== undefined) {
                    typeDateValue = this.props.value.typeDateValue
                }

                return (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <FormControl fullWidth disabled={this.props.disabled}>
                                <InputLabel htmlFor="select-filter-condition-value">Valor Padrão</InputLabel>
                                <Select
                                    value={typeDateValue}
                                    onChange={(e) => {
                                        if (e.target.value !== '@FIXED') {
                                            dateValue = '';
                                        }
                                        let value = {
                                            dateValue: dateValue,
                                            typeDateValue: e.target.value
                                        }
                                        this.props.onChange(value);
                                    }}
                                    inputProps={{ id: "select-field-filters-columns-condition" }}
                                >
                                    {this.state.typeDateValues.map((typeDateValue, index) => (
                                        <MenuItem value={typeDateValue.id} key={index}>{typeDateValue.description}</MenuItem>
                                    ))}
                                </Select>
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <DateTextField
                                disabled={this.props.disabled || typeDateValue !== '@FIXED'}
                                fullWidth
                                label="Data"
                                value={dateValue}
                                onChange={(date) => {
                                    let value = {
                                        dateValue: date,
                                        typeDateValue: typeDateValue
                                    }
                                    this.props.onChange(value);
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            case 'DATETIME':
                return (
                    <DateTimeTextField
                        disabled={this.props.disabled}
                        fullWidth
                        label="Data/Hora"
                        value={this.props.value}
                        onChange={this.props.onChange}
                    />
                );
            case 'DROPDOWN':
                return (
                    <FormControl fullWidth disabled={this.props.disabled}>
                        <InputLabel htmlFor="select-filter-condition-value">Valor</InputLabel>
                        <Select
                            value={this.props.value}
                            onChange={(e) => this.props.onChange(e.target.value)}
                            inputProps={{ id: "select-field-filters-columns-condition" }}
                        >
                            {(this.props.values || []).map((value, index) => (
                                <MenuItem value={value.id} key={index}>{value.description}</MenuItem>
                            ))}
                        </Select>
                    </ FormControl>
                );
            default:
                return 'ERROR FIELD'
        }
    }
};

export default DynamicTextField;

import api from '../api';

export async function getCaseFile() {
    try {
        const response = await api.get('/caseFile');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveCaseFile(caseFile) {
    try {
        let response;
        if (caseFile.idCaseFile === null) {
            response = await api.post('/casefile', caseFile);
        } else {
            response = await api.put(`/casefile/${caseFile.idCaseFile}`, caseFile);
        }
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function deleteCaseFile(idCaseFile) {
    try {
        const response = await api.delete(`/casefile/${idCaseFile}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
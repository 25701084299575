import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';

import TableResponsive from '../../utils/TableResponsive';
import SelectGoal from '../../genericTextField/SelectGoal';

import { getProcessesOut} from '../../../api/consumer/query';
import { getNameMonth, getMonths } from '../../utils/DataFormat';
import { getUser } from '../../../api/consumer/user';

export class ProcessesOut extends Component {

    state = {
        processesOuts: [],
        loading: false,
        idGoal: '',
        year: new Date().getFullYear(),
        month:  (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
    }    

    componentDidMount() {
        this.loading();
    }

    loading = async () => {
        try {

            const {year, month, idGoal} = this.state;

            if (this.state.year === '' && this.state.month !== '') {
                global.SnackBar.show('WARNING', 'Informe a ano');
                return;
            }

            let filter = {}
            if (year) {
                filter.year = year;
            }
            if (month) {
                filter.month = month;
            }
            if (idGoal) {
                filter.idGoal = idGoal;
            }                        

            this.setState({loading: true})
            const response = await getProcessesOut(filter);
            this.setState({
                processesOuts: response.processesOuts,
                loading: false
            });
            
        } catch (error) {
            this.setState({
                processesOuts: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        let totOut = 0;
        this.state.processesOuts.forEach(processesOut => {            
            let dateCell = {                                      
                nameLaborCourt: `${processesOut.code} - ${processesOut.name}`,
                descriptionGoal: processesOut.description,
                year: processesOut.year,
                month: getNameMonth(processesOut.month).dsMonth,
                count: processesOut.count,
            }

            totOut += parseInt(processesOut.count);

            data.push(dateCell);            
        });

        if (data.length > 0) {
            data.push({
                nameLaborCourt: <b>Total</b>,
                year: '',
                month: '',
                count: <b>{totOut}</b>,
            });
        }

        return data;
    }

    loadingUsers = async () => {
        try {            
            const response = await getUser();
            this.setState({
                users: response.users,
                loadingUsers: false
            });
        } catch (error) {
            this.setState({
                users: [],
                loadingUsers: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    onClickArithmetician = () => {
        if (this.state.users && !this.clickUsers) {                      
            this.clickUsers = true;
            this.loadingUsers();
        }        
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridList} spacing={16} direction="column">
                <Paper className={classes.paper} elevation={12}>                
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                      
                        <Typography variant="h5" color="inherit" className={classes.fontTitle}>
                            Saída de Processos
                        </Typography>
                    </Grid>
                    <Grid container spacing={16} direction="row" style={{paddingTop: '1rem'}}>
                        <Grid item xs={4} sm={6} md={2} lg={2} xl={1}>  
                            <TextField                  
                                fullWidth          
                                label="Ano"
                                value={this.state.year}
                                onChange={(e) => this.setState({year: e.target.value})}
                                type="number"                                                     
                            />
                        </Grid> 
                        <Grid item xs={8} sm={6} md={4} lg={3} xl={2}> 
                            <FormControl fullWidth>
                                <InputLabel htmlFor="select-month">Mês</InputLabel>
                                <Select                                                                                
                                    value={this.state.month}
                                    onChange={(event) => {this.setState({month: event.target.value})}}
                                    inputProps={{id: 'select-month'}}                                    
                                >
                                    <MenuItem value={''}></MenuItem>
                                    {getMonths().map((month, index) => (
                                        <MenuItem value={month.cdMonth} key={index}>{month.dsMonth}</MenuItem> 
                                    ))}
                                </Select>   
                            </ FormControl>
                        </Grid>
                        <Grid item xs={10} sm={11} md={6} lg={6} xl={6}>
                            <SelectGoal 
                                fullWidth   
                                value={this.state.idGoal}
                                onChange={(event) => this.setState({ idGoal: event.target.value })}   
                                emptyItem                         
                            />
                        </Grid>   
                        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>  
                            <IconButton onClick={this.loading}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop: '1rem'}}>
                        <TableResponsive
                            columns={columns}
                            values={this.data()}
                            loading={this.state.loading}
                            fontContent={{fontSize: '0.9rem'}}
                        />
                    </Grid>
                </Paper>                
            </Grid>
        );
    }
};

const columns = [
    {
        id: 'nameLaborCourt',
        label: 'VARA TRABALHISTA'
    },
    {
        id: 'descriptionGoal',
        label: 'FINALIDADE'
    }, 
    {
        id: 'year',
        label: 'ANO'
    },
    {
        id: 'month',
        label: 'MÊS'
    },
    {
        id: 'count',
        label: 'QUANTIDADE'
    },            
]

const styles = {
    root: {
        flexGrow: 1,
    },
    fontTitle: {
        fontSize: '1.1rem',
        textAlign: 'center'
    },
    paper: {
        padding: '20px'
    }
}

ProcessesOut.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProcessesOut);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/SearchRounded';
import { FaMoneyBillAlt } from 'react-icons/fa';

import RegisterPaymentProcess from './RegisterPaymentProcess';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';
import OpenReport from '../Report/OpenReport';
import DateTextField from '../../utils/DateTextField';
import Profile from '../../utils/Profile';

import paymentProcessAPI from '../../../api/consumer/paymentProcess';
import { formatDateTimeBRZ, getNameMonth } from '../../utils/DataFormat';
import { formatMoneySymbol } from '../../utils/NumberFormat';
import { getUser } from '../../../api/consumer/user';

export class ListPaymentProcess extends Component {

    state = {
        open: false,
        paymentProcesses: [],
        paymentProcess: null,
        messageDelete: null,
        initialDate: this.getInitialDate(),
        finalDate: new Date(),
        loading: true,
        users: [],
        loadingUsers: true,
        idArithmetician: '',
    }

    getInitialDate() {
        let date = new Date();
        date.setDate(date.getDate() - 90);
        return date;
    }

    componentWillMount() {
        this.loadingPaymentProcesss();
    }

    loadingPaymentProcesss = async () => {
        try {
            if (this.state.initialDate === '') {
                global.SnackBar.show('WARNING', 'Informe a data inicial');
                return;
            }
            if (this.state.finalDate === '') {
                global.SnackBar.show('WARNING', 'Informe a data final');
                return;
            }
            
            let initialDate = new Date(this.state.initialDate);
            initialDate.setHours(0);
            initialDate.setMinutes(0);
            initialDate.setSeconds(0);
            initialDate.setMilliseconds(0);

            let finalDate = new Date(this.state.finalDate);
            finalDate.setHours(23);
            finalDate.setMinutes(59);
            finalDate.setSeconds(59);
            finalDate.setMilliseconds(999);

            this.setState({ 
                paymentProcesses: [],
                loading: true                
            });
            let param = {
                initialDateCreated: new Date(initialDate).toISOString(),
                finalDateCreated: new Date(finalDate).toISOString()                
            }
            if (this.state.idArithmetician > 0) {
                param.idArithmetician = this.state.idArithmetician;
            }
            const response = await paymentProcessAPI('GET', param);
            this.setState({
                paymentProcesses: response.paymentProcesses,
                loading: false
            });
        } catch (error) {
            this.setState({                
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    loadingUsers = async () => {
        try {            
            const response = await getUser();
            this.setState({
                users: response.users,
                loadingUsers: false
            });
        } catch (error) {
            this.setState({
                users: [],
                loadingUsers: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        this.state.paymentProcesses.forEach(paymentProcess => {
            let dateCell = {                
                idPaymentProcess: paymentProcess.idPaymentProcess,
                createdAt: formatDateTimeBRZ(new Date(paymentProcess.createdAt)), 
                name: paymentProcess.Arithmetician.name,               
                month: getNameMonth(paymentProcess.month).dsMonth,
                year: paymentProcess.year,
                amount: formatMoneySymbol(paymentProcess.amount),
                action: this.actions(paymentProcess)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (paymentProcess) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => OpenReport('R002', {idPaymentProcess: paymentProcess.idPaymentProcess, showSignature: true})}>
                <PrintIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(paymentProcess)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (paymentProcess = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            paymentProcess,
        });
        if (!open) {
            this.loadingPaymentProcesss();
        }
    }

    showMessageDelete = (paymentProcess = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Processamento',
                message: <span>Deseja realmente excluir o processamento <b>“{paymentProcess.Arithmetician.name} - {getNameMonth(paymentProcess.month).dsMonth}/{paymentProcess.year} - {formatMoneySymbol(paymentProcess.amount)}” ?</b><br /><br /><b style={{color: 'red'}}>Lembre-se de recolher as guias que foram assinadas!</b></span>,
                ok: () => this.onDeletePaymentProcess(paymentProcess),
                cancel: true
            }
        });
    }

    onDeletePaymentProcess = async (paymentProcess) => {
        try {            
            await paymentProcessAPI('DELETE', {idPaymentProcess: paymentProcess.idPaymentProcess})
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingPaymentProcesss();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }    
    
    onClickArithmetician = () => {
        if (this.state.users && !this.clickUsers) {                      
            this.clickUsers = true;
            this.loadingUsers();
        }        
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListPaymentProcess} elevation={1}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>                        
                                <FaMoneyBillAlt fontSize="25" style={{marginRight: '0.6rem'}}/>
                                <Typography variant="h5" color="inherit">
                                    Pagamentos
                                </Typography>
                                <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                                    <AddIcon />
                                </Fab>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <DateTextField
                                    fullWidth
                                    label="Dt de Início"
                                    value={this.state.initialDate}
                                    onChange={date => { this.setState({ initialDate: date }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <DateTextField
                                    fullWidth
                                    label="Dt de Fim"
                                    value={this.state.finalDate}
                                    onChange={date => { this.setState({ finalDate: date }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} sm={11} md={4} lg={3} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="select-arithmetician">Calculista</InputLabel>
                                <Select                                                 
                                    value={this.state.idArithmetician}
                                    onChange={(event) => {this.setState({idArithmetician: event.target.value})}}
                                    inputProps={{id: 'select-arithmetician'}}     
                                    onClick={this.onClickArithmetician}                                                                   
                                >
                                    <MenuItem value={''}></MenuItem>
                                    {this.state.loadingUsers ?                                        
                                        <MenuItem value={''}>               
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <Typography variant="h6" color="inherit" style={{textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem'}}>
                                                    Buscando... 
                                                </Typography>
                                                <CircularProgress size={20} color="secondary"/>
                                            </div>
                                        </MenuItem>
                                        :                                                                                    
                                        this.state.users.map((user, index) => (
                                            Profile.isArithmetician(user.profile) && <MenuItem value={user.idUser} key={index}>{user.name}</MenuItem>
                                        ))                                        
                                    }
                                </Select>   
                            </ FormControl>
                        </Grid> 
                        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>  
                            <IconButton onClick={this.loadingPaymentProcesss}>
                                <SearchIcon />
                            </IconButton>
                        </Grid> 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableResponsive
                                columns={columns}
                                values={this.data(classes)}
                                loading={this.state.loading}
                                showTotalRecords
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <RegisterPaymentProcess open={this.state.open} openOrClose={this.openOrClose} paymentProcess={this.state.paymentProcess} />                
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'idPaymentProcess',
        label: 'NÚMERO'
    },
    {
        id: 'createdAt',
        label: 'DATA PROCESSAMENTO'
    },
    {
        id: 'name',        
        label: 'CALCULISTA'
    },
    {
        id: 'month',        
        label: 'MÊS'
    },
    {
        id: 'year',        
        label: 'ANO'
    },
    {
        id: 'amount',        
        label: 'VALOR TOTAL'
    },
    {
        id: 'action',        
        label: ''
    }
]

const styles = {
    paperListPaymentProcess: {
        padding: '1rem'
    }
}

ListPaymentProcess.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListPaymentProcess);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';

import RegisterCustomer from './RegisterCustomer';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';

import { getCustomer, deleteCustomer } from '../../../api/consumer/customer';
import { formatDateTimeBRZ } from '../../utils/DataFormat';

export class ListCustomer extends Component {

    state = {
        open: false,
        customers: [],
        customer: null,
        messageDelete: null
    }

    componentWillMount() {
        this.loadingCustomers();
    }

    loadingCustomers = async () => {
        try {
            const response = await getCustomer();
            this.setState({
                customers: response.customers
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = (classes) => {
        var data = [];
        this.state.customers.forEach(customer => {
            let dateCell = {
                idCustomer: customer.idCustomer,
                name: customer.name,
                createdAt: formatDateTimeBRZ(new Date(customer.createdAt)),
                updatedAt: formatDateTimeBRZ(customer.updatedAt ? new Date(customer.updatedAt) : null),
                status: customer.inactive ? 'Inativo' : 'Ativo',
                quota: `${customer.quota} Usuário(s)`,
                action: this.actions(customer)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (customer) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditCustomer(customer)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(customer)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (customer = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            customer,
        });
        if (!open) {
            this.loadingCustomers();
        }
    }

    showMessageDelete = (customer = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Cliente',
                message: <span>Deseja realmente excluir o cliente <b>“{customer.idCustomer}: {customer.name}” ?</b></span>,
                ok: () => this.onDeleteCustomer(customer),
                cancel: true
            }
        });
    }

    onEditCustomer = (customer) => {
        this.openOrClose(customer);
    }

    onDeleteCustomer = async (customer) => {
        try {
            await deleteCustomer(customer.idCustomer);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingCustomers();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListCustomer} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <CustomerIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Clientes
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                    />
                </Paper>
                <RegisterCustomer open={this.state.open} openOrClose={this.openOrClose} customer={this.state.customer} />            
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'idCustomer',        
        label: 'ID'
    },
    {
        id: 'name',        
        label: 'NAME'
    },
    {
        id: 'createdAt',
        label: 'DATA CRIAÇÃO'
    },
    {
        id: 'updatedAt',        
        label: 'DATA ALTERAÇÃO'
    },
    {
        id: 'status',        
        primary: true,
        label: 'STATUS'
    },
    {
        id: 'quota',
        label: 'COTA'
    },
    {
        id: 'action',        
        label: ''
    }
]

const styles = {
    paperListCustomer: {
        padding: '1rem'
    }
}

ListCustomer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListCustomer);

export const typeConditionDynamicReport = [{
    id: '=',
    description: 'Igual a'
}, {
    id: '>',
    description: 'Maior que'
}, {
    id: '>=',
    description: 'Maior e igual a'
}, {
    id: '<',
    description: 'Menor que'
}, {
    id: '<=',
    description: 'Menor e igual a'
}, {
    id: '!=',
    description: 'Diferente de'
}, {
    id: 'is null',
    description: 'Igual a vazio'
}, {
    id: 'is not null',
    description: 'Diferente de vazio'
}, {
    id: 'like',
    description: 'Contém o texto'
}];

export const getTypeConditionDynamicReport = (id) => {
    return typeConditionDynamicReport.find(type => type.id === id);
}

export const typeDateValues = [{ 
    id: '@FIXED', 
    description: 'Data Fixa' 
}, { 
    id: '@TODAY', 
    description: 'Hoje' 
}, { 
    id: '@FIRST_DAY_MONTH', 
    description: 'Primeiro dia do mês' 
}, { 
    id: '@LAST_DAY_MONTH', 
    description: 'Último dia do mês' 
}];

export const getTypeDateValues = (id) => {
    return typeDateValues.find(type => type.id === id);
}
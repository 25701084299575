import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ListLaborCourt from './ListLaborCourt';

export class LaborCourt extends Component {
    render() {        
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridList} spacing={8}>
                <Grid item xs={12}>
                    <ListLaborCourt className={classes.gridLists} />
                </Grid>
            </Grid>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    },
    gridList: {
        padding: '0.5rem'
    },
    gridLists: {
        marginBottom: '1rem'
    }
}

LaborCourt.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LaborCourt);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';

import TableResponsive from '../../utils/TableResponsive';
import SelectLaborCourt from '../../genericTextField/SelectLaborCourt';
import SelectGoal from '../../genericTextField/SelectGoal';
import { formatMoneySymbol } from '../../utils/NumberFormat';

import { getSituationOfProcesses } from '../../../api/consumer/query';

export class SituationOfProcesses extends Component {

    constructor(props) {
        super(props);

        let idLaborCourt = '';
        let idGoal = '';
        let lastFilter =  window.localStorage.getItem('@filterHomeSituationOfProcesses');  
        if (lastFilter) {
            lastFilter = JSON.parse(lastFilter);
            idLaborCourt = lastFilter.idLaborCourt;
            idGoal = lastFilter.idGoal;
        }      

        this.state = {
            idLaborCourt,
            idGoal,            
            situationOfProcesses: [],
            loading: false
        }
    }

    componentDidMount() {
        this.loading();
    }

    loading = async () => {
        try {
            this.setState({loading: true});
            const {idLaborCourt, idGoal} = this.state;
            window.localStorage.setItem('@filterHomeSituationOfProcesses', JSON.stringify({idLaborCourt, idGoal})); 
            let filter = {};
            if (idLaborCourt) {
                filter.idLaborCourt = idLaborCourt;
            }    
            if (idGoal) {
                filter.idGoal = idGoal;
            }                    
            const response = await getSituationOfProcesses(filter);
            this.setState({
                situationOfProcesses: response.situationOfProcesses,
                loading: false
            });
        } catch (error) {
            this.setState({
                situationOfProcesses: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        let totCalculated = 0;
        let totReceived = 0;
        let toNotHomologated = 0;
        let toNotReceived = 0.00;

        this.state.situationOfProcesses.forEach(situationOfProcess => {            
            let dateCell = {                                      
                nameLaborCourt: `${situationOfProcess.code} - ${situationOfProcess.name}`,
                descriptionGoal: situationOfProcess.description,
                calculated: situationOfProcess.calculated,
                received: situationOfProcess.received,
                notHomologated: situationOfProcess.notHomologated,
                notReceived: formatMoneySymbol(situationOfProcess.notReceived),
            }

            totCalculated += parseInt(situationOfProcess.calculated);
            totReceived += parseInt(situationOfProcess.received);
            toNotHomologated += parseInt(situationOfProcess.notHomologated);
            toNotReceived += situationOfProcess.notReceived === null ? 0.00 : parseFloat(parseFloat(situationOfProcess.notReceived).toFixed(2));                       

            data.push(dateCell);            
        });

        if (data.length > 0) {
            data.push({
                nameLaborCourt: <b>Total</b>,
                descriptionGoal: '',
                calculated: <b>{totCalculated}</b>,
                received: <b>{totReceived}</b>,
                notHomologated: <b>{toNotHomologated}</b>,
                notReceived: <b>{formatMoneySymbol(toNotReceived)}</b>
            });
        }
    
        return data;
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridList} spacing={16} direction="column">
                <Paper className={classes.paper} elevation={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                      
                        <Typography variant="h5" color="inherit" className={classes.fontTitle}>
                            Situação das Movimentações
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop: '1rem'}}>
                        <Grid container spacing={16} direction="row">
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>                      
                                <SelectLaborCourt 
                                    fullWidth   
                                    value={this.state.idLaborCourt}
                                    onChange={(event) => this.setState({ idLaborCourt: event.target.value })}   
                                    emptyItem                         
                                />
                            </Grid>
                            <Grid item xs={10} sm={10} md={4} lg={4} xl={4}>                      
                                <SelectGoal 
                                    fullWidth   
                                    value={this.state.idGoal}
                                    onChange={(event) => this.setState({ idGoal: event.target.value })}   
                                    emptyItem                         
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>  
                                <IconButton onClick={this.loading}>
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop: '1rem'}}>
                        <TableResponsive
                            columns={columns}
                            values={this.data()}
                            loading={this.state.loading}
                            fontContent={{fontSize: '0.9rem'}}
                        />
                    </Grid>
                </Paper>                
            </Grid>
        );
    }
};

const columns = [
    {
        id: 'nameLaborCourt',
        label: 'VARA TRABALHISTA'
    },
    {
        id: 'descriptionGoal',
        label: 'FINALIDADE'
    },
    {
        id: 'calculated',
        label: 'CONCLUÍDOS '
    },
    {
        id: 'received',
        label: 'RECEBIDOS '
    },
    {
        id: 'notHomologated',
        label: 'A RECEBER '
    },            
    {
        id: 'notReceived',
        label: 'NÃO RECEBIDOS'
    },       
]

const styles = {
    root: {
        flexGrow: 1,
    },
    fontTitle: {
        fontSize: '1.1rem',
        textAlign: 'center'
    },
    paper: {
        padding: '20px'
    }
}

SituationOfProcesses.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SituationOfProcesses);

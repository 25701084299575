import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { FaRegPaperPlane } from 'react-icons/fa';
import { FiZap } from "react-icons/fi";

import Message from '../../../utils/Message';
import TableResponsive from '../../../utils/TableResponsive';
import RegisterDynamicReport from '../RegisterDynamicReport/RegisterDynamicReport';
import dynamicReportApi from '../../../../api/consumer/dynamicReport';
import OpenDynamicReport from '../../TableDynamicReport/OpenDynamicReport';

import { formatDateTimeBRZ } from '../../../utils/DataFormat';
import { getTypeViewDynamicReport } from '../../../../resources/types/typeViewDynamicReport';

export class ListDynamicReport extends Component {

    state = {
        open: false,
        dynamicReports: [],
        dynamicReport: null,
        messageDelete: null,
        loading: true
    }

    componentWillMount() {
        this.loadingDynamicReports();
    }

    loadingDynamicReports = async () => {
        try {
            this.setState({
                loading: true
            });
            const response = await dynamicReportApi('GET', {});
            this.setState({
                dynamicReports: response.dynamicReports,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        this.state.dynamicReports.forEach(dynamicReport => {
            let dateCell = {
                name: dynamicReport.name,
                view: getTypeViewDynamicReport(dynamicReport.parameters.view).description,
                createdAt: formatDateTimeBRZ(dynamicReport.createdAt),
                process: this.process(dynamicReport),
                action: this.actions(dynamicReport)
            }
            data.push(dateCell);
        });
        return data;
    }

    process = (dynamicReport) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Fab variant="extended" color="secondary" size="medium"  onClick={() => OpenDynamicReport(dynamicReport.idDynamicReport, {})}>
                    <FiZap fontSize="22" style={{marginRight: '0.5rem'}}/>
                    Gerar
                </Fab>
            </div>
        );
    }

    actions = (dynamicReport) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditDynamicReport(dynamicReport)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(dynamicReport)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (dynamicReport = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            dynamicReport,
        });
        if (!open) {
            this.loadingDynamicReports();
        }
    }

    showMessageDelete = (dynamicReport = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Relatório Dinâmico',
                message: <span>Deseja realmente excluir o relatório dinâmico <b>“{dynamicReport.name}” ?</b></span>,
                ok: () => this.onDeleteDynamicReport(dynamicReport),
                cancel: true
            }
        });
    }

    onEditDynamicReport = (dynamicReport) => {
        this.openOrClose(dynamicReport);
    }

    onDeleteDynamicReport = async (dynamicReport) => {
        try {
            await dynamicReportApi('DELETE', { idDynamicReport: dynamicReport.idDynamicReport });
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingDynamicReports();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListDynamicReport} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <FaRegPaperPlane fontSize="22" style={{ marginRight: '0.6rem' }} />
                        <Typography variant="h5" color="inherit">
                            Relatórios Dinâmicos
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                        loading={this.state.loading}
                    />
                </Paper>
                <RegisterDynamicReport open={this.state.open} openOrClose={this.openOrClose} dynamicReport={this.state.dynamicReport} />
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'name',
        label: 'NOME'
    },
    {
        id: 'view',
        label: 'VISÃO'
    },    
    {
        id: 'createdAt',
        label: 'DATA CRIAÇÃO'
    }, {
        id: 'action',
        label: ''
    }, {
        id: 'process',
        label: ''
    }
]

const styles = {
    paperListDynamicReport: {
        padding: '1rem'
    }
}

ListDynamicReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListDynamicReport);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';

import DateTextField from '../../../utils/DateTextField';
import {getLastDayMounth} from '../../../utils/DataFormat';
import OpenReport from '../../Report/OpenReport';

import { getLaborCourt } from '../../../../api/consumer/laborCourt';

export class OpenR001 extends Component {

    state = {
        initialDate: this.getInitialDate(),
        finalDate: getLastDayMounth(this.getInitialDate()),
        idLaborCourt: '',
        loadingLaborCourts: true,
        laborCourts: []
    }

    clickLaborCourts = false;

    getInitialDate() {
        let date = new Date();
        date.setDate(1);
        return date;
    }

    openReport = () => {
        let {initialDate, finalDate, idLaborCourt} = this.state;
        if (!initialDate) {
            global.SnackBar.show('WARNING', 'Informe a data initial');
            return;
        }
        if (!finalDate) {
            global.SnackBar.show('WARNING', 'Informe a data final');
            return;
        }        

        initialDate.setHours(0);
        initialDate.setMinutes(0);
        initialDate.setSeconds(0);
        initialDate.setMilliseconds(0);        
        finalDate.setHours(23);
        finalDate.setMinutes(59);
        finalDate.setSeconds(59);
        finalDate.setMilliseconds(999);

        let params = {
            initialDate: new Date(initialDate).toISOString(),
            finalDate: new Date(finalDate).toISOString()                      
        }

        if (idLaborCourt) {
            const laborCourt = this.state.laborCourts.find(laborCourt => laborCourt.idLaborCourt === idLaborCourt);
            if (laborCourt) {
                params.LaborCourt = laborCourt;
            }
        }        
        
        OpenReport('R001', params);
    }

    loadingLaborCourts = async () => {
        try {            
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts,
                loadingLaborCourts: false
            });
        } catch (error) {
            this.setState({
                laborCourts: [],
                loadingLaborCourts: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    onClickLaborCourt = () => {
        if (this.state.laborCourts && !this.clickLaborCourts) {                      
            this.clickLaborCourts = true;
            this.loadingLaborCourts();
        }        
    }    

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Demonstrativo de Honorários Recebidos</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <DateTextField
                                    fullWidth
                                    label="Data Início"
                                    value={this.state.initialDate}
                                    onChange={date => this.setState({ initialDate: date })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <DateTextField
                                    fullWidth
                                    label="Data Fim"
                                    value={this.state.finalDate}
                                    onChange={date => this.setState({ finalDate: date })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-labor-court">Vara Trabalhista</InputLabel>
                                    <Select                                                 
                                        value={this.state.idLaborCourt}
                                        onChange={(event) => {this.setState({idLaborCourt: event.target.value})}}
                                        inputProps={{id: 'select-labor-court'}}     
                                        onClick={this.onClickLaborCourt}                                                                   
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        {this.state.loadingLaborCourts ?                                        
                                            <MenuItem value={''}>               
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <Typography variant="h6" color="inherit" style={{textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem'}}>
                                                        Buscando... 
                                                    </Typography>
                                                    <CircularProgress size={20} color="secondary"/>
                                                </div>
                                            </MenuItem>
                                            :                                                                                    
                                            this.state.laborCourts.map((laborCourt, index) => (
                                                <MenuItem value={laborCourt.idLaborCourt} key={index}>{laborCourt.code} - {laborCourt.name}</MenuItem>
                                            ))                                        
                                        }
                                    </Select>   
                                </ FormControl>
                            </Grid>  
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Fab variant="extended" color="secondary" className={classes.fab} onClick={this.openReport}>
                                    <PrintIcon style={{marginRight: '0.5rem'}}/>
                                    Gerar
                                </Fab>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>                
            </div>
        );
    }
};

const styles = theme => ({
    root: {
        padding: '1rem'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    fab: {
        margin: theme.spacing.unit,
    }
});

OpenR001.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OpenR001);

import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import { formatDateTimeBRZ, formatDateBRZ } from '../../../../utils/DataFormat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import movementsOfProcessAPI from '../../../../../api/consumer/movementsOfProcess';

import { formatMoneySymbol } from '../../../../utils/NumberFormat';

export class R003 extends Component {
    state = {
        loading: true,
        error: null,
        movementsOfProcesses: [],
        movementsOfCalculations: []
    };

    componentWillMount() {
        this.loadingPayment();
    }

    loadingPayment = async () => {
        try {
            let { initialDateConclusion, finalDateConclusion, Arithmetician, LaborCourt, Goal } = this.props.params;

            //Procura por calculista, mês, ano  caso o id do processamento não seja passado
            if (initialDateConclusion === undefined || initialDateConclusion === '') {
                throw Object('Data início conclusão é parâmetro obrigatório.');
            }
            if (finalDateConclusion === undefined || finalDateConclusion === '') {
                throw Object('Data fim conclusão é parâmetro obrigatório.');
            }

            let param = {
                initialDateConclusion,
                finalDateConclusion,
                order: [
                    ['idArithmetician', 'ASC'],
                    ['idLaborCourt', 'ASC'],
                    ['idGoal', 'ASC'],
                    ['concluisionDateProcessMovement', 'ASC']
                ]
            };

            if (Arithmetician) {
                param.idArithmetician = Arithmetician.idUser;
            }

            if (LaborCourt) {
                param.idLaborCourt = LaborCourt.idLaborCourt;
            }

            if (Goal) {
                param.idGoal = Goal.idGoal;
            }

            const responseMovements = await movementsOfProcessAPI('GET', param);
            let movementsOfProcesses = responseMovements.movementsOfProcesses;
            let movementsOfCalculations = [];
            let lastIdArithmetician = null;
            for (let i = 0; i < movementsOfProcesses.length; i++) {
                const { idArithmetician, nameArithmetician } = movementsOfProcesses[i];
                if (idArithmetician !== lastIdArithmetician) {
                    const arith = {
                        idArithmetician,
                        nameArithmetician: nameArithmetician ? nameArithmetician.toUpperCase() : null,
                        paymentTotal: 0,
                        movementsOfProcesses: []
                    };
                    movementsOfCalculations.push(arith);
                }
                lastIdArithmetician = idArithmetician;
            }
            for (let i = 0; i < movementsOfCalculations.length; i++) {
                const { idArithmetician } = movementsOfCalculations[i];
                for (let x = 0; x < movementsOfProcesses.length; x++) {
                    const movementsOfProcess = movementsOfProcesses[x];
                    if (idArithmetician === movementsOfProcess.idArithmetician) {
                        movementsOfCalculations[i].movementsOfProcesses.push(movementsOfProcess);
                        if (movementsOfProcess.amountPaid > 0) {
                            movementsOfCalculations[i].paymentTotal += movementsOfProcess.amountPaid;
                        }
                    }
                }
            }
            this.setState({
                movementsOfCalculations,
                movementsOfProcesses,
                loading: false
            });
        } catch (error) {
            let descError = 'Erro ao consultar pagamentos';
            if (error !== undefined) {
                if (error.message !== undefined) {
                    descError = error.message;
                } else {
                    descError = error;
                }
            }
            this.setState({
                loading: false,
                error: descError
            });
        }
    };

    render() {
        const { user, person } = this.props;
        const reportName = 'Demonstrativo de Produtividade';
        const textCellHeader = styles.textCellHeader;
        const textCellRow = styles.textCellRow;

        const { initialDateConclusion, finalDateConclusion, Arithmetician, LaborCourt, Goal } = this.props.params;

        if (this.state.error != null) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Erro ao buscar cálculos: {JSON.stringify(this.state.error)}
                    </Typography>
                </Paper>
            );
        } else if (this.state.loading) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Buscando cálculos...
                    </Typography>
                    <CircularProgress size={20} color="secondary" />
                </Paper>
            );
        } else {
            return (
                <PDFViewer width="100%" height="100%" style={{ border: 0, padding: 0 }}>
                    <Document title={reportName}>
                        <Page size="A4" orientation="portrait" style={styles.page}>
                            <View style={styles.viewTitle}>
                                {person.logo && (
                                    <View style={{ flex: 1 }}>
                                        <Image style={styles.imageLogo} src={person.logo} />
                                    </View>
                                )}
                                <View style={{ flex: 3, display: 'flex' }}>
                                    <Text style={styles.textReportName}>{reportName}</Text>
                                    <Text style={styles.textSocialReason}>{person.socialReason}</Text>
                                    <Text style={styles.textAdditionalDescription}>{person.additionalDescription}</Text>
                                    <Text style={styles.textAdditionalDescription}>CNPJ: {person.registrationNumber}</Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.telephone} / {person.cellPhone}
                                    </Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.city} - {person.province}
                                    </Text>
                                </View>
                                {person.logo && <View style={{ flex: 1 }} />}
                            </View>
                            <View style={styles.viewInfo}>
                                <Text style={styles.textIssued}>
                                    Período de conclusão: {formatDateBRZ(initialDateConclusion)} a {formatDateBRZ(finalDateConclusion)}
                                </Text>
                                <Text style={styles.textIssued}>Vara Trabalhista: {LaborCourt && `${LaborCourt.code} - ${LaborCourt.name}`}</Text>
                                <Text style={styles.textIssued}>Calculista: {Arithmetician && Arithmetician.name}</Text>
                                <Text style={styles.textIssued}>Finalidade: {Goal && Goal.description}</Text>
                                <Text style={styles.textIssued}>
                                    Emitido por: {user.name} - {formatDateTimeBRZ(new Date())}
                                </Text>
                            </View>
                            {this.state.movementsOfCalculations.length === 0 ? (
                                <Text style={styles.textArithmetician}>Nenhum registro encontrado</Text>
                            ) : (
                                this.state.movementsOfCalculations.map((movementsOfCalculation, index) => (
                                    <React.Fragment key={index}>
                                        <View style={styles.tableHeaderArith}>
                                            <Text style={styles.textArithmetician}>
                                                {movementsOfCalculation.nameArithmetician
                                                    ? movementsOfCalculation.nameArithmetician
                                                    : '*SEM CALCULISTA'}
                                            </Text>
                                            <View fixed style={styles.tableHeader}>
                                                <View style={{ ...textCellHeader, flex: 2 }}>
                                                    <Text>Vara Trabalhista</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 2 }}>
                                                    <Text>Número</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 2 }}>
                                                    <Text>Reclamante</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 2 }}>
                                                    <Text>Reclamado</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 1 }}>
                                                    <Text>Finalidade</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 1 }}>
                                                    <Text>Dt.Conclusão</Text>
                                                </View>
                                                <View style={{ ...textCellHeader, flex: 1 }}>
                                                    <Text>Valor Pago</Text>
                                                </View>
                                            </View>
                                        </View>
                                        {movementsOfCalculation.movementsOfProcesses.map((movementsOfProcess, index) => (
                                            <View style={styles.tableRow} key={index} wrap={false}>
                                                <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.nameLoaborCourt}</Text>
                                                <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.number}</Text>
                                                <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.claimant}</Text>
                                                <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.claimed}</Text>
                                                <Text style={{ ...textCellRow, flex: 1 }}>{movementsOfProcess.descriptionGoal}</Text>
                                                <Text style={{ ...textCellRow, flex: 1 }}>
                                                    {formatDateBRZ(movementsOfProcess.concluisionDateProcessMovement)}
                                                </Text>
                                                <Text style={{ ...textCellRow, flex: 1 }}>{formatMoneySymbol(movementsOfProcess.amountPaid)}</Text>
                                            </View>
                                        ))}
                                        <View style={styles.tableRowTotal} wrap={false}>
                                            <Text style={{ ...textCellRow, textAlign: 'left', flex: 10, fontSize: 7 }}>
                                                Realizado(s): {movementsOfCalculation.movementsOfProcesses.length}
                                            </Text>
                                            <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>TOTAL</Text>
                                            <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>
                                                {formatMoneySymbol(movementsOfCalculation.paymentTotal)}
                                            </Text>
                                        </View>
                                    </React.Fragment>
                                ))
                            )}
                            <View
                                fixed
                                style={styles.viewPageNumber}
                                render={() => (
                                    <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <View style={{ flex: 1, paddingLeft: 15 }}>
                                            <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <View>
                                                    <Image style={styles.imageAllPasso} src="/images/logo_64X64.png" />
                                                </View>
                                                <View style={{ paddingLeft: 3 }}>
                                                    <Text>AllPasso Software {'<allpasso.com.br>'}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text
                                                style={styles.textPageNumber}
                                                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                            />
                                        </View>
                                        <View style={{ flex: 1 }} />
                                    </View>
                                )}
                            />
                        </Page>
                    </Document>
                </PDFViewer>
            );
        }
    }
}

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        backgroundColor: '#FFF',
        padding: 20,
        paddingBottom: 30
    },
    viewTitle: {
        padding: 7,
        textAlign: 'center',
        border: '1 solid #000',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row'
    },
    imageLogo: {
        width: 80
    },
    textReportName: {
        fontSize: 12
    },
    textSocialReason: {
        fontSize: 8
    },
    textAdditionalDescription: {
        fontSize: 8
    },
    viewInfo: {
        marginTop: 5,
        marginBottom: 5,
        padding: 3,
        textAlign: 'left',
        border: '1 solid #000',
        borderRadius: 4
    },
    textIssued: {
        fontSize: 8
    },
    viewPageNumber: {
        position: 'absolute',
        fontSize: 6,
        bottom: 20,
        left: 20,
        right: 20
    },
    textPageNumber: {
        textAlign: 'center',
        color: '#000'
    },
    imageAllPasso: {
        width: 10
    },
    tableHeaderArith: {
        paddingTop: 5,
        paddingBottom: 1,
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        border: '1 solid #000',
        borderRadius: 4,
        marginBottom: 4
    },
    tableHeader: {
        paddingTop: 5,
        paddingBottom: 2,
        paddingLeft: 1,
        paddingRight: 1,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 4
    },
    tableRow: {
        padding: 2,
        display: 'flex',
        flexDirection: 'row'
    },
    tableRowTotal: {
        marginTop: 3,
        marginBottom: 12,
        padding: 3,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #000',
        borderRadius: 4
    },
    textCellHeader: {
        fontSize: 7,
        display: 'flex',
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2
    },
    textCellRow: {
        fontSize: 6,
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2
    },
    textArithmetician: {
        fontSize: 13,
        padding: 5,
        paddingTop: 8,
        textAlign: 'center'
    }
});

export default R003;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { saveCustomer } from '../../../api/consumer/customer';

export class RegisterCustomer extends Component {

    state = {
        name: '',
        quota: '',
        inactive: false,
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.customer) {
                this.setState({
                    name: props.customer.name,
                    quota: props.customer.quota,
                    inactive: props.customer.inactive
                });
            } else {
                this.clearCustomer();
            }

        }
    }

    clearCustomer = () => {
        this.setState({
            name: '',
            quota: '',
            inactive: false
        });
    }

    saveCustomer = async () => {

        if (this.state.name === '') {
            global.SnackBar.show('WARNING', 'Informe o nome do cliente');
            return;
        }

        if (this.state.quota === '') {
            global.SnackBar.show('WARNING', 'Informe a cota de usuários do cliente');
            return;
        }

        try {
            let customer = {
                idCustomer: (this.props.customer ? this.props.customer.idCustomer : null),
                name: this.state.name,
                quota: this.state.quota,
                inactive: this.state.inactive
            }
            await saveCustomer(customer);
            global.SnackBar.show('SUCCESS', 'Registro salvo');            
            this.props.openOrClose();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">Cadastro Cliente</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    label="Nome"
                                    fullWidth                                    
                                    value={this.state.name}
                                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                                <TextField
                                    label="Cota Usuários"
                                    fullWidth                                    
                                    value={this.state.quota}
                                    onChange={(event) => { this.setState({ quota: event.target.value }) }}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.inactive}
                                            onChange={(event) => this.setState({ inactive: event.target.checked })}
                                        />
                                    }
                                    label="Inativo"
                                    value="top"
                                    labelPlacement="top"
                                    disabled={this.props.customer === null}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.saveCustomer} color="primary" variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterCustomer.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterCustomer));
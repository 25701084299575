import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReportIcon from '@material-ui/icons/Toc';

import Profile from '../../utils/Profile';

import OpenR001 from './reports/OpenR001';
import OpenR002 from './reports/OpenR002';
import OpenR003 from './reports/OpenR003';
import OpenR004 from './reports/OpenR004';

export class ListReports extends Component {
    render() {
        const { classes } = this.props;
        const isAdmin = Profile.isAdmin(this.props.user.profile);
        return (
            <div style={{ margin: '0.5rem' }}>
                <Paper className={classes.paperListCaseFile} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <ReportIcon style={{ marginRight: '0.6rem' }} />
                        <Typography variant="h5" color="inherit">
                            Relatórios
                        </Typography>
                    </div>
                    <Grid container spacing={0}>
                        {isAdmin && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <OpenR001 />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <OpenR003 />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <OpenR002 user={this.props.user} />
                                </Grid>
                                {isAdmin && (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <OpenR004 user={this.props.user} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const styles = {
    paperListCaseFile: {
        padding: '1rem'
    }
};

ListReports.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListReports);

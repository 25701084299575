import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BodyMenu from './BodyMenu';
import Dashboard from './Dashboard/Dashboard';
import User from './User/User';
import LaborCourt from './LaborCourt/LaborCourt';
import Goal from './Goal/Goal';
import CaseFile from './CaseFile/CaseFile';
import Process from './Process/Process';
import ListReports from './ListReports/ListReports';
import DynamicReport from './DynamicReport/DynamicReport';
import PaymentProcess from './PaymentProcess/PaymentProcess';
import ArithmeticianPaymentValue from './ArithmeticianPaymentValue/ArithmeticianPaymentValue';
import Company from './Company/Company';
import CostInvoice from './Company/CostInvoice';

import { Switch, Route, Redirect } from 'react-router';
import MessageNotif from './MessageNotif/MessageNotif';
import Redirector from '../../Redirector';
import Profile from '../utils/Profile';

export class Main extends Component {

    render() {       
        const { classes, ...props } = this.props; //pegar todas as props mas sem a prop classes  
        
        let acesso = true;
        if (!Profile.isAdmin(this.props.user.profile)) {
            let route = this.props.location.pathname;
            if (route === `${this.props.match.path}/laborcourt` || 
                route === `${this.props.match.path}/user` ||
                route === `${this.props.match.path}/goal` ||
                route === `${this.props.match.path}/goal-value-pay` ||
                route === `${this.props.match.path}/casefile` ||
                route === `${this.props.match.path}/dynamic-report` ||
                route === `${this.props.match.path}/company-register` ||
                route === `${this.props.match.path}/company-cost-invoice` ||
                route === `${this.props.match.path}/payment`) {
                acesso = false;
            }
        }

        if (!acesso) {
            Redirector.to(this, '/error/Acesso Negado/Caso necessite de alguma funcionalidade não disponível para seu perfil, entre em contato com seu responsável.');             
            return <div />;
        }
        
        return (            
            <React.Fragment>
                <BodyMenu {...props} />                      
                <Switch>
                    <SubRoute exact path={`${this.props.match.path}/dashboard`} component={Dashboard} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/process`} component={Process} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/casefile`} component={CaseFile} user={props.user}/>                    
                    <SubRoute exact path={`${this.props.match.path}/laborcourt`} component={LaborCourt} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/goal`} component={Goal} user={props.user}/>                    
                    <SubRoute exact path={`${this.props.match.path}/goal-value-pay`} component={ArithmeticianPaymentValue} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/user`} component={User} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/messages`} component={MessageNotif} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/payment-process`} component={PaymentProcess} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/reports`} component={ListReports} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/dynamic-report`} component={DynamicReport} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/company-register`} component={Company} user={props.user}/>
                    <SubRoute exact path={`${this.props.match.path}/company-cost-invoice`} component={CostInvoice} user={props.user}/>
                    <Redirect from='*' to={`${this.props.match.path}/dashboard`} /> 
                </Switch>
            </React.Fragment>
        );
    }
};

const SubRoute = ({component: Component, user, ...subProps}) => (
    <Route
        {...subProps}
        render={props =>
            <Component {...props} user={user}/>
        }
    />
);

const styles = {}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Main);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import GoalIcon from '@material-ui/icons/RedoRounded';

import RegisterGoal from './RegisterGoal';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';

import { getGoal, deleteGoal } from '../../../api/consumer/goal';
import { formatDateTimeBRZ } from '../../utils/DataFormat';

export class ListGoal extends Component {

    state = {
        open: false,
        goals: [],
        goal: null,
        messageDelete: null
    }

    componentWillMount() {
        this.loadingGoals();
    }

    loadingGoals = async () => {
        try {
            const response = await getGoal();
            this.setState({
                goals: response.goals
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = (classes) => {
        var data = [];
        this.state.goals.forEach(goal => {
            let dateCell = {               
                description: goal.description,
                createdAt: formatDateTimeBRZ(new Date(goal.createdAt)),
                updatedAt: formatDateTimeBRZ(goal.updatedAt ? new Date(goal.updatedAt) : null),
                action: this.actions(goal)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (goal) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditGoal(goal)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(goal)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (goal = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            goal,
        });
        if (!open) {
            this.loadingGoals();
        }
    }

    showMessageDelete = (goal = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Finalidade',
                message: <span>Deseja realmente excluir a finalidade <b>“{goal.idGoal}: {goal.description}” ?</b></span>,
                ok: () => this.onDeleteGoal(goal),
                cancel: true
            }
        });
    }

    onEditGoal = (goal) => {
        this.openOrClose(goal);
    }

    onDeleteGoal = async (goal) => {
        try {
            await deleteGoal(goal.idGoal);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingGoals();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListGoal} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <GoalIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Finalidades
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                    />
                </Paper>
                <RegisterGoal open={this.state.open} openOrClose={this.openOrClose} goal={this.state.goal} />            
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'description',        
        label: 'DESCRIÇÃO'
    },
    {
        id: 'createdAt',
        label: 'DATA CRIAÇÃO'
    },
    {
        id: 'updatedAt',        
        label: 'DATA ALTERAÇÃO'
    },
    {
        id: 'action',        
        label: ''
    }
]

const styles = {
    paperListGoal: {
        padding: '1rem'
    }
}

ListGoal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListGoal);

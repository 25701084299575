import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FiX, FiList } from "react-icons/fi";

export class DefineGroups extends Component {

    constructor(props) {
        super(props);
        let fullColumns = clone(props.parameters.fields);
        this.state = {
            fullColumns,
            nameColumn: ''
        }
    }

    addColumn = () => {
        if (this.state.nameColumn === '') {
            global.SnackBar.show('WARNING', 'Selecione uma coluna');
            return;
        }
        let definedGroups = clone(this.props.definedGroups);
        let newGroup = this.state.fullColumns.find((column) => column.displayField === this.state.nameColumn);
        if (newGroup) {
            newGroup.order = '';            
            definedGroups.push(newGroup)
            this.setState({
                nameColumn: ''
            });
            this.props.setDefinedGroups(definedGroups);
        }
    }

    removeColumn = (index) => {
        let definedGroups = clone(this.props.definedGroups);
        definedGroups.splice(index, 1);
        this.props.setDefinedGroups(definedGroups);
    }

    changeValue = (value, index, attribute) => {
        if (value === null) {
            value = '';
        }
        let definedGroups = clone(this.props.definedGroups);
        definedGroups[index][attribute] = value;
        this.props.setDefinedGroups(definedGroups);
    }

    render() {

        return (
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                        <div style={{flex: 1, marginRight: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FiList fontSize="22" />
                        </div>
                        <div style={{flex: 1}}>
                            <Typography style={{ fontSize: '1.2rem' }}>Grupos</Typography>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="select-field-groups-columns">Coluna</InputLabel>
                                        <Select
                                            value={this.state.nameColumn}
                                            onChange={(e) => this.setState({ nameColumn: e.target.value })}
                                            inputProps={{ id: "select-field-groups-columns" }}
                                        >
                                            {this.state.fullColumns.map((fullColumn, index) => (
                                                !(this.props.definedGroups.find((definedGroup) => definedGroup.displayField === fullColumn.displayField)) && <MenuItem value={fullColumn.displayField} key={index}>{fullColumn.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </ FormControl>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={this.addColumn} color="secondary" variant="contained">
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.props.definedGroups.map((column, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography>{column.label}: </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="select-field-groups-columns-order">Ordenação</InputLabel>
                                                <Select
                                                    value={column.order}
                                                    onChange={(e) => this.changeValue(e.target.value, index, 'order')}
                                                    inputProps={{ id: "select-field-groups-columns-order" }}
                                                >
                                                    {getOrderType.map((condition, index) => (
                                                        <MenuItem value={condition.id} key={index}>{condition.description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </ FormControl>
                                        </Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={5} xl={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <IconButton onClick={() => this.removeColumn(index)}>
                                                <FiX />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

};

const getOrderType = [{
    id: 'asc',
    description: 'Ascendente (A-Z)'
}, {
    id: 'desc',
    description: 'Descendente (Z-A)'
}]

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const styles = {}

DefineGroups.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DefineGroups);

export const typeViewDynamicReport = [{
    id: 'DetailOfProcesses',
    description: 'Processos'
}, {
    id: 'MovementsOfProcesses',
    description: 'Movimentos'
}];

export const getTypeViewDynamicReport = (id) => {
    return typeViewDynamicReport.find(type => type.id === id);
}
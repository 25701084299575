import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LaborCourtIcon from '@material-ui/icons/GavelRounded';

import RegisterLaborCourt from './RegisterLaborCourt';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';

import { getLaborCourt, deleteLaborCourt } from '../../../api/consumer/laborCourt';
import { formatDateTimeBRZ } from '../../utils/DataFormat';

export class ListLaborCourt extends Component {

    state = {
        open: false,
        laborCourts: [],
        laborCourt: null,
        messageDelete: null
    }

    componentWillMount() {
        this.loadingLaborCourts();
    }

    loadingLaborCourts = async () => {
        try {
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = (classes) => {
        var data = [];
        this.state.laborCourts.forEach(laborCourt => {
            let dateCell = {                
                code: laborCourt.code,
                name: laborCourt.name,
                createdAt: formatDateTimeBRZ(new Date(laborCourt.createdAt)),
                updatedAt: formatDateTimeBRZ(laborCourt.updatedAt ? new Date(laborCourt.updatedAt) : null),
                action: this.actions(laborCourt)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (laborCourt) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditLaborCourt(laborCourt)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(laborCourt)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (laborCourt = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            laborCourt,
        });
        if (!open) {
            this.loadingLaborCourts();
        }
    }

    showMessageDelete = (laborCourt = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Vara Trabalhista',
                message: <span>Deseja realmente excluir a vara trabalhista <b>“{laborCourt.idLaborCourt}: {laborCourt.code}/{laborCourt.name}” ?</b></span>,
                ok: () => this.onDeleteLaborCourt(laborCourt),
                cancel: true
            }
        });
    }

    onEditLaborCourt = (laborCourt) => {
        this.openOrClose(laborCourt);
    }

    onDeleteLaborCourt = async (laborCourt) => {
        try {
            await deleteLaborCourt(laborCourt.idLaborCourt);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingLaborCourts();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListLaborCourt} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>                        
                        <LaborCourtIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Varas Trabalhistas
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                    />
                </Paper>
                <RegisterLaborCourt open={this.state.open} openOrClose={this.openOrClose} laborCourt={this.state.laborCourt} />                
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'code',        
        label: 'CÓDIGO'
    },
    {
        id: 'name',        
        label: 'NAME'
    },
    {
        id: 'createdAt',
        label: 'DATA CRIAÇÃO'
    },
    {
        id: 'updatedAt',        
        label: 'DATA ALTERAÇÃO'
    },
    {
        id: 'action',        
        label: ''
    }
]

const styles = {
    paperListLaborCourt: {
        padding: '1rem'
    }
}

ListLaborCourt.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListLaborCourt);

export default {
	saveToken(token, remember) {
		this.removeToken()
		if (remember) {
			window.localStorage.setItem('@tokenAuth', token);
		} else {
			window.sessionStorage.setItem('@tokenAuth', token);
		}
	},
	saveUser(user) {
		window.sessionStorage.setItem('@userAuth', JSON.stringify(user));
	},
	removeToken() {
		window.localStorage.removeItem('@tokenAuth');
		window.sessionStorage.removeItem('@tokenAuth');
		window.sessionStorage.removeItem('@userAuth');
	},
	removeUserAuth() {
		window.sessionStorage.removeItem('@userAuth');
	},
	getToken() {
		let token = null;
		let tokenLocal = window.localStorage.getItem('@tokenAuth');
		let tokenSession = window.sessionStorage.getItem('@tokenAuth');
		if (tokenLocal) {
			token = tokenLocal
		} else if (tokenSession) {
			token = tokenSession;
		}
		return token || '';
	},
	getUser() {
		const user = window.sessionStorage.getItem('@userAuth');
		if (user) {
			return JSON.parse(user);
		} 
		return null;		
	},
	getBearerToken() {
		return `bearer ${this.getToken()}`;
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
//import MaskedInput from 'react-text-mask';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/PrintRounded';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';

import Profile from '../../utils/Profile';
import { filterUserActive } from '../../utils/FilterList';
import DateTextField from '../../utils/DateTextField';
import OpenReport from '../Report/OpenReport';

import { saveProcess, saveProcessArithmetician } from '../../../api/consumer/process';
import { getLaborCourt } from '../../../api/consumer/laborCourt';
import { getGoal } from '../../../api/consumer/goal';
import { getCaseFile } from '../../../api/consumer/caseFile';
import { getUser } from '../../../api/consumer/user';
import { typeStatusProcess } from '../../../resources/types/typeStatusProcess';
import { formatMoney, setStateMoney, formatNumberProcess, isValidNumberProcess } from '../../utils/NumberFormat';
import { formatDateBRZ } from '../../utils/DataFormat';
import { getDsTypeStatusProcess } from '../../../resources/types/typeStatusProcess';

export class RegisterProcess extends Component {
    state = {
        //number: '_______-__.____._.__.____',
        number: '',
        idLaborCourt: '',
        idGoal: '',
        idCaseFile: '',
        idArithmetician: '',
        idExpert: '',
        claimant: '',
        claimed: '',
        uploadDate: '',
        expirationDate: '',
        status: 'PEND',
        arbitratedFees: '',
        concluisionDate: '',
        //receiptDate: '',
        receivedFees: '',
        note: '',
        updatedAt: '',
        processMovements: [],
        laborCourts: [],
        goals: [],
        caseFiles: [],
        users: [],
        messageLoading: null,
        loadedSelects: false,
        saved: false,
        showCalculistaInativos: false
    };

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.process) {
                this.setState({
                    number: props.process.number,
                    idLaborCourt: props.process.idLaborCourt,
                    idCaseFile: props.process.idCaseFile,
                    idExpert: props.process.idExpert !== null ? props.process.idExpert : '',
                    claimant: props.process.claimant,
                    claimed: props.process.claimed,
                    status: props.process.status,
                    concluisionDate: props.process.concluisionDate ? new Date(props.process.concluisionDate) : '',
                    arbitratedFees: formatMoney(props.process.arbitratedFees),
                    /*receiptDate: props.process.receiptDate ? new Date(props.process.receiptDate) : '',*/
                    receivedFees: formatMoney(props.process.receivedFees),
                    note: props.process.note !== null ? props.process.note : '',
                    updatedAt: new Date(props.process.updatedAt),
                    processMovements: props.process.ProcessMovements
                });
            } else {
                this.clearProcess();
            }
            this.loadingSelects();
        }
    }

    clearProcess = () => {
        let adic = {};
        const experts = this.state.users.filter(user => Profile.isExpert(user.profile));
        if (experts.length === 1) {
            adic.idExpert = experts[0].idUser;
        }

        this.setState({
            //number: '_______-__.____._.__.____',
            number: '',
            idLaborCourt: '',
            idGoal: '',
            idCaseFile: '',
            idArithmetician: '',
            idExpert: '',
            claimant: '',
            claimed: '',
            uploadDate: '',
            expirationDate: '',
            status: 'PEND',
            arbitratedFees: '',
            concluisionDate: '',
            /*receiptDate: '',*/
            receivedFees: '',
            note: '',
            updatedAt: '',
            processMovements: [],
            ...adic
        });
    };

    saveProcess = async () => {
        const isAdmin = Profile.isAdmin(this.props.user.profile);

        if (isAdmin) {
            /*if (this.state.number.toString().indexOf('_') > -1) {
                global.SnackBar.show('WARNING', 'Informe o número do processo');
                return;
            }*/

            if (this.state.number === '') {
                global.SnackBar.show('WARNING', 'Informe o número do processo');
                return;
            }

            if (!isValidNumberProcess(this.state.number)) {
                global.SnackBar.show('WARNING', 'Número do processo inválido');
                return;
            }

            if (this.state.idLaborCourt === '') {
                global.SnackBar.show('WARNING', 'Informe a vara trabalhista');
                return;
            }

            if (this.state.idCaseFile === '') {
                global.SnackBar.show('WARNING', 'Informe o auto');
                return;
            }

            if (this.state.claimant === '') {
                global.SnackBar.show('WARNING', 'Informe o reclamante');
                return;
            }

            if (this.state.claimed === '') {
                global.SnackBar.show('WARNING', 'Informe o reclamado');
                return;
            }
        }

        if (this.state.status === '') {
            global.SnackBar.show('WARNING', 'Informe o status');
            return;
        }

        if (this.state.note.length > 5000) {
            global.SnackBar.show('WARNING', 'Limite de 5000 caracteres para observação');
            return;
        }

        let processMovementsUpdate = [];
        for (let i = 0; i < this.state.processMovements.length; i++) {
            const processMovement = this.state.processMovements[i];

            if (isAdmin) {
                if (processMovement.uploadDate === null || processMovement.uploadDate === '') {
                    global.SnackBar.show('WARNING', `Movimento: ${i + 1} - Informe a data de carga`);
                    return;
                }

                const data = new Date(processMovement.uploadDate);
                data.setHours(0);
                data.setMinutes(0);
                data.setSeconds(0);
                data.setMilliseconds(0);
                processMovement.uploadDate = data;

                if (processMovement.idGoal === '') {
                    global.SnackBar.show('WARNING', `Movimento: ${i + 1} - Informe a finalidade`);
                    return;
                }

                if (processMovement.expirationDate === null || processMovement.expirationDate === '') {
                    global.SnackBar.show('WARNING', `Movimento: ${i + 1} - Informe a data de vencimento`);
                    return;
                }

                const data2 = new Date(processMovement.expirationDate);
                data.setHours(0);
                data.setMinutes(0);
                data.setSeconds(0);
                data.setMilliseconds(0);
                processMovement.expirationDate = data2;
            }

            if (processMovement.status === '') {
                global.SnackBar.show('WARNING', `Movimento: ${i + 1} - Informe o status`);
                return;
            }

            if (processMovement.paid !== 'PAID' && processMovement.paid !== 'UNPAID') {
                processMovementsUpdate.push(processMovement);
            }
        }

        try {
            this.setState({ messageLoading: 'Salvando...' });
            if (isAdmin) {
                let process = {
                    idProcess: this.props.process ? this.props.process.idProcess : null,
                    updatedAt: this.props.process ? this.props.process.updatedAt : null,
                    number: this.state.number,
                    idLaborCourt: this.state.idLaborCourt,
                    idGoal: this.state.idGoal,
                    idCaseFile: this.state.idCaseFile,
                    idExpert: this.state.idExpert,
                    claimant: this.state.claimant,
                    claimed: this.state.claimed,
                    uploadDate: this.state.uploadDate,
                    status: this.state.status,
                    concluisionDate: this.state.concluisionDate,
                    arbitratedFees: formatMoney(this.state.arbitratedFees),
                    /*receiptDate: this.state.receiptDate,*/
                    /*receivedFees: formatMoney(this.state.receivedFees),*/
                    note: this.state.note,
                    processMovements: processMovementsUpdate
                };
                await saveProcess(process);
            } else {
                let process = {
                    idProcess: this.props.process.idProcess,
                    updatedAt: this.props.process.updatedAt,
                    status: this.state.status,
                    concluisionDate: this.state.concluisionDate,
                    note: this.state.note,
                    processMovements: processMovementsUpdate
                };
                await saveProcessArithmetician(process);
            }
            global.SnackBar.show('SUCCESS', 'Registro salvo');
            this.setState({ messageLoading: null });
            this.props.openOrClose(null, true);
        } catch (error) {
            this.setState({ messageLoading: null });
            global.SnackBar.show('ERROR', error.message, 5000);
        }
    };

    loadingSelects = async () => {
        if (!this.state.loadedSelects) {
            try {
                this.setState({ messageLoading: 'Carregando informações...' });
                const [{ laborCourts }, { goals }, { caseFiles }, { users }] = await Promise.all([
                    getLaborCourt(),
                    getGoal(),
                    getCaseFile(),
                    getUser()
                ]);
                let adic = {};
                const experts = users.filter(user => Profile.isExpert(user.profile));
                if (experts.length === 1) {
                    adic.idExpert = experts[0].idUser;
                }
                this.setState({
                    laborCourts,
                    goals,
                    caseFiles,
                    users,
                    loadedSelects: true,
                    messageLoading: null,
                    ...adic
                });
            } catch (error) {
                this.setState({ messageLoading: null });
                global.SnackBar.show('ERROR', error.message);
            }
        }
    };

    setLaborCourtCode = () => {
        let number = formatNumberProcess(this.state.number);
        if (number.length === 25 && this.state.idLaborCourt === '') {
            const code = number.toString().substring(21, 25);
            const laborCourt = this.state.laborCourts.find(type => type.code === code);
            if (laborCourt) {
                this.setState({
                    idLaborCourt: laborCourt.idLaborCourt
                });
            }
        }
        this.setState({
            number
        });
    };

    changeValue = (value, index, attribute) => {
        console.log(value, index, attribute);
        if (value === null || value === undefined) {
            value = '';
        }
        let array = this.state.processMovements;
        if (attribute === 'isDeleted' && array[index]['isNew']) {
            array.splice(index, 1);
        } else {
            array[index][attribute] = value;
        }
        this.setState({
            processMovements: array
        });
    };

    getValueDate = date => {
        if (date === undefined) {
            return '';
        }
        if (date === null) {
            return '';
        }
        if (date === '') {
            return '';
        }
        return new Date(date);
    };

    addProcessMovement = () => {
        let array = this.state.processMovements;
        let newMovement = {
            idProcessMovement: '',
            uploadDate: new Date(),
            expirationDate: '',
            idArithmetician: '',
            idGoal: '',
            status: 'PEND',
            concluisionDate: '',
            Goal: {
                description: ''
            },
            isNew: true,
            isExpanded: true
        };
        array.push(newMovement);
        this.setState({
            processMovements: array
        });
    };

    render() {
        const { classes, fullScreen, user, process } = this.props;
        const isAdmin = Profile.isAdmin(user.profile);
        let isCompleted = false;
        if (process) {
            if (process.status === 'COMP') {
                //isCompleted = true;
            }
        }

        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.openOrClose(null, false)}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                    fullScreen={fullScreen}
                    fullWidth
                    disableBackdropClick
                    scroll="body"
                >
                    <DialogTitle id="form-dialog-title">
                        Processo
                        {this.props.process && isAdmin && false && (
                            <IconButton
                                onClick={() => {
                                    OpenReport('R001', { idProcess: this.props.process.idProcess });
                                }}
                            >
                                <PrintIcon />
                            </IconButton>
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="input-number-process">Número</InputLabel>
                                    <Input
                                        disabled={!isAdmin}
                                        value={this.state.number}
                                        onChange={event => {
                                            this.setState({ number: event.target.value });
                                        }}
                                        id="input-number-process"
                                        error={!isValidNumberProcess(this.state.number) && this.state.number !== ''}
                                        //inputComponent={TextMaskNumberProcess}
                                        onBlur={this.setLaborCourtCode}
                                        autoFocus
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-labor-court">Vara Trabalhista</InputLabel>
                                    <Select
                                        disabled={!isAdmin}
                                        value={this.state.idLaborCourt}
                                        onChange={event => {
                                            this.setState({ idLaborCourt: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-labor-court' }}
                                    >
                                        {this.state.laborCourts.map((laborCourt, index) => (
                                            <MenuItem value={laborCourt.idLaborCourt} key={index}>
                                                {laborCourt.code} - {laborCourt.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-case-file">Autos</InputLabel>
                                    <Select
                                        disabled={!isAdmin}
                                        value={this.state.idCaseFile}
                                        onChange={event => {
                                            this.setState({ idCaseFile: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-case-file' }}
                                    >
                                        {this.state.caseFiles.map((caseFile, index) => (
                                            <MenuItem value={caseFile.idCaseFile} key={index}>
                                                {caseFile.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    label="Reclamante"
                                    fullWidth
                                    value={this.state.claimant}
                                    onChange={event => {
                                        this.setState({ claimant: event.target.value });
                                    }}
                                    disabled={!isAdmin}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    label="Reclamado"
                                    fullWidth
                                    value={this.state.claimed}
                                    onChange={event => {
                                        this.setState({ claimed: event.target.value });
                                    }}
                                    disabled={!isAdmin}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-expert">Perito</InputLabel>
                                    <Select
                                        disabled={!isAdmin}
                                        value={this.state.idExpert}
                                        onChange={event => {
                                            this.setState({ idExpert: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-expert' }}
                                    >
                                        <MenuItem value={''} />
                                        {this.state.users.map(
                                            (user, index) =>
                                                Profile.isExpert(user.profile) && (
                                                    <MenuItem value={user.idUser} key={index}>
                                                        {user.name}
                                                    </MenuItem>
                                                )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {isAdmin && (
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="money-arbitrated-fees">Honorários Arbit.</InputLabel>
                                        <Input
                                            id="money-arbitrated-fees"
                                            type="number"
                                            value={this.state.arbitratedFees}
                                            onBlur={() => setStateMoney(this, 'arbitratedFees')}
                                            onChange={event => {
                                                this.setState({ arbitratedFees: event.target.value });
                                            }}
                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {/*isAdmin &&
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                    <DateTextField
                                        fullWidth
                                        label="Data de Receb. Hon."
                                        value={this.state.receiptDate}
                                        onChange={date => { this.setState({ receiptDate: date }) }}
                                    />
                                </Grid>
                            */}
                            {isAdmin && (
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="money-received-fees">Honorários Receb.</InputLabel>
                                        <Input
                                            id="money-received-fees"
                                            type="number"
                                            value={this.state.receivedFees}
                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                            readOnly
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-status">Situação</InputLabel>
                                    <Select
                                        disabled={!isAdmin && isCompleted}
                                        value={this.state.status}
                                        onChange={event => {
                                            const status = event.target.value;
                                            this.setState({ status });
                                            if (status === 'COMP') {
                                                if (this.state.concluisionDate === '') {
                                                    this.setState({ concluisionDate: new Date() });
                                                }
                                            }
                                        }}
                                        inputProps={{ id: 'select-status' }}
                                    >
                                        {typeStatusProcess.map((status, index) => (
                                            <MenuItem value={status.idType} key={index}>
                                                {status.dsType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                <DateTextField
                                    fullWidth
                                    label="Data de Conclusão"
                                    value={this.state.concluisionDate}
                                    onChange={date => this.setState({ concluisionDate: date })}
                                    disabled={!isAdmin && isCompleted}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label="Observações"
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                    value={this.state.note}
                                    onChange={e => this.setState({ note: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '0.8rem' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                        Movimentações
                                    </Typography>
                                    {isAdmin && (
                                        <Fab
                                            size="small"
                                            color="secondary"
                                            aria-label="Add"
                                            style={{ marginLeft: '1rem' }}
                                            onClick={this.addProcessMovement}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '0.2rem' }}>
                                {this.state.processMovements.map((processMovement, index) => (
                                    <ExpansionPanel
                                        key={index}
                                        onChange={() => this.changeValue(!processMovement.isExpanded, index, 'isExpanded')}
                                        expanded={processMovement.isExpanded}
                                    >
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>
                                                <span
                                                    style={
                                                        processMovement.isDeleted
                                                            ? { textDecoration: 'line-through', color: 'red' }
                                                            : processMovement.isNew
                                                            ? { color: 'blue' }
                                                            : null
                                                    }
                                                >
                                                    {index + 1} - {formatDateBRZ(processMovement.uploadDate)} - {processMovement.Goal.description} -{' '}
                                                    {getDsTypeStatusProcess(processMovement.status).dsType} -{' '}
                                                    {(processMovement.paid === 'PAID' || processMovement.paid === 'UNPAID') &&
                                                        `PAGAMENTO PROCESSADO: ${processMovement.idPaymentProcess || ''}`}
                                                </span>
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container direction="row" spacing={16}>
                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                                    <DateTextField
                                                        fullWidth
                                                        label="Data de Carga"
                                                        value={this.getValueDate(processMovement.uploadDate)}
                                                        onChange={date => this.changeValue(date, index, 'uploadDate')}
                                                        disabled={!isAdmin || processMovement.paid === 'PAID' || processMovement.paid === 'UNPAID'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="select-goal">Finalidade</InputLabel>
                                                        <Select
                                                            disabled={
                                                                (!isAdmin && isCompleted) ||
                                                                processMovement.paid === 'PAID' ||
                                                                processMovement.paid === 'UNPAID'
                                                            }
                                                            value={processMovement.idGoal}
                                                            onChange={event => this.changeValue(event.target.value, index, 'idGoal')}
                                                            inputProps={{ id: 'select-goal' }}
                                                        >
                                                            {this.state.goals.map((goal, index) => (
                                                                <MenuItem value={goal.idGoal} key={index}>
                                                                    {goal.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                                    <DateTextField
                                                        fullWidth
                                                        label="Data de Vencimento"
                                                        value={this.getValueDate(processMovement.expirationDate)}
                                                        onChange={date => this.changeValue(date, index, 'expirationDate')}
                                                        disabled={!isAdmin || processMovement.paid === 'PAID' || processMovement.paid === 'UNPAID'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="select-arithmetician">Calculista</InputLabel>
                                                        <Select
                                                            disabled={
                                                                (!isAdmin && isCompleted) ||
                                                                processMovement.paid === 'PAID' ||
                                                                processMovement.paid === 'UNPAID'
                                                            }
                                                            value={processMovement.idArithmetician || ''}
                                                            onChange={event => this.changeValue(event.target.value, index, 'idArithmetician')}
                                                            inputProps={{ id: 'select-arithmetician' }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    value={''}
                                                                    style={{
                                                                        width: '20%'
                                                                    }}
                                                                ></MenuItem>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'flex-end',
                                                                        width: '80%'
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h6"
                                                                        color="inherit"
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            fontFamily: 'Arial',
                                                                            fontSize: '0.9rem',
                                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                                            marginRight: '0.6rem'
                                                                        }}
                                                                    >
                                                                        Mostrar inativos
                                                                    </Typography>

                                                                    <Switch
                                                                        checked={this.state.showCalculistaInativos}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                showCalculistaInativos: e.target.checked
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            {filterUserActive(
                                                                this.state.users,
                                                                this.state.showCalculistaInativos,
                                                                processMovement.idArithmetician
                                                            ).map(
                                                                (user, index) =>
                                                                    Profile.isArithmetician(user.profile) && (
                                                                        <MenuItem value={user.idUser} key={index}>
                                                                            <span
                                                                                style={{
                                                                                    textDecoration: user.inactive ? 'line-through' : undefined
                                                                                }}
                                                                            >
                                                                                {user.name}
                                                                            </span>
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="select-status">Situação</InputLabel>
                                                        <Select
                                                            disabled={
                                                                (!isAdmin && isCompleted) ||
                                                                processMovement.paid === 'PAID' ||
                                                                processMovement.paid === 'UNPAID'
                                                            }
                                                            value={processMovement.status}
                                                            onChange={event => {
                                                                const status = event.target.value;
                                                                this.changeValue(status, index, 'status');
                                                                if (processMovement.status === 'COMP') {
                                                                    if (
                                                                        processMovement.concluisionDate === '' ||
                                                                        processMovement.concluisionDate === null
                                                                    ) {
                                                                        this.changeValue(new Date(), index, 'concluisionDate');
                                                                    }
                                                                }
                                                            }}
                                                            inputProps={{ id: 'select-status' }}
                                                        >
                                                            {typeStatusProcess.map((status, index) => (
                                                                <MenuItem value={status.idType} key={index}>
                                                                    {status.dsType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
                                                    <DateTextField
                                                        fullWidth
                                                        label="Data de Conclusão"
                                                        value={this.getValueDate(processMovement.concluisionDate)}
                                                        onChange={date => this.changeValue(date, index, 'concluisionDate')}
                                                        disabled={
                                                            (!isAdmin && isCompleted) ||
                                                            processMovement.paid === 'PAID' ||
                                                            processMovement.paid === 'UNPAID'
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                        <Divider />
                                        <ExpansionPanelActions>
                                            {isAdmin &&
                                                (!processMovement.isDeleted || processMovement.isDeleted === undefined ? (
                                                    <IconButton
                                                        onClick={() => this.changeValue(true, index, 'isDeleted')}
                                                        disabled={processMovement.paid === 'PAID' || processMovement.paid === 'UNPAID'}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => this.changeValue(false, index, 'isDeleted')}
                                                        disabled={processMovement.paid === 'PAID' || processMovement.paid === 'UNPAID'}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                ))}
                                        </ExpansionPanelActions>
                                    </ExpansionPanel>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        {this.state.messageLoading && (
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <CircularProgress size={25} color="secondary" />
                                <Typography variant="h6" style={{ marginLeft: '0.6rem', fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.65)' }}>
                                    {this.state.messageLoading}
                                </Typography>
                            </div>
                        )}
                        <Button onClick={() => this.props.openOrClose(null, false)} color="primary" disabled={!!this.state.messageLoading}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={this.saveProcess}
                            color="primary"
                            variant="contained"
                            disabled={!!this.state.messageLoading || (!isAdmin && isCompleted)}
                        >
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

/*function TextMaskNumberProcess(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}*/

const styles = {
    root: {
        flexGrow: 1
    }
};

RegisterProcess.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterProcess));

import api from '../api';

export async function getCreateBackup() {
    try {
        const response = await api.get('/infra/create-backup-database?token=a71efb54ec111710e81fcf90a396e77f');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GoalIcon from '@material-ui/icons/RedoRounded';

import RegisterArithmeticianPaymentValue from './RegisterArithmeticianPaymentValue';
import TableResponsive from '../../utils/TableResponsive';
import Profile from '../../utils/Profile';

import arithmeticianPaymentValueAPI from '../../../api/consumer/arithmeticianPaymentValue';
import { getGoal } from '../../../api/consumer/goal';
import { getUser } from '../../../api/consumer/user';
import { formatDateTimeBRZ } from '../../utils/DataFormat';
import { formatMoneySymbol } from '../../utils/NumberFormat';

export class ListArithmeticianPaymentValue extends Component {

    state = {
        open: false,
        arithmeticianPaymentValues: [],
        arithmeticianPaymentValue: null,
        loading: true
    }

    componentWillMount() {
        this.loadingArithmeticianPaymentValues();
    }

    loadingArithmeticianPaymentValues = async () => {
        try {
            this.setState({                
                loading: true                
            });
            const [{ arithmeticianPaymentValues: arithmeticianPaymentValuesCurrent }, { goals }, { users }] = await Promise.all([
                arithmeticianPaymentValueAPI('GET', { currentValues: true }),
                getGoal(),
                getUser()
            ]);
            let goalValuePays = [];            
            goals.forEach((goal) => {
                users.forEach((user) => {
                    if (Profile.isArithmetician(user.profile)) {
                        let current = arithmeticianPaymentValuesCurrent.find(arithmeticianPaymentValues2 => (
                            arithmeticianPaymentValues2.idGoal === goal.idGoal && 
                            arithmeticianPaymentValues2.idArithmetician === user.idUser
                        ));                                            
                        if (current) {
                            goalValuePays.push(current)                            
                        } else {
                            current = {
                                idGoal: goal.idGoal,
                                idArithmetician: user.idUser,
                                Goal: goal,
                                Arithmetician: user
                            };
                            goalValuePays.push(current)
                        }
                    }
                });
            });
            this.setState({
                arithmeticianPaymentValues: goalValuePays,
                loading: false                
            });
        } catch (error) {
            this.setState({
                arithmeticianPaymentValues: [],
                loading: false                
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        this.state.arithmeticianPaymentValues.forEach(arithmeticianPaymentValue => {            
            let dateCell = {
                description: arithmeticianPaymentValue.Goal.description,
                name: arithmeticianPaymentValue.Arithmetician.name,
                validityDateBegin: formatDateTimeBRZ(arithmeticianPaymentValue.validityDateBegin),
                validityDateEnd: formatDateTimeBRZ(arithmeticianPaymentValue.validityDateEnd),
                value: formatMoneySymbol(arithmeticianPaymentValue.value),
                action: this.actions(arithmeticianPaymentValue)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (arithmeticianPaymentValue) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => {this.onEditArithmeticianPaymentValue(arithmeticianPaymentValue)}}>
                <EditIcon />
            </IconButton>
        </div>
    )

    openOrClose = (arithmeticianPaymentValue = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            arithmeticianPaymentValue,
        });
        if (!open) {
            this.loadingArithmeticianPaymentValues();
        }
    }

    onEditArithmeticianPaymentValue = (arithmeticianPaymentValue) => {        
        this.openOrClose(arithmeticianPaymentValue);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListArithmeticianPaymentValue} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <GoalIcon style={{ marginRight: '0.6rem' }} />
                        <Typography variant="h5" color="inherit">
                            Valor Pagamento por Finalidade
                        </Typography>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        loading={this.state.loading}
                        showTotalRecords
                    />
                </Paper>
                <RegisterArithmeticianPaymentValue open={this.state.open} openOrClose={() => this.openOrClose()} arithmeticianPaymentValue={this.state.arithmeticianPaymentValue} />                
            </div>
        );
    }
};

const columns = [
    {
        id: 'description',
        label: 'FINALIDADE'
    },
    {
        id: 'name',
        label: 'CALCULISTA'
    },
    {
        id: 'validityDateBegin',
        label: 'INÍCIO VIGÊNCIA'
    },
    {
        id: 'validityDateEnd',
        label: 'FIM VIGÊNCIA'
    },
    {
        id: 'value',
        label: 'VALOR'
    },
    {
        id: 'action',
        label: ''
    }
]

const styles = {
    paperListArithmeticianPaymentValue: {
        padding: '1rem'
    }
}

ListArithmeticianPaymentValue.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListArithmeticianPaymentValue);

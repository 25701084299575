import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

class BadgeAllPasso extends React.Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <img
                        style={{
                            width: 120
                        }}
                        src="/images/logo_all.png"
                        alt="Logo AllPasso"
                    />
                </div>
            </div>
        );
    }
}

const styles = theme => ({});

BadgeAllPasso.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BadgeAllPasso);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NotificationsIcon from '@material-ui/icons/Mail';

import TableResponsive from '../../utils/TableResponsive';

export class MessageNotif extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Paper className={classes.paperListMessage} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <NotificationsIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Mensagens
                        </Typography>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={[/*{createdAt: <b>30/01/2019 14:20:15</b>, description: <b>Sistema ficará indisponível entre 03:00 AM e 08:00 AM no dia 05/02/2018</b>}*/]}
                        showTotalRecords
                    />
                </Paper>
            </div>
        );
    }
};

const columns= [
    {id:'createdAt', label: 'Data/Hora'},
    {id: 'description', label: 'Descrição'}
]

const styles = {
    container: {
        padding: '0.5rem',
    },
    paperListMessage: {
        padding: '1rem'
    },    
}

MessageNotif.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageNotif);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import TableResponsive from '../../utils/TableResponsive';

import { getProcessesPending } from '../../../api/consumer/query';

export class ProcessPending extends Component {

    state = {
        processesPending: [],
        loading: false
    }

    componentDidMount() {
        this.loading();
    }

    loading = async () => {
        try {
            this.setState({loading: true})
            const response = await getProcessesPending();
            this.setState({
                processesPending: response.processesPendings,
                loading: false
            });
        } catch (error) {
            this.setState({
                processesPending: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        let totPending = 0;
        let totExpired = 0;
        let toToExpire = 0;
        this.state.processesPending.forEach(processPending => {            
            let dateCell = {                                      
                nameLaborCourt: `${processPending.code} - ${processPending.name}`,
                pending: processPending.pending,
                expired: processPending.expired,
                toExpire: processPending.toExpire,
            }

            totPending += parseInt(processPending.pending);
            totExpired += parseInt(processPending.expired);
            toToExpire += parseInt(processPending.toExpire);

            data.push(dateCell);            
        });

        if (data.length > 0) {
            data.push({
                nameLaborCourt: <b>Total</b>,
                pending: <b>{totPending}</b>,
                expired: <b>{totExpired}</b>,
                toExpire: <b>{toToExpire}</b>,
            });
        }
    
        return data;
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridList} spacing={16} direction="column">
                <Paper className={classes.paper} elevation={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                      
                        <Typography variant="h5" color="inherit" className={classes.fontTitle}>
                            Processos Pendentes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop: '1rem'}}>
                        <TableResponsive
                            columns={columns}
                            values={this.data()}
                            loading={this.state.loading}
                            fontContent={{fontSize: '0.9rem'}}
                        />
                    </Grid>
                </Paper>                
            </Grid>
        );
    }
};

const columns = [
    {
        id: 'nameLaborCourt',
        label: 'VARA TRABALHISTA'
    },
    {
        id: 'pending',
        label: 'PENDENTES'
    },
    {
        id: 'expired',
        label: 'VENCIDOS'
    },
    {
        id: 'toExpire',
        label: 'Á VENCER'
    },            
]

const styles = {
    root: {
        flexGrow: 1,
    },
    fontTitle: {
        fontSize: '1.1rem',
        textAlign: 'center'
    },
    paper: {
        padding: '20px'
    }
}

ProcessPending.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProcessPending);
import api from '../api';
import Token from '../Token';

export async function validateAuth(email, password, remember = false) {
    try {
        const response = await api.post('/auth/authenticate', {
            email, password
        });      
        Token.saveToken(response.data.token, remember);
        Token.saveUser(response.data.user);  
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data);
    }
}

export async function recoverSend(email) {
    try {
        const response = await api.post('/auth/recover/send', {
            email
        });        
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data);
    }
}

export async function recoverPassword(token, password) {
    try {
        const response = await api.post('/auth/recover/password', {
            token, password
        });        
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data);
    }
}

export async function verifyEmail(token, password) {
    try {
        const response = await api.post('/auth/verify/email', {
            token, password
        });        
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data);
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
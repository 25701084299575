import api from '../api';

export async function getOneProcess(idProcess) {
    try {
        const response = await api.get(`/process/${idProcess}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getProcess(filter) {
    try {
        const response = await api.get('/process', {
            params: filter
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveProcess(process) {
    try {
        process = treatsEmpty(process);
        let response;
        if (process.idProcess === null) {
            response = await api.post('/process', process);
        } else {
            response = await api.put(`/process/${process.idProcess}`, process);
        }
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveProcessArithmetician(process) {
    try {
        process = treatsEmpty(process);
        let response = await api.patch(`/process/${process.idProcess}`, process);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function deleteProcess(idProcess) {
    try {
        const response = await api.delete(`/process/${idProcess}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}

function treatsEmpty(obj) {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            if (obj[i] === '') {
                obj[i] = null;
            } 
            if (typeof obj[i] === 'object') {
                obj[i] = treatsEmpty(obj[i]);
            }
        }
    } else {
        for (let attr in obj) {
            if (obj[attr] === '') {
                obj[attr] = null;
            }  
            if (typeof obj[attr] === 'object') {
                obj[attr] = treatsEmpty(obj[attr]);
            }      
        }
    }
    return obj;
}

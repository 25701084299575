import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { recoverSend } from '../../api/consumer/auth';
import Redirector from '../../Redirector';
import BadgeAllPasso from '../utils/BadgeAllPasso';

class RecoverSend extends React.Component {
    state = {
        waitSend: false,
        email: ''
    };

    sendEmail = async () => {
        try {
            if (this.state.email === '') {
                global.SnackBar.show('WARNING', 'E-mail é obrigatório!');
                return;
            }
            this.setState({ waitSend: true });
            const message = await recoverSend(this.state.email);
            this.setState({
                email: '',
                waitSend: false
            });
            global.SnackBar.show('SUCCESS', message);
            setTimeout(() => {
                Redirector.login(this, 401);
            }, 3000);
        } catch (error) {
            this.setState({ waitSend: false });
            if (error.code === undefined) {
                global.SnackBar.show('ERROR', 'Sem conexão com o servidor de dados!');
            } else {
                global.SnackBar.show('ERROR', `${error.code} - ${error.message}`);
            }
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.bodyContainer}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Paper className={classes.paperContainer} elevation={15} square={true}>
                            <Typography variant="h5" component="h3" className={classes.fontHeader}>
                                Recuperar Senha
                            </Typography>
                            <Grid container className={classes.gridForm}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <TextField
                                        label="E-mail"
                                        className={classes.textField}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        fullWidth
                                        value={this.state.email}
                                        onChange={event => {
                                            this.setState({ email: event.target.value });
                                        }}
                                    />
                                </Grid>
                                {this.state.waitSend ? (
                                    <Grid item xs={12} className={classes.gridProgress}>
                                        <LinearProgress color="secondary" />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} className={classes.gridButtonLogin}>
                                        <Button variant="contained" color="primary" fullWidth onClick={this.sendEmail}>
                                            Enviar e-mail
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                        <div className={classes.fromText}>
                            Powered by <BadgeAllPasso />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = {
    bodyContainer: {
        height: '100vh',
        backgroundColor: '#e6e9ea'
    },
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    fontHeader: {
        color: '#373737',
        marginLeft: '12px',
        marginTop: '10px'
    },
    fontLogo: {
        color: '#FFF',
        fontFamily: 'FontLogo',
        fontSize: '1.90rem',
        margin: '12px'
    },
    centered: {
        textAlign: 'center'
    },
    gridContainer: {
        height: '95vh',
        padding: '10px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paperContainer: {
        padding: '20px',
        borderRadius: '9px 9px 9px 9px'
    },
    gridForm: {
        padding: '15px'
    },
    gridItem: {
        marginBottom: '21px'
    },
    gridButtonLogin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    gridProgress: {
        marginTop: '25px',
        flexGrow: 1
    },
    paperFooter: {
        backgroundColor: '#1E004A',
        padding: '20px',
        borderRadius: '12px 12px 12px 12px'
    },
    fontMarq: {
        color: '#FFF',
        textAlign: 'center'
    },
    buttonRecover: {
        marginTop: '15px',
        textDecoration: 'none'
    },
    fromText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '0.875rem',
        color: '#6B6B6B',
        paddingTop: '20px'
    }
};

RecoverSend.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RecoverSend);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../components/utils/Loader';
import { getUserAuth } from '../api/consumer/user';

import Token from '../api/Token';
import Profile from '../components/utils/Profile';

export class PrivateRoute extends React.Component {

    componentWillMount() {
        this.setConfig(this.props);
    }

    componentWillReceiveProps(props) {
        this.setConfig(props);
    }

    setConfig = (props) => {
        const { component: ComponentPrivate, ...rest } = props;

        var typeShow = 0;

        if (Token.getToken() === '') {
            typeShow = 1;
        }
        
        this.setState({
            typeShow,
            ComponentPrivate,
            propsRoute: rest,
            user: null,
            errorTitle: null,
            errorMessage: null
        });

        this.validAuthenticated();
    }

    validAuthenticated = async () => {
        try {                        
            let user = await Token.getUser();                        
            if (!user) {
                const response = await getUserAuth();
                user = response.user;
                Token.saveUser(user);
                console.log('VERSION_BACKEND', new Date(response.version).toLocaleString());
            }                      
            if (this.state.propsRoute.path === '/admin' && !Profile.isSuperAdmin(user.profile)) {
                this.setState({
                    typeShow: null,
                    errorTitle: 'Acesso negado',
                    errorMessage: 'Página disponível apenas para administradores.'
                });
                return;
            }                        
            if ((this.state.propsRoute.path === '/main/laborcourt' ||
                this.state.propsRoute.path === '/main/user' ||
                this.state.propsRoute.path === '/main/goal' ||
                this.state.propsRoute.path === '/main/goal-value-pay' ||            
                this.state.propsRoute.path === '/main/casefile' ||
                this.state.propsRoute.path === '/main/payment') 
                && !Profile.isAdmin(user.profile)) {
                this.setState({
                    typeShow: null,
                    errorTitle: 'Acesso negado',
                    errorMessage: 'Página disponível apenas para administradores, contate o responsável pela empresa.'
                });
                return;
            }            

            this.setState({
                user, typeShow: 2
            });
        } catch (error) {            
            if (error.code === 401) {
                Token.removeToken();
                this.setState({
                    typeShow: 1
                });
            } else if (error.code === 403) {
                Token.removeToken();
                this.setState({
                    typeShow: null,
                    errorTitle: `Acesso negado`,
                    errorMessage: error.message
                });
            } else {
                this.setState({
                    typeShow: null,
                    errorTitle: error.code,
                    errorMessage: error.message
                });
            }
        }
    }

    render() {
        
        switch (this.state.typeShow) {

            case 0: //Loading

                return (
                    <Route
                        {...this.state.propsRoute}
                        render={props =>
                            <Loader />
                        }
                    />
                );

            case 1: //Login

                return (
                    <Route
                        {...this.state.propsRoute}
                        render={props =>
                            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                        }
                    />
                );

            case 2: // ComponentPrivate

                return (
                    <Route
                        {...this.state.propsRoute}
                        render={props =>
                            <this.state.ComponentPrivate {...props} user={this.state.user} />
                        }
                    />
                );

            case 3: // Main

                return (
                    <Route
                        {...this.state.propsRoute}
                        render={props =>
                            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                        }
                    />
                );

            default: //Error

                return (
                    <Route
                        {...this.state.propsRoute}
                        render={props =>
                            <Redirect to={{ pathname: `/error/${this.state.errorTitle}/${this.state.errorMessage}`, state: { from: props.location } }} />
                        }
                    />
                );
        }

    }
}

export default PrivateRoute;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SuccessIcon from '@material-ui/icons/DoneOutlineRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import OpenReport from '../Report/OpenReport';

import Profile from '../../utils/Profile';
import TableResponsive from '../../utils/TableResponsive';
import paymentProcessAPI from '../../../api/consumer/paymentProcess';

import { formatMoneySymbol } from '../../utils/NumberFormat';
import { getUser } from '../../../api/consumer/user';
import { getMonths } from '../../utils/DataFormat';

export class RegisterPaymentProcess extends Component {

    state = {
        arithmeticians: [],
        loading: true,
        year: new Date().getFullYear(),
        month:  (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1),
        isToProcessAll: false,
        saving: false
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            this.setState({
                year: new Date().getFullYear(),
                month:  (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1),
                isToProcessAll: false
            });
            this.loadingPaymentProcesss();
        }
    }

    loadingPaymentProcesss = async () => {
        try {
            this.setState({  
                arithmeticians: [],
                loading: true                
            });
            const response = await getUser();
            let arithmeticians = []; 
            response.users.forEach((user) => {
                if (Profile.isArithmetician(user.profile) && !user.inactive) {
                    user.isToProcess = false;
                    user.statusProcess = 'PENDING';   //PENDING, PROCESSING, SUCCESS, ERROR
                    user.return = '';
                    arithmeticians.push(user);
                }                
            });
            this.setState({
                arithmeticians,
                loading: false
            });
        } catch (error) {
            this.setState({                
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    clearLaborCourt = () => {
        this.setState({
            code: '',
            name: ''
        });
    }

    processPayment = async () => {

        if (this.state.month === '') {
            global.SnackBar.show('WARNING', 'Informe o mês de processamento');
            return;
        }

        if (this.state.year === '') {
            global.SnackBar.show('WARNING', 'Informe o ano de processamento');
            return;
        }

        this.setState({
            saving: true
        });

        try {

            for (let i = 0; i < this.state.arithmeticians.length; i++) {
                const {statusProcess} = this.state.arithmeticians[i];
                if (statusProcess !== 'PENDING') {
                    this.changeValueStatusProcess('PENDING', '', i); 
                }                                                                                        
            }
            
            for (let i = 0; i < this.state.arithmeticians.length; i++) {                 
                const {idUser, isToProcess} = this.state.arithmeticians[i];
                if (isToProcess) {
                    try {                                      
                        this.changeValueStatusProcess('PROCESSING', '', i);                    
                        const response = await paymentProcessAPI('CREATE', {month: parseInt(this.state.month), year: parseInt(this.state.year), idArithmetician: idUser});
                        this.changeValueStatusProcess('SUCCESS', `Movimento gerado com sucesso! Valor Total: ${formatMoneySymbol(response.paymentProcess.amount)}`, i);                    
                    } catch (error) {                    
                        this.changeValueStatusProcess('ERROR', error.message, i);
                    }  
                }
            }

            this.setState({
                saving: false
            });

        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    changeValueStatusProcess = (statusProcess, returnS, index) => {
        let array = this.state.arithmeticians;
        array[index]['statusProcess'] = statusProcess;        
        array[index]['return'] = returnS;        
        this.setState({
            arithmeticians: array
        })
    }

    columns = () => {

        var columns = [];
        columns.push({id: 'isToProcess', label: <Checkbox
                                                    checked={this.state.isToProcessAll}
                                                    onChange={(event) => this.setToProcessAll(event.target.checked)}
                                                />});
        columns.push({id: 'name', label: 'CALCULISTA'});
        columns.push({id: 'statusProcess', label: 'Situação'});        
        columns.push({id: 'return', label: 'Retorno'});
        columns.push({id: 'previous', label: ''});
    
        return columns;
    }

    data = (classes) => {
        var data = [];
        this.state.arithmeticians.forEach((arithmetician, index) => {
            let dateCell = {                
                isToProcess:  <Checkbox
                                checked={arithmetician.isToProcess}
                                onChange={(event) => this.changeValue(event.target.checked, index, 'isToProcess')}                              
                            />, 
                name: arithmetician.name,               
                statusProcess: this.returnIconStatusProcess(arithmetician.statusProcess),
                error: arithmetician.error,
                return: arithmetician.return,    
                previous: this.returnIconPrint(classes, arithmetician)            
            }
            data.push(dateCell);
        });
        return data;
    }

    openReportPrevious = (arithmetician) => {
        const {month, year, statusProcess} = this.state;
        if (statusProcess === 'SUCCESS') {
            OpenReport('R002', {month, year, idArithmetician: arithmetician.idUser, showSignature: true});
        } else {
            OpenReport('R002', {month, year, idArithmetician: arithmetician.idUser});
        }        
    }

    returnIconStatusProcess = (statusProcess) => {
        switch (statusProcess) {
            case 'PROCESSING':
                return <CircularProgress size={20} color="secondary" />;
            case 'SUCCESS':  
                return <SuccessIcon style={{color: 'green'}} />;
            case 'ERROR':
                return <ErrorIcon  style={{color: '#C97912'}} />;             
            default:
                return '';
        }
    }

    returnIconPrint = (classes, arithmetician) => {
        switch (arithmetician.statusProcess) {
            case 'PENDING':
                return  <Fab variant="extended" color="inherit" size="small" className={classes.fab} onClick={() => this.openReportPrevious(arithmetician)}>
                            <PrintIcon style={{marginRight: '0.5rem'}}/>
                            Prévia
                        </Fab>
            case 'SUCCESS':  
                return  <Fab variant="extended" color="secondary" size="small" className={classes.fab} onClick={() => this.openReportPrevious(arithmetician)}>
                            <PrintIcon style={{marginRight: '0.5rem'}}/>
                            Demonstrativo
                        </Fab>;  
            case 'ERROR':
                return  <Fab variant="extended" color="inherit" size="small" className={classes.fab} onClick={() => this.openReportPrevious(arithmetician)}>
                            <PrintIcon style={{marginRight: '0.5rem'}}/>
                            Prévia
                        </Fab>            
            default:
                return '';
        }
    }

    changeValue = (value, index, attribute) => {
        if (value === null) {
            value = '';
        }
        let array = this.state.arithmeticians;
        array[index][attribute] = value;        
        this.setState({
            arithmeticians: array
        })
    }

    setToProcessAll = (checked) => {
        this.setState({
            isToProcessAll: checked
        });        
        this.state.arithmeticians.map((arithmetician) => {
            arithmetician.isToProcess = checked;
            return arithmetician;
        });
    }

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                    fullWidth
                    fullScreen={fullScreen}
                    scroll="body"
                >
                    <DialogTitle id="form-dialog-title">Gerar Processamento</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={16} direction="row" style={{paddingTop: '1rem'}}>
                            <Grid item xs={8} sm={6} md={4} lg={3} xl={2}> 
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-month">Mês</InputLabel>
                                    <Select                                                                                
                                        value={this.state.month}
                                        onChange={(event) => {this.setState({month: event.target.value})}}
                                        inputProps={{id: 'select-month'}}                                    
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        {getMonths().map((month, index) => (
                                            <MenuItem value={month.cdMonth} key={index}>{month.dsMonth}</MenuItem> 
                                        ))}
                                    </Select>   
                                </ FormControl>
                            </Grid>
                            <Grid item xs={4} sm={6} md={2} lg={2} xl={1}>  
                                <TextField                  
                                    fullWidth          
                                    label="Ano"
                                    value={this.state.year}
                                    onChange={(e) => this.setState({year: e.target.value})}
                                    type="number"                                                     
                                />
                            </Grid> 
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                                <TableResponsive
                                    columns={this.columns()}
                                    values={this.data(classes)}
                                    showTotalRecords
                                    loading={this.state.loading}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.processPayment} color="primary" variant="contained" disabled={this.state.saving || this.state.loading}>
                            Processar
                        </Button>
                    </DialogActions>
                </Dialog>            
            </div>
        );
    }
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    fab: {
        margin: theme.spacing.unit,
        fontSize: '0.8rem'
    }
});

RegisterPaymentProcess.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterPaymentProcess));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Media from "react-media";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

export class TableResponsive extends Component {

    state = {
        positionScroll: 0
    }

    componentWillReceiveProps(props) {
        if (props.loading && props.preserveScroll) {
            if (window.pageYOffset > 0) {
                this.setState({
                    positionScroll: window.pageYOffset
                })                                               
            }                                     
        }          
    }

    componentDidUpdate() {               
        if (this.state.positionScroll > 0 && this.props.preserveScroll && !this.props.loading) {            
            window.scrollTo(0, this.state.positionScroll);
        }        
    }

    render() {        
        let { classes, fontContent } = this.props;
        
        if (this.props.loading) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="h6" color="inherit" style={{textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem'}}>
                        Buscando... 
                    </Typography>
                    <CircularProgress size={20} color="secondary"/>
                </div>
            );
        } else if (this.props.values.length === 0) {
            return (
                <Typography variant="h6" color="inherit" style={{textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)'}}>
                    Nenhum conteúdo
                </Typography>
            );
        } else {
            return (
                <div>
                    <table className={classes.table}>
                        <Media query="(min-width: 1300px)">
                            {matches =>
                                matches ? (
                                    <thead>
                                        <tr className={classes.rowTable}>
                                            {this.props.columns.map((column, index) => (
                                                <th key={index} className={classes.cellHeader}>{column.label}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                )
                                    :
                                    null
                            }
                        </Media>
                        <tbody className={classes.bodyTable}>
                            <Media query="(min-width: 1300px)">
                                {matches =>
                                    matches ?
                                        (this.props.values.map((value, indexData) => (
                                            <tr key={indexData} className={classes.rowTable}>
                                                {this.props.columns.map((column, indexColumn) => (
                                                    <td key={indexColumn} className={classes.cellContent}><div style={{ textAlign: 'center', ...fontContent }}>{value[column.id]}</div></td>
                                                ))}
                                            </tr>
                                        )))
                                        :
        
                                        (this.props.values.map((value, indexData) => (
                                            <tr key={indexData} className={classes.rowTableResp}>
                                                {this.props.columns.map((column, indexColumn) => (
                                                    <td key={indexColumn} className={classes.cellContentResp}><div style={{ textAlign: 'left' }}><b>{column.label !== '' && typeof column.label !== 'object'? `${column.label}:` : '' } </b>{value[column.id]}</div></td>
                                                ))}
                                            </tr>
                                        )))
                                }
                            </Media>
                        </tbody>
                    </table>
                    {this.props.showTotalRecords && 
                        <div>
                            <Typography className={classes.buttonPag} variant="h6" color="inherit" style={{textAlign: 'center', fontFamily: 'Arial', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.65)', marginTop: '0.7rem'}}>
                                {this.props.values.length} resultados
                            </Typography>
                        </div>
                    }
                </div>
            );
        }
    }
};


const styles = {
    table: {
        width: '100%',
        borderSpacing: '0',
        borderCollapse: 'collapse'
    },
    cellHeader: {
        alignText: 'center',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: '500',
        height: '3rem'
    },
    cellContent: {
        alignText: 'center',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '400',
        height: '3rem'
    },
    cellContentResp: {
        alignText: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.2rem',
        marginBottom: '0.2rem'
    },
    rowTable: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    rowTableResp: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',        
    },
    bodyTable: {
        marginTop: '0.8rem'
    }
}

TableResponsive.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
};

export default withStyles(styles)(TableResponsive);

import React from 'react';
import SnackbarUI from '@material-ui/core/Snackbar';
import SuccessIcon from '@material-ui/icons/Done';
import WarnigIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';


export default class Snackbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inOpenSnack: false,
            message: '',
            type: '',
            time: 3000
        };
    }

    show = (type, message, time = 3000) => {
        if (type === 'ERROR') {
            console.log('SNACKBAR-ERROR:', message);
        }
        if (!(typeof message === 'string')) {
            message = JSON.stringify(message);
        }        
        this.setState({ inOpenSnack: true, type, message, time });
    }

    handleHide = () => {
        this.setState({ inOpenSnack: false });
    };

    render() {

        const position = { vertical: 'bottom', horizontal: 'right' }

        switch (this.state.type) {
            case 'SUCCESS':
                return (
                    <SnackbarUI
                        anchorOrigin={position}
                        open={this.state.inOpenSnack}
                        onClose={this.handleHide}
                        autoHideDuration={this.state.time}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SuccessIcon style={{ marginRight: '10px', color: 'green' }} /> {this.state.message}
                        </div>}
                    />
                );
            case 'WARNING':
                return (
                    <SnackbarUI
                        anchorOrigin={position}
                        open={this.state.inOpenSnack}
                        onClose={this.handleHide}
                        autoHideDuration={this.state.time}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <WarnigIcon style={{ marginRight: '10px', color: 'yellow' }} /> {this.state.message}
                        </div>}
                    />
                );
            case 'ERROR':
                return (
                    <SnackbarUI
                        anchorOrigin={position}
                        open={this.state.inOpenSnack}
                        onClose={this.handleHide}
                        autoHideDuration={this.state.time}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ErrorIcon style={{ marginRight: '10px', color: 'red' }} /> {this.state.message}
                        </div>}
                    />
                );
            default:
                return (
                    <SnackbarUI
                        anchorOrigin={position}
                        open={this.state.inOpenSnack}
                        onClose={this.handleHide}
                        autoHideDuration={this.state.time}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{this.state.message}</div>}
                    />
                );
        }
    }
}


import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getGoal } from '../../api/consumer/goal';

export class SelectGoal extends Component {

    state = {
        goals: [],
        loadingGoals: true,
        idGoal: ''
    }

    clickGoals = false;

    loadingGoals = async () => {
        try {
            const response = await getGoal();
            this.setState({
                goals: response.goals,
                loadingGoals: false
            });
        } catch (error) {
            this.setState({
                goals: [],
                loadingGoals: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    onClickGoal = () => {
        if (this.state.goals && !this.clickGoals) {
            this.clickGoals = true;
            this.loadingGoals();
        }
    }

    render() {           
        const id = `ID${Math.trunc(Math.random() * 10000000)}`;         
        /*if (parseInt(this.props.value) > 0) {
            this.onClickGoal();
        }*/       
        return (
            <FormControl fullWidth={this.props.fullWidth} disabled={this.props.disabled}>
                <InputLabel htmlFor={id}>Finalidade</InputLabel>
                <Select
                    value={this.props.value}
                    onChange={(event) => {
                        //let Goal = null;
                        /*let idGoal = parseInt(event.target.value);
                        if (idGoal > 0) {
                            Goal = this.state.loadingGoals.find((goal) =>  goal.idGoal = idGoal);
                        }*/
                        this.props.onChange(event);
                    }}
                    inputProps={{id}}
                    onClick={this.onClickGoal}
                >
                    {this.props.emptyItem && <MenuItem value={''}></MenuItem>}
                    {this.state.loadingGoals ?
                            <MenuItem value={''}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                    Buscando...
                                </Typography>
                                <CircularProgress size={20} color="secondary" />
                            </div>
                        </MenuItem>
                        :
                        this.state.goals.map((goal, index) => (
                            <MenuItem value={goal.idGoal} key={index}>{goal.description}</MenuItem>
                        ))
                    }
                </Select>
            </ FormControl>
        );
    }
};

export default SelectGoal;

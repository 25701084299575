import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { FiDownload, FiFilter } from "react-icons/fi";

import dynamicReportAPI from '../../../api/consumer/dynamicReport';
import TableDynamic from './TableDynamic';

import { formatValues, formatField } from './StructureDynamicReport';
import { getTypeConditionDynamicReport } from '../../../resources/types/typeConditionDynamicReport';

export class TableDynamicReport extends Component {

    state = {
        loading: true,
        error: null,
        params: {},
        data: {}
    }

    componentWillMount() {
        this.loadingParametersAndData();
    }

    loadingParametersAndData = async () => {
        try {

            const dynamicReportId = this.props.match.params.dynamicReportId;
            let params = window.sessionStorage.getItem(`@queryReportDR${dynamicReportId}`);
            if (params) {
                params = JSON.parse(params);
            } else {
                params = {};
            }

            const response = await dynamicReportAPI('GET_QUERYDATA', { idDynamicReport: parseInt(dynamicReportId) });
            const { dynamicReport, fields, values } = response;

            let headers = [];
            for (let column of dynamicReport.parameters.columns) {
                let field = fields.find(fieldStruct => fieldStruct.displayField === column.displayField);
                headers.push(field);
            }

            let groups = [];
            for (let column of dynamicReport.parameters.groups) {
                let field = fields.find(fieldStruct => fieldStruct.displayField === column.displayField);
                groups.push(field);
            }

            let filters = dynamicReport.parameters.filters;

            let data = {
                fields,
                values,
                headers,
                groups,
                filters,
                dynamicReport
            }

            this.setState({
                params,
                data,
                loading: false,
                error: null
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    generateCSV = () => {
        let { headers, fields, values } = this.state.data;
        values = clone(formatValues(fields, values));

        let csv = '';
        let line = '';

        //gerar cabeçalho        
        for (let header of headers) {
            line = `${line}"${header.label}";`
        }
        csv = `${line}\n`;

        //gerar dados
        for (let value of values) {
            line = '';
            for (let header of headers) {
                if (value[header.displayField] !== null) {
                    let val = value[header.displayField];
                    if (header.dataType === 'FLOAT' || header.dataType === 'MONEY') {
                        val = val.toString().replace('.', ',')
                    }
                    line = `${line}"${val}";`;
                } else {
                    line = `${line}"";`;
                }                
            }
            csv = `${csv}${line}\n`;
        }

        let hiddenElement = document.createElement('a');
        let universalBOM = "\uFEFF";
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(`${universalBOM}${csv}`);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${this.state.data.dynamicReport.name}.csv`;
        hiddenElement.click();
    }

    render() {

        if (this.state.error != null) {
            return (
                <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}>
                    <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}>
                        Erro ao buscar dados: {JSON.stringify(this.state.error)}
                    </Typography>
                </Paper>
            );
        } else if (this.state.loading) {
            return (
                <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}>
                    <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}>
                        Buscando dados...
                    </Typography>
                    <CircularProgress size={20} color="secondary" />
                </Paper>
            );
        } else {
            return (
                <React.Fragment>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="inherit" style={{ fontSize: '1rem', flexGrow: 1 }}>
                                {this.state.data.dynamicReport.name}
                            </Typography>
                            <IconButton onClick={this.generateCSV} color="inherit">
                                <FiDownload />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Paper elevation={12} style={{marginTop: '1rem', marginBottom: '1rem', marginLeft: '0.5rem', marginRight: '0.5rem', padding: '0.5rem'}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '0.5rem'}}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '5rem' }}>
                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <FiFilter fontSize="16" />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ fontSize: '1rem' }}>Filtros</Typography>
                                    </div>
                                </div>
                            </Grid>
                            {this.state.data.filters.map((filter, index) => (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index} style={{ marginLeft: '0.7rem'}}>
                                    <Typography style={{ fontSize: '0.9rem' }}>
                                        <b>{filter.label}: </b> 
                                        {getTypeConditionDynamicReport(filter.condition).description} {filter.description ? filter.description: formatField(this.state.data.fields, filter.displayField, filter.valueFilter)}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                    <TableDynamic params={this.state.params} data={this.state.data} />
                </React.Fragment >
            );
        }

    }
};

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export default TableDynamicReport;
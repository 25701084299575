import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FiUploadCloud } from "react-icons/fi";
import SettingIcon from '@material-ui/icons/Settings';

import { getCreateBackup } from '../../../api/consumer/infra';

export class ListServer extends Component {

    state = {        
        userAccesses: [],      
        loading: true,
        creatingBackup: false
    }

    componentWillMount() {        
        this.loadingUserAccesses();
    }

    loadingUserAccesses = async () => {
        try {            
            //const response = await getUserAccesses();
            this.setState({
                //userAccesses: response.userAccesses,
                loading: false
            });            
        } catch (error) {
            this.setState({                
                loading: false
            }); 
            global.SnackBar.show('ERROR', error.message);
        }
    }

    createBackup = async () => {
        try {      
            this.setState({                
                creatingBackup: true
            });        
            const response = await getCreateBackup();
            this.setState({                
                creatingBackup: false
            });
            global.SnackBar.show('SUCCESS', response);            
        } catch (error) {
            this.setState({                
                creatingBackup: false
            }); 
            global.SnackBar.show('ERROR', error.message);
        }        
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListServer} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <SettingIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Administração
                        </Typography>
                        <IconButton onClick={this.loadingUserAccesses} style={{ marginLeft: '1rem' }}>
                            <RefreshIcon />
                        </IconButton>
                    </div>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button onClick={this.createBackup} color="primary" variant="contained" disabled={this.state.creatingBackup}>
                                <FiUploadCloud fontSize={18} style={{marginRight: '0.5rem'}}/> {this.state.creatingBackup ? 'Criando...' : 'Criar Backup'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>                                              
            </div>
        );
    }
};

const styles = {
    paperListServer: {
        padding: '1rem'
    }
}

ListServer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListServer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TimerIcon from '@material-ui/icons/Timer';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

import TableResponsive from '../../utils/TableResponsive';

import { getUserAccesses } from '../../../api/consumer/user';
import { formatDateTimeBRZ } from '../../utils/DataFormat';

export class ListUserAccesses extends Component {

    state = {        
        userAccesses: [],      
        loading: true
    }

    componentWillMount() {        
        this.loadingUserAccesses();
    }

    loadingUserAccesses = async () => {
        try {            
            const response = await getUserAccesses();
            this.setState({
                userAccesses: response.userAccesses,
                loading: false
            });            
        } catch (error) {
            this.setState({                
                loading: false
            }); 
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = () => {
        var data = [];
        this.state.userAccesses.forEach(userAccess => {            
            const loginAt = new Date(userAccess.loginAt * 1000);
            const accessedAt = new Date(userAccess.accessedAt);
            let dateCell = {
                idUser: userAccess.idUser,
                name: userAccess.name,
                loginAt: `${formatDateTimeBRZ(loginAt)} ${this.calculateDifference(loginAt)}`,
                accessedAt: `${formatDateTimeBRZ(accessedAt)} ${this.calculateDifference(accessedAt)}`
            }
            data.push(dateCell);
        });
        return data;
    }

    calculateDifference = (date) => {
        let milliseconds = new Date() - date
        let seconds = milliseconds / 1000;
        let minutesTotal = Math.trunc(seconds / 60);
        let minutes = minutesTotal % 60;        
        let hours = Math.trunc(minutesTotal / 60);
        return `(${hours}hr e ${minutes}min)`;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListUserAccess} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <TimerIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Acesso Real-Time
                        </Typography>
                        <IconButton onClick={this.loadingUserAccesses} style={{ marginLeft: '1rem' }}>
                            <RefreshIcon />
                        </IconButton>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                        loading={this.state.loading}
                    />
                </Paper>                                              
            </div>
        );
    }
};

const columns = [
    {
        id: 'idUser',        
        label: 'ID'
    },
    {
        id: 'name',        
        label: 'NAME'
    },
    {
        id: 'loginAt',
        label: 'DATA LOGIN'
    },
    {
        id: 'accessedAt',        
        label: 'DATA ÚLTIMO ACESSO'
    }
]

const styles = {
    paperListUserAccess: {
        padding: '1rem'
    }
}

ListUserAccesses.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListUserAccesses);

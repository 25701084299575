import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FileIcon from '@material-ui/icons/AttachFileRounded';
import ProcessIcon from '@material-ui/icons/AssignmentRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/SearchRounded';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import Input from '@material-ui/core/Input';
//import MaskedInput from 'react-text-mask';
import CircularProgress from '@material-ui/core/CircularProgress';
import PreviousIcon from '@material-ui/icons/NavigateBeforeRounded';
import NextIcon from '@material-ui/icons/NavigateNextRounded';
import SkipPreviousIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextIcon from '@material-ui/icons/SkipNextRounded';
import Badge from '@material-ui/core/Badge';

import RegisterProcess from './RegisterProcess';
import FileProcess from './FileProcess';
import RegisterFeesReceived from './RegisterFeesReceived';
import Message from '../../utils/Message';
import DateTextField from '../../utils/DateTextField';
import Profile from '../../utils/Profile';

import { getLaborCourt } from '../../../api/consumer/laborCourt';
import { getProcess, deleteProcess } from '../../../api/consumer/process';
import { formatDateBRZ, formatDateTimeBRZ } from '../../utils/DataFormat';
import { formatMoneySymbol } from '../../utils/NumberFormat';
import { typeStatusProcess, getDsTypeStatusProcess } from '../../../resources/types/typeStatusProcess';

export class ListProcess extends Component {
    state = {
        open: false,
        openFiles: false,
        openFeesReceived: false,
        //number: '_______-__.____._.__.____',
        number: '',
        idLaborCourt: '',
        initialDate: this.getInitialDate(),
        finalDate: new Date(),
        claimant: '',
        claimed: '',
        status: '',
        processes: [],
        laborCourts: [],
        loadingLaborCourts: true,
        process: null,
        messageDelete: null,
        loading: false,
        primaryItem: 0,
        itemsPage: 10,
        actualPage: 1,
        totalPage: 1
    };

    clickLaborCourts = false;

    getInitialDate() {
        let date = new Date();
        date.setDate(date.getDate() - 30);
        return date;
    }

    componentWillMount() {
        this.loadingProcesses();
    }

    loadingProcesses = async () => {
        try {
            if (this.state.initialDate === '') {
                global.SnackBar.show('WARNING', 'Informe a data inicial');
                return;
            }
            if (this.state.finalDate === '') {
                global.SnackBar.show('WARNING', 'Informe a data final');
                return;
            }
            if (new Date(this.state.initialDate).getTime() > new Date(this.state.finalDate).getTime()) {
                global.SnackBar.show('WARNING', 'Data inicial maior que data final');
                return;
            }

            let initialDate = new Date(this.state.initialDate);
            initialDate.setHours(0);
            initialDate.setMinutes(0);
            initialDate.setSeconds(0);
            initialDate.setMilliseconds(0);

            let finalDate = new Date(this.state.finalDate);
            finalDate.setHours(23);
            finalDate.setMinutes(59);
            finalDate.setSeconds(59);
            finalDate.setMilliseconds(999);

            let filter = {
                initialDate,
                finalDate
            };
            if (this.state.idLaborCourt !== '') {
                filter.idLaborCourt = this.state.idLaborCourt;
            }
            if (this.state.status !== '') {
                filter.status = this.state.status;
            }
            if (this.state.number !== '') {
                filter.number = this.state.number.toString().trim();
                filter.initialDate = undefined;
                filter.finalDate = undefined;
            }
            if (this.state.claimant !== '') {
                filter.claimant = this.state.claimant.toString().trim();
                filter.initialDate = undefined;
                filter.finalDate = undefined;
            }
            if (this.state.claimed !== '') {
                filter.claimed = this.state.claimed.toString().trim();
                filter.initialDate = undefined;
                filter.finalDate = undefined;
            }
            this.setState({ loading: true });
            const response = await getProcess(filter);

            let page = {};
            if (response.processes.length > 0) {
                const actualPage = 1;
                const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
                page = {
                    actualPage,
                    totalPage: Math.ceil(response.processes.length / this.state.itemsPage),
                    primaryItem
                };
            } else {
                page = {
                    actualPage: 0,
                    totalPage: 0,
                    primaryItem: 0
                };
            }

            this.setState({
                processes: response.processes,
                loading: false,
                ...page
            });
        } catch (error) {
            this.setState({
                processes: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    columns = () => {
        const isAdmin = Profile.isAdmin(this.props.user.profile);

        var columns = [];
        columns.push({ id: 'createdAt', label: 'DATA CRIAÇÃO' });
        columns.push({ id: 'number', label: 'NÚMERO' });
        columns.push({ id: 'nameLaborCourt', label: 'VARA TRABALHISTA' });
        columns.push({ id: 'claimant', label: 'RECLAMANTE' });
        columns.push({ id: 'claimed', label: 'RECLAMADO' });
        columns.push({ id: 'status', label: 'SITUAÇÃO' });
        if (isAdmin) {
            columns.push({ id: 'receiptDate', label: 'DATA RECEBIMENTO' });
        }
        columns.push({ id: 'action', label: '' });

        return columns;
    };

    data = (classes, isAdmin) => {
        var data = [];
        this.state.processes.forEach(process => {
            let dateCell = {
                createdAt: formatDateTimeBRZ(new Date(process.createdAt)),
                number: process.number,
                nameLaborCourt: `${process.LaborCourt.code}-${process.LaborCourt.name}`,
                claimant: process.claimant,
                claimed: process.claimed,
                status: getDsTypeStatusProcess(process.status).dsType,
                receiptDate: formatDateBRZ(process.receiptDate ? new Date(process.receiptDate) : null),
                action: this.actions(process, isAdmin)
            };
            data.push(dateCell);
        });
        return data;
    };

    actions = (process, isAdmin) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditProcess(process)}>
                <EditIcon />
            </IconButton>
            {isAdmin && (
                <IconButton onClick={() => this.showMessageDelete(process)}>
                    <DeleteIcon />
                </IconButton>
            )}
        </div>
    );

    openOrClose = (process = null, saved = false) => {
        let open = !this.state.open;
        this.setState({
            process,
            open
        });
        if (!open && saved) {
            this.loadingProcesses();
        }
    };

    openOrCloseFiles = (process = null) => {
        this.setState({
            process,
            openFiles: !this.state.openFiles
        });
    };

    openOrCloseFeesReceived = (process = null, saved = false) => {
        let open = !this.state.openFeesReceived;
        this.setState({
            process,
            openFeesReceived: open
        });
        if (!open && saved) {
            this.loadingProcesses();
        }
    };

    showMessageDelete = (process = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Processo',
                message: (
                    <span>
                        Deseja realmente excluir o processo <b>“{process.number}” ?</b>
                    </span>
                ),
                ok: () => this.onDeleteProcess(process),
                cancel: true
            }
        });
    };

    onEditProcess = process => {
        let clone = JSON.parse(JSON.stringify(process));
        this.openOrClose(clone, false);
    };

    onDeleteProcess = async process => {
        try {
            await deleteProcess(process.idProcess);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingProcesses();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    };

    loadingLaborCourts = async () => {
        try {
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts,
                loadingLaborCourts: false
            });
        } catch (error) {
            this.setState({
                laborCourts: [],
                loadingLaborCourts: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    onClickLaborCourt = () => {
        if (this.state.loadingLaborCourts && !this.clickLaborCourts) {
            this.clickLaborCourts = true;
            this.loadingLaborCourts();
        }
    };

    pressEnter = event => {
        if (event.keyCode === 13) {
            this.loadingProcesses();
        }
    };

    //[INICIO] - Logica para paginação

    generateActualPage = () => {
        let process = this.state.processes;
        return process.slice(parseInt(this.state.primaryItem), parseInt(this.state.primaryItem) + parseInt(this.state.itemsPage));
    };

    skipPreviousPage = () => {
        if (this.state.actualPage > 1) {
            const actualPage = 1;
            const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
            this.setState({
                actualPage,
                primaryItem
            });
        }
    };

    previousPage = () => {
        if (this.state.actualPage > 1) {
            const actualPage = this.state.actualPage - 1;
            const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
            this.setState({
                actualPage,
                primaryItem
            });
        }
    };

    nextPage = () => {
        if (this.state.actualPage < this.state.totalPage) {
            const actualPage = this.state.actualPage + 1;
            const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
            this.setState({
                actualPage,
                primaryItem
            });
        }
    };

    skipNextPage = () => {
        if (this.state.actualPage < this.state.totalPage) {
            const actualPage = this.state.totalPage;
            const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
            this.setState({
                actualPage,
                primaryItem
            });
        }
    };

    changeTotalPage = e => {
        const itemsPage = parseInt(e.target.value);
        const actualPage = 1;
        const primaryItem = actualPage * this.state.itemsPage - this.state.itemsPage;
        const totalPage = Math.ceil(this.state.processes.length / itemsPage);
        this.setState({
            actualPage,
            itemsPage,
            primaryItem,
            totalPage
        });
    };

    //[FIM] - Logica para paginação

    render() {
        const { classes, user } = this.props;
        const isAdmin = Profile.isAdmin(user.profile);

        const spaceGrid1 = {
            xs: isAdmin ? 12 : 12,
            sm: isAdmin ? 12 : 12,
            md: isAdmin ? 6 : 6,
            lg: isAdmin ? 3 : 4,
            xl: isAdmin ? 3 : 4
        };

        const spaceGrid2 = {
            xs: isAdmin ? 12 : 12,
            sm: isAdmin ? 12 : 12,
            md: isAdmin ? 6 : 6,
            lg: isAdmin ? 2 : 2,
            xl: isAdmin ? 2 : 2
        };

        const spaceGrid3 = {
            xs: isAdmin ? 12 : 12,
            sm: isAdmin ? 12 : 12,
            md: isAdmin ? 3 : 3,
            lg: isAdmin ? 2 : 2,
            xl: isAdmin ? 2 : 2
        };

        const spaceGrid4 = {
            xs: isAdmin ? 12 : 12,
            sm: isAdmin ? 12 : 12,
            md: isAdmin ? 9 : 12,
            lg: isAdmin ? 5 : 6,
            xl: isAdmin ? 5 : 6
        };

        return (
            <div>
                <Paper className={classes.paperListProcess} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <ProcessIcon style={{ marginRight: '0.6rem' }} />
                        <Typography variant="h5" color="inherit">
                            Processos
                        </Typography>
                        {isAdmin && (
                            <Fab
                                size="small"
                                color="secondary"
                                aria-label="Add"
                                style={{ marginLeft: '1rem' }}
                                onClick={() => this.openOrClose(null, false)}
                            >
                                <AddIcon />
                            </Fab>
                        )}
                    </div>
                    <Grid container direction="row" spacing={16} style={{ marginTop: '0.3rem', marginBottom: '0.5rem' }}>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <InputLabel htmlFor="input-number-process">Número</InputLabel>
                                <Input
                                    value={this.state.number}
                                    onChange={event => {
                                        this.setState({ number: event.target.value });
                                    }}
                                    id="input-number-process"
                                    //inputComponent={TextMaskNumberProcess}
                                    autoFocus
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <InputLabel htmlFor="select-labor-court">Vara Trabalhista</InputLabel>
                                <Select
                                    value={this.state.idLaborCourt}
                                    onChange={event => {
                                        this.setState({ idLaborCourt: event.target.value });
                                    }}
                                    inputProps={{ id: 'select-labor-court' }}
                                    onClick={this.onClickLaborCourt}
                                >
                                    <MenuItem value={''} />
                                    {this.state.loadingLaborCourts ? (
                                        <MenuItem value={''}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    style={{
                                                        textAlign: 'center',
                                                        fontFamily: 'Arial',
                                                        fontSize: '1.05rem',
                                                        color: 'rgba(0, 0, 0, 0.65)',
                                                        marginRight: '0.6rem'
                                                    }}
                                                >
                                                    Buscando...
                                                </Typography>
                                                <CircularProgress size={20} color="secondary" />
                                            </div>
                                        </MenuItem>
                                    ) : (
                                        this.state.laborCourts.map((laborCourt, index) => (
                                            <MenuItem value={laborCourt.idLaborCourt} key={index}>
                                                {laborCourt.code} - {laborCourt.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <InputLabel htmlFor="input-claimant">Reclamante</InputLabel>
                                <Input
                                    value={this.state.claimant}
                                    onChange={event => {
                                        this.setState({ claimant: event.target.value });
                                    }}
                                    id="input-claimant"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <InputLabel htmlFor="input-claimed">Reclamado</InputLabel>
                                <Input
                                    value={this.state.claimed}
                                    onChange={event => {
                                        this.setState({ claimed: event.target.value });
                                    }}
                                    id="input-claimed"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <DateTextField
                                    fullWidth
                                    label="Dt de Início"
                                    value={this.state.initialDate}
                                    onChange={date => {
                                        this.setState({ initialDate: date });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <DateTextField
                                    fullWidth
                                    label="Dt de Fim"
                                    value={this.state.finalDate}
                                    onChange={date => {
                                        this.setState({ finalDate: date });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                            <FormControl fullWidth onKeyDown={this.pressEnter}>
                                <InputLabel htmlFor="select-status">Situação</InputLabel>
                                <Select
                                    value={this.state.status}
                                    onChange={event => {
                                        this.setState({ status: event.target.value });
                                    }}
                                    inputProps={{ id: 'select-status' }}
                                >
                                    <MenuItem value={''} />
                                    {typeStatusProcess.map((status, index) => (
                                        <MenuItem value={status.idType} key={index}>
                                            {status.dsType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <IconButton onClick={this.loadingProcesses}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {!this.state.loading && this.state.processes.length === 0 ? (
                        <Typography
                            variant="h6"
                            color="inherit"
                            style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)' }}
                        >
                            Nenhum conteúdo
                        </Typography>
                    ) : this.state.loading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography
                                variant="h6"
                                color="inherit"
                                style={{
                                    textAlign: 'center',
                                    fontFamily: 'Arial',
                                    fontSize: '1.05rem',
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    marginRight: '0.6rem'
                                }}
                            >
                                Buscando...
                            </Typography>
                            <CircularProgress size={20} color="secondary" />
                        </div>
                    ) : (
                        <div style={{ marginTop: '0.3rem' }}>
                            {this.generateActualPage().map((process, index) => (
                                <Paper
                                    key={index}
                                    elevation={5}
                                    style={{ marginBottom: '0.5rem', padding: '0.5rem' }}
                                    className={classes.paperProcess}
                                    onClick={() => this.onEditProcess(process)}
                                >
                                    <Grid container direction="row">
                                        <Grid item {...spaceGrid1}>
                                            <Grid container direction="column" spacing={8}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b># </b> {this.state.primaryItem + index + 1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Número: </b> {process.number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Vara Trabalhista: </b> {process.LaborCourt.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Reclamante: </b> {process.claimant}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Reclamado: </b> {process.claimed}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Criado em: </b> {formatDateTimeBRZ(new Date(process.createdAt))}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item {...spaceGrid2}>
                                            <Grid container direction="column" spacing={8}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Situação: </b> {getDsTypeStatusProcess(process.status).dsType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography>
                                                        <b>Concluído em: </b> {formatDateBRZ(process.concluisionDate)}
                                                    </Typography>
                                                </Grid>
                                                {isAdmin && (
                                                    <>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Typography>
                                                                <b>Honorários Arbit.: </b> {formatMoneySymbol(process.arbitratedFees)}
                                                            </Typography>
                                                        </Grid>
                                                        {/*
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Typography>
                                                                    <b>Data de Receb. Hon.: </b> {formatDateBRZ(process.receiptDate)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Typography>
                                                                    <b>Honorários Receb.: </b> {formatMoneySymbol(process.receivedFees)}
                                                                </Typography>
                                                            </Grid>
                                                        */}
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {isAdmin && (
                                            <Grid item {...spaceGrid3} style={{ padding: '7px' }}>
                                                <Paper elevation={2} style={{ padding: '0.1rem' }}>
                                                    <Typography style={{ textAlign: 'center', fontSize: '0.9rem' }}>
                                                        <b>Honorários Recebidos</b>
                                                    </Typography>
                                                    <div className={classes.containerTable}>
                                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                            <thead style={{ marginTop: '10px', borderBottom: '1px solid #E1E1E1' }}>
                                                                <tr>
                                                                    <th>
                                                                        <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                            <b>#</b>
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                            <b>Data de Recebimento</b>
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                            <b>Valor</b>
                                                                        </Typography>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {process.FeesReceiveds.map((FeesReceived, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ padding: '5px 0px' }}>
                                                                            <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                                {index + 1}
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                                {formatDateBRZ(new Date(FeesReceived.receiptDate))}
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                                {formatMoneySymbol(FeesReceived.received)}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot style={{ marginTop: '10px', borderTop: '1px solid #E1E1E1' }}>
                                                                <tr key={index}>
                                                                    <td />
                                                                    <td style={{ padding: '5px 0px' }}>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            <b>Total</b>
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            <b>
                                                                                {formatMoneySymbol(
                                                                                    process.receivedFees === null ? 0 : process.receivedFees
                                                                                )}
                                                                            </b>
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        )}
                                        <Grid item {...spaceGrid4} style={{ padding: '7px' }}>
                                            <Paper elevation={2} style={{ padding: '0.1rem' }}>
                                                <Typography style={{ textAlign: 'center', fontSize: '0.9rem' }}>
                                                    <b>Movimentações</b>
                                                </Typography>
                                                <div className={classes.containerTable}>
                                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                        <thead style={{ marginTop: '10px', borderBottom: '1px solid #E1E1E1' }}>
                                                            <tr>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>#</b>
                                                                    </Typography>
                                                                </th>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>Data de Carga</b>
                                                                    </Typography>
                                                                </th>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>Finalidade</b>
                                                                    </Typography>
                                                                </th>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>Calculista</b>
                                                                    </Typography>
                                                                </th>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>Situação</b>
                                                                    </Typography>
                                                                </th>
                                                                <th>
                                                                    <Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.65)' }}>
                                                                        <b>Data de Conclusão</b>
                                                                    </Typography>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {process.ProcessMovements.map((ProcessMovement, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ padding: '5px 0px' }}>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {index + 1}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {formatDateBRZ(new Date(ProcessMovement.uploadDate))}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {ProcessMovement.Goal.description}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {ProcessMovement.Arithmetician ? ProcessMovement.Arithmetician.name : ''}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {getDsTypeStatusProcess(ProcessMovement.status).dsType}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography style={{ textAlign: 'center', paddingRight: '0.3rem' }}>
                                                                            {formatDateBRZ(ProcessMovement.concluisionDate)}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {isAdmin && (
                                                    <IconButton
                                                        className={classes.buttonEdit}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.openOrCloseFeesReceived(process);
                                                        }}
                                                    >
                                                        <MoneyIcon
                                                            className={classes.iconEdit}
                                                            style={process.countFeesReceived > 0 ? { color: '#00893B' } : {}}
                                                        />
                                                    </IconButton>
                                                )}
                                                <IconButton
                                                    className={classes.buttonEdit}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.openOrCloseFiles(process);
                                                    }}
                                                >
                                                    <Badge badgeContent={process.totalFiles} color="secondary" invisible={!process.totalFiles}>
                                                        <FileIcon className={classes.iconEdit} />
                                                    </Badge>
                                                </IconButton>
                                                <IconButton
                                                    className={classes.buttonEdit}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.onEditProcess(process);
                                                    }}
                                                >
                                                    <EditIcon className={classes.iconEdit} />
                                                </IconButton>
                                                {isAdmin && (
                                                    <IconButton
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.showMessageDelete(process);
                                                        }}
                                                    >
                                                        <DeleteIcon className={classes.iconEdit} />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                            <div
                                className={classes.rowTable}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}
                            >
                                <div style={{ flex: 2 }}>
                                    <FormControl className={classes.formControl} style={{ flex: 2 }} fullWidth>
                                        <Select
                                            native
                                            value={this.state.itemsPage}
                                            onChange={this.changeTotalPage}
                                            style={{ fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.65)' }}
                                        >
                                            <option value={10}>10 itens por página</option>
                                            <option value={30}>30 itens por página</option>
                                            <option value={50}>50 itens por página</option>
                                            <option value={100}>100 itens por página</option>
                                            <option value={200}>200 itens por página</option>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flex: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <IconButton
                                        onClick={this.skipPreviousPage}
                                        className={classes.buttonPag}
                                        disabled={this.state.totalPage === 1 || this.state.actualPage === 1}
                                    >
                                        <SkipPreviousIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={this.previousPage}
                                        className={classes.buttonPag}
                                        disabled={this.state.totalPage === 1 || this.state.actualPage === 1}
                                    >
                                        <PreviousIcon />
                                    </IconButton>
                                    <Typography
                                        className={classes.buttonPag}
                                        variant="h6"
                                        color="inherit"
                                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '0.9rem' }}
                                    >
                                        {this.state.actualPage} / {this.state.totalPage}
                                    </Typography>
                                    <IconButton
                                        onClick={this.nextPage}
                                        className={classes.buttonPag}
                                        disabled={this.state.totalPage === 1 || this.state.actualPage === this.state.totalPage}
                                    >
                                        <NextIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={this.skipNextPage}
                                        className={classes.buttonPag}
                                        disabled={this.state.totalPage === 1 || this.state.actualPage === this.state.totalPage}
                                    >
                                        <SkipNextIcon />
                                    </IconButton>
                                </div>
                                <div style={{ flex: 2 }}>
                                    <Typography
                                        className={classes.buttonPag}
                                        variant="h6"
                                        color="inherit"
                                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.65)' }}
                                    >
                                        {this.state.processes.length} resultados
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </Paper>
                <RegisterProcess open={this.state.open} openOrClose={this.openOrClose} process={this.state.process} user={this.props.user} />
                <FileProcess open={this.state.openFiles} openOrClose={this.openOrCloseFiles} process={this.state.process} user={this.props.user} />
                {isAdmin && (
                    <RegisterFeesReceived
                        open={this.state.openFeesReceived}
                        openOrClose={this.openOrCloseFeesReceived}
                        process={this.state.process}
                    />
                )}
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
}

/*function TextMaskNumberProcess(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}*/

const styles = theme => ({
    paperListProcess: {
        padding: '1rem'
    },
    divPageButtonEnd: {
        flex: 2,
        [theme.breakpoints.down('xs')]: {
            flex: 0
        }
    },
    buttonPag: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 175
    },
    iconEdit: {
        fontSize: 32
    },
    buttonEdit: {
        marginRight: 36
    },
    paperProcess: {
        '&:hover': {
            backgroundColor: '#FCFCFC',
            boxShadow: '0px 3px 20px -1px rgba(0,0,0,0.28), 0px 5px 20px 0px rgba(0,0,0,0.14), 0px 1px 20px 0px rgba(0,0,0,0.12)',
            cursor: 'pointer'
        }
    },
    containerTable: {
        overflowX: 'auto'
    }
});

ListProcess.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListProcess);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FiX, FiFilter } from "react-icons/fi";

import DynamicTextField from './DynamicTextField';

import { getTypeConditionDynamicReport } from '../../../../resources/types/typeConditionDynamicReport';

export class DefineFilters extends Component {

    constructor(props) {
        super(props);
        let fullColumns = clone(props.parameters.fields);
        this.state = {
            fullColumns,
            nameColumn: ''
        }
    }

    addColumn = () => {
        if (this.state.nameColumn === '') {
            global.SnackBar.show('WARNING', 'Selecione uma coluna');
            return;
        }
        let definedFilters = clone(this.props.definedFilters);
        let newFilter = this.state.fullColumns.find((column) => column.displayField === this.state.nameColumn);
        if (newFilter) {
            newFilter.condition = '';
            newFilter.valueFilter = '';
            definedFilters.push(newFilter)
            this.setState({
                nameColumn: ''
            });
            this.props.setDefinedFilters(definedFilters);
        }
    }

    removeColumn = (index) => {
        let definedFilters = clone(this.props.definedFilters);
        definedFilters.splice(index, 1);
        this.props.setDefinedFilters(definedFilters);
    }

    changeValue = (value, index, attribute) => {
        if (value === null) {
            value = '';
        }
        let definedFilters = clone(this.props.definedFilters);
        if (attribute === 'condition' && (value === 'is null' || value === 'is not null')) {
            definedFilters[index].valueFilter = '';
        }
        if (attribute === 'valueFilter') {
            if (definedFilters[index].dataType === 'DROPDOWN') {
                let description = definedFilters[index].values.find(field => (field.id).toString() === (value).toString()).description;
                definedFilters[index].description = description;
            }
        }
        definedFilters[index][attribute] = value;
        this.props.setDefinedFilters(definedFilters);
    }

    render() {

        return (
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FiFilter fontSize="22" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: '1.2rem' }}>Filtros</Typography>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="select-field-filters-columns">Coluna</InputLabel>
                                        <Select
                                            value={this.state.nameColumn}
                                            onChange={(e) => this.setState({ nameColumn: e.target.value })}
                                            inputProps={{ id: "select-field-filters-columns" }}
                                        >
                                            {this.state.fullColumns.map((column, index) => (
                                                <MenuItem value={column.displayField} key={index}>{column.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </ FormControl>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={this.addColumn} color="secondary" variant="contained">
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.props.definedFilters.map((column, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography>{column.label}: </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="select-field-filters-columns-condition">Condição</InputLabel>
                                                <Select
                                                    value={column.condition}
                                                    onChange={(e) => this.changeValue(e.target.value, index, 'condition')}
                                                    inputProps={{ id: "select-field-filters-columns-condition" }}
                                                >
                                                    {getConditionType(column.dataType).map((condition, index) => (
                                                        <MenuItem value={condition.id} key={index}>{condition.description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </ FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <DynamicTextField
                                                values={column.values}
                                                value={column.valueFilter}
                                                onChange={(value) => this.changeValue(value, index, 'valueFilter')}
                                                dataType={column.dataType}
                                                disabled={column.condition === 'is null' || column.condition === 'is not null'}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <IconButton onClick={() => this.removeColumn(index)}>
                                                <FiX />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

};

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const getConditionType = (dataType) => {
    switch (dataType) {
        case 'INTEGER':
            return conditions1;
        case 'FLOAT':
            return conditions1;
        case 'MONEY':
            return conditions1;
        case 'DATE':
            return conditions1;
        case 'DATETIME':
            return conditions1;
        case 'TEXT':
            return conditions3;
        default:
            return conditions2;
    }
}

const conditions1 = [
    getTypeConditionDynamicReport('='),
    getTypeConditionDynamicReport('>'),
    getTypeConditionDynamicReport('>='),
    getTypeConditionDynamicReport('<'),
    getTypeConditionDynamicReport('<='),
    getTypeConditionDynamicReport('!='),
    getTypeConditionDynamicReport('is null'),
    getTypeConditionDynamicReport('is not null')]

const conditions2 = [
    getTypeConditionDynamicReport('='),
    getTypeConditionDynamicReport('!='),
    getTypeConditionDynamicReport('is null'),
    getTypeConditionDynamicReport('is not null')]

const conditions3 = [
    getTypeConditionDynamicReport('='),
    getTypeConditionDynamicReport('like'),
    getTypeConditionDynamicReport('!='),
    getTypeConditionDynamicReport('is null'),
    getTypeConditionDynamicReport('is not null')]

const styles = {}

DefineFilters.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DefineFilters);

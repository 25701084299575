
/* eslint-disable */
export default {
    isSuperAdmin(profile) {
        isValid(profile);
        return (profile === 'A');
    },    
    isAdmin(profile) {
        isValid(profile);
        return (profile === 'A' || profile === 'AP' || profile === 'APC');
    },    
    isExpert(profile) {
        isValid(profile);
        return (profile === 'A' || profile === 'AP' || profile === 'APC' || profile === 'P'  || profile === 'PC');
    },   
    isArithmetician(profile){
        isValid(profile);
        return (profile === 'A' || profile === 'APC' || profile === 'PC' || profile === 'C');
    }
}

function isValid(profile) {
    if (!profile) {
        throw 'Perfil é inválido';
    }     
}

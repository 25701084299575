import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitIcon from '@material-ui/icons/ExitToApp';
import UserIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/InsertChart';
import AdminIcon from '@material-ui/icons/Security';
import LaborCourtIcon from '@material-ui/icons/GavelRounded';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Mail';
import GoalIcon from '@material-ui/icons/RedoRounded';
import FileCaseIcon from '@material-ui/icons/NotesRounded';
import ProcessIcon from '@material-ui/icons/AssignmentRounded';
import Message from '../utils/Message';
import Profile from '../utils/Profile';
import Redirector from '../../Redirector';
import Token from '../../api/Token';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CreateIcon from '@material-ui/icons/Create';
import Store from '@material-ui/icons/StoreRounded';
import { FaMoneyBillAlt, FaRegPaperPlane } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import BadgeAllPasso from '../utils/BadgeAllPasso';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
        fontFamily: 'Montserrat',
        fontSize: '1.20rem'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        //...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        minHeight: '48px'
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    buttonAdd: {
        margin: theme.spacing.unit,
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2
    },
    toolbar: {
        paddingRight: theme.spacing.unit * 3
    },
    divTabs: {
        flexGrow: 1
    },
    buttonMessageNofig: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    links: {
        textDecoration: 'none',
        color: 'inherit',
        outline: 'none'
    }
});

class BodyMenu extends React.Component {
    state = {
        message: null,
        messageCount: 0,
        anchorEl: null,
        anchorMenuGoal: null,
        anchorMenuCompany: null
    };

    componentWillMount() {
        if (!global.notifHomolog && process.env.REACT_APP_ENV === 'homolog') {
            this.setState({
                message: {
                    onClose: () => this.setState({ message: null }),
                    title: 'Aviso!',
                    message: (
                        <b>
                            Você está utilizando o ambiente de homologação, utilizado apenas para teste de sistema, todos os dados são fictícios,
                            podendo ser removidos a qualquer momento.
                        </b>
                    )
                }
            });
            global.notifHomolog = true;
        }
    }

    handleDrawerOpen = () => {
        this.setState({ inOpenDrawer: true });
    };

    handleDrawerClose = () => {
        this.setState({ inOpenDrawer: false });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    logoff = () => {
        Token.removeToken();
        Redirector.login(this, '401');
    };

    toAdmin = () => {
        Redirector.admin(this);
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, theme } = this.props;
        const { inOpenDrawer, anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: inOpenDrawer
                        })}
                    >
                        <Toolbar disableGutters={!inOpenDrawer} className={classes.toolbar} variant="dense">
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, inOpenDrawer && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                Perito Contábil
                            </Typography>
                            <div>
                                <Link to={`${this.props.match.path}/messages`} className={classes.links}>
                                    <IconButton color="inherit">
                                        <Badge badgeContent={this.state.messageCount} color="error" invisible={!this.state.messageCount}>
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                </Link>
                                <IconButton
                                    aria-owns={open ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <span className={classes.buttonMessageNofig} style={{ fontSize: '0.9rem', marginRight: '0.2rem' }}>
                                        {this.props.user.name}
                                    </span>
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem>{this.props.user.name}</MenuItem>
                                    <MenuItem onClick={this.logoff}>
                                        <ExitIcon style={{ marginRight: '8px' }} /> Sair
                                    </MenuItem>
                                </Menu>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={inOpenDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <Typography variant="h6" color="inherit" style={{ fontSize: '0.7rem', textAlign: 'center', flex: 1 }}>
                                {this.props.user.Customer.name}
                            </Typography>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <Link to={`${this.props.match.path}/dashboard`} className={classes.links}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <DashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard" />
                                </ListItem>
                            </Link>
                            <Link to={`${this.props.match.path}/process`} className={classes.links}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <ProcessIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Processos" />
                                </ListItem>
                            </Link>
                            {Profile.isAdmin(this.props.user.profile) ? (
                                <span>
                                    <Link to={`${this.props.match.path}/casefile`} className={classes.links}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <FileCaseIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Autos" />
                                        </ListItem>
                                    </Link>
                                    <Link to={`${this.props.match.path}/laborcourt`} className={classes.links}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <LaborCourtIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Varas Trabalhistas" />
                                        </ListItem>
                                    </Link>
                                    <ListItem
                                        button
                                        aria-owns={Boolean(this.state.anchorMenuGoal) ? 'menu-goal' : null}
                                        aria-haspopup="true"
                                        onClick={event => {
                                            this.setState({ anchorMenuGoal: event.currentTarget });
                                        }}
                                    >
                                        <ListItemIcon>
                                            <GoalIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Finalidades" />
                                        <ListItemIcon>
                                            <ArrowRightIcon />
                                        </ListItemIcon>
                                    </ListItem>
                                    <MenuGoal
                                        anchorEl={this.state.anchorMenuGoal}
                                        onClose={() => {
                                            this.setState({ anchorMenuGoal: null });
                                        }}
                                        classes={classes}
                                        path={this.props.match.path}
                                    />
                                    <Divider />
                                    <Link to={`${this.props.match.path}/payment-process`} className={classes.links}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <FaMoneyBillAlt fontSize="22" />
                                            </ListItemIcon>
                                            <ListItemText primary="Pagamentos" />
                                        </ListItem>
                                    </Link>
                                </span>
                            ) : (
                                <Divider />
                            )}
                            <Link to={`${this.props.match.path}/reports`} className={classes.links}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <FiFileText fontSize="22" />
                                    </ListItemIcon>
                                    <ListItemText primary="Relatórios" />
                                </ListItem>
                            </Link>
                            {Profile.isAdmin(this.props.user.profile) && (
                                <Link to={`${this.props.match.path}/dynamic-report`} className={classes.links}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <FaRegPaperPlane fontSize="22" />
                                        </ListItemIcon>
                                        <ListItemText primary="Relatórios Dinâmicos" />
                                    </ListItem>
                                </Link>
                            )}
                        </List>
                        <Divider />
                        <List>
                            <Link to={`${this.props.match.path}/messages`} className={classes.links}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Badge badgeContent={this.state.messageCount} color="error" invisible={!this.state.messageCount}>
                                            <NotificationsIcon />
                                        </Badge>
                                    </ListItemIcon>
                                    <ListItemText primary="Mensagens" />
                                </ListItem>
                            </Link>
                            {Profile.isAdmin(this.props.user.profile) && (
                                <Link to={`${this.props.match.path}/user`} className={classes.links}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <UserIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Usuários" />
                                    </ListItem>
                                </Link>
                            )}
                            {Profile.isAdmin(this.props.user.profile) && (
                                <Fragment>
                                    <ListItem
                                        button
                                        aria-owns={Boolean(this.state.anchorMenuCompany) ? 'menu-company' : null}
                                        aria-haspopup="true"
                                        onClick={event => {
                                            this.setState({ anchorMenuCompany: event.currentTarget });
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Store />
                                        </ListItemIcon>
                                        <ListItemText primary="Empresa" />
                                        <ListItemIcon>
                                            <ArrowRightIcon />
                                        </ListItemIcon>
                                    </ListItem>
                                    <MenuCompany
                                        anchorEl={this.state.anchorMenuCompany}
                                        onClose={() => {
                                            this.setState({ anchorMenuCompany: null });
                                        }}
                                        classes={classes}
                                        path={this.props.match.path}
                                    />
                                </Fragment>
                            )}
                            {Profile.isSuperAdmin(this.props.user.profile) && (
                                <ListItem button onClick={this.toAdmin}>
                                    <ListItemIcon>
                                        <AdminIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Admin" />
                                </ListItem>
                            )}
                            <Divider />
                        </List>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%', paddingBottom: '10px' }}>
                            <BadgeAllPasso />
                        </div>
                    </Drawer>
                    <main
                        className={classNames(classes.content, {
                            [classes.contentShift]: inOpenDrawer
                        })}
                    >
                        <div className={classes.drawerHeader} />
                    </main>
                </div>
                <Message options={this.state.message} />
            </div>
        );
    }
}

const MenuGoal = props => {
    const open = Boolean(props.anchorEl);
    return (
        <Menu
            id="menu-goal"
            anchorEl={props.anchorEl}
            open={open}
            onClose={props.onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
        >
            <Link to={`${props.path}/goal`} className={props.classes.links}>
                <MenuItem onClick={props.onClose}>
                    <ListItemIcon>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cadastro" />
                </MenuItem>
            </Link>
            <Link to={`${props.path}/goal-value-pay`} className={props.classes.links}>
                <MenuItem onClick={props.onClose}>
                    <ListItemIcon>
                        <MoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Valores" />
                </MenuItem>
            </Link>
        </Menu>
    );
};

const MenuCompany = props => {
    const open = Boolean(props.anchorEl);
    return (
        <Menu
            id="menu-company"
            anchorEl={props.anchorEl}
            open={open}
            onClose={props.onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
        >
            <Link to={`${props.path}/company-register`} className={props.classes.links}>
                <MenuItem onClick={props.onClose}>
                    <ListItemIcon>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cadastro" />
                </MenuItem>
            </Link>
            <Link to={`${props.path}/company-cost-invoice`} className={props.classes.links}>
                <MenuItem onClick={props.onClose}>
                    <ListItemIcon>
                        <MoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Custos e Fatura" />
                </MenuItem>
            </Link>
        </Menu>
    );
};

BodyMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BodyMenu);

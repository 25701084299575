import api from '../api';

export async function getGoal() {
    try {
        const response = await api.get('/goal');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveGoal(goal) {
    try {
        let response;
        if (goal.idGoal === null) {
            response = await api.post('/goal', goal);
        } else {
            response = await api.put(`/goal/${goal.idGoal}`, goal);
        }
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function deleteGoal(idGoal) {
    try {
        const response = await api.delete(`/goal/${idGoal}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
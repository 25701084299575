import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import person from '../../../api/consumer/person';

import R001 from './Reports/R001/R001';
import R002 from './Reports/R002/R002';
import R003 from './Reports/R003/R003';
import R004 from './Reports/R004/R004';
export class Report extends Component {
    state = {
        loading: true,
        error: null,
        person: {}
    };

    componentWillMount() {
        this.loadingPerson();
    }

    loadingPerson = async () => {
        try {
            const idPerson = this.props.user.Customer.idPerson;
            if (idPerson) {
                const param = { idPerson, imageLogo: true };
                const response = await person('GET', param);
                this.setState({
                    person: response.person,
                    loading: false
                });
            } else {
                this.setState({
                    error: 'Empresa sem cadastro de pessoa',
                    person: null,
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

    render() {
        const reportId = this.props.match.params.reportId;
        let params = window.sessionStorage.getItem(`@queryReport${reportId}`);
        if (params) {
            params = JSON.parse(params);
        } else {
            params = {};
        }

        if (this.state.error != null) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Erro ao buscar dados da empresa: {JSON.stringify(this.state.error)}
                    </Typography>
                </Paper>
            );
        } else if (this.state.loading) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Buscando dados pessoa...
                    </Typography>
                    <CircularProgress size={20} color="secondary" />
                </Paper>
            );
        } else {
            return (
                <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
                    <ReportSwitch reportId={reportId} params={params} user={this.props.user} person={this.state.person} />
                </div>
            );
        }
    }
}

const ReportSwitch = props => {
    switch (props.reportId) {
        case 'R001':
            return <R001 {...props} />;
        case 'R002':
            return <R002 {...props} />;
        case 'R003':
            return <R003 {...props} />;
        case 'R004':
            return <R004 {...props} />;
        default:
            return <h3 style={{ margin: '1rem' }}>Relatório {props.reportId} não encontrado</h3>;
    }
};

export default Report;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { FiChevronRight, FiChevronDown, FiMaximize, FiMinimize } from "react-icons/fi";

import { agroup, formatValues, generateTotals, formatNumeric } from './StructureDynamicReport';

export class TableDynamic extends Component {

    constructor(props) {
        super(props);

        const { data } = props;

        const groups = data.groups;
        const fields = data.fields;
        let values = clone(data.values);
        let totals = generateTotals(fields, values)
        values = formatValues(fields, values);
        let grouped;
        if (groups.length > 0) {
            grouped = agroup(0, groups, fields, values);
        } else {
            grouped = formatNumeric(fields, values);
        }

        this.state = {
            grouped,
            totals,
            expanded: false
        }
    }

    onReload = () => {
        this.forceUpdate();
    }

    expantedGroup = () => {
        let expandOrCollapse = (group, expanded) => {
            for (let i = 0; i < group.length; i++) {
                if (group[i].isGroup) {
                    group[i].expanded = expanded;
                    expandOrCollapse(group[i].values, expanded);
                }
            }
        }

        let expanded = !this.state.expanded;

        expandOrCollapse(this.state.grouped, expanded);

        this.setState({
            expanded
        });
    }

    render() {

        const { classes, data } = this.props;
        const groups = data.groups;
        const headers = data.headers;
        let headersVisible = [];

        for (let header of headers) {
            let isGroup = Boolean(groups.find(column => column.displayField === header.displayField));
            if (!isGroup) {
                headersVisible.push(header.displayField);
            }
        }

        return (
            <Fragment>
                {groups.length > 0 &&
                    <div style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                        <IconButton onClick={this.expantedGroup}>
                            {this.state.expanded ?
                                <Fragment><FiMinimize /><Typography style={{ marginLeft: '0.5rem', fontSize: '0.9rem' }}> Retrair todos</Typography></Fragment>
                                :
                                <Fragment><FiMaximize /><Typography style={{ marginLeft: '0.5rem', fontSize: '0.9rem' }}> Expandir todos</Typography></Fragment>
                            }
                        </IconButton>
                    </div>
                }
                <Paper className={classes.root}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <Header
                                    headers={headers}
                                    groups={groups}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Rows
                                index={0}
                                headers={headersVisible}
                                grouped={this.state.grouped}
                                onReload={this.onReload}
                            />
                            {Object.keys(this.state.totals).length > 0 &&
                                <TableRow>
                                    <CellsTotals headers={headersVisible} values={this.state.totals} />
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell align="center" colSpan={headersVisible.length} style={{ padding: '0.3rem', width: '250px' }}>
                                    <b>RESULTADOS: {this.props.data.values.length}</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Fragment>
        );
    }
}

let keyRow = 0;

const Rows = ({ grouped, index, headers, onReload }) => {
    return grouped.map((group) => {
        const nextIndex = index + 1;
        keyRow++;
        if (group.isGroup) {
            return (
                <Fragment key={keyRow}>
                    <TableRow style={{ padding: '0.0rem', margin: '0.0rem', background: 'linear-gradient(to bottom, #FDFDFD, #FAF9F9)', color: '#FFF' }}>
                        <TableCell align="left" colSpan={headers.length}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 5, marginLeft: ((index * 3) + 'rem') }}>
                                    <IconButton onClick={() => { group.expanded = !group.expanded; onReload(); }}>
                                        {group.expanded ? <FiChevronDown /> : <FiChevronRight />}
                                    </IconButton>
                                    <b>{group.groupName}</b>
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    RESULTADOS: {group.count}
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                    {group.expanded && <Rows grouped={group.values} index={nextIndex} headers={headers} onReload={onReload} />}
                    {Object.keys(group.totals).length > 0 &&
                        <TableRow>
                            <CellsTotals headers={headers} values={group.totals} />
                        </TableRow>
                    }
                </Fragment>
            );
        } else {
            return (
                <Fragment key={keyRow}>
                    <TableRow>
                        <Cells headers={headers} values={group} />
                    </TableRow>
                </Fragment>
            );
        }
    })
}

const Cells = ({ headers, values }) => {
    let cells = [];
    for (let header of headers) {
        cells.push(
            <TableCell key={header} align="center" style={{ padding: '0.3rem', width: '250px' }}>
                {values[header]}
            </TableCell>
        );
    }
    return cells;
}

const CellsTotals = ({ headers, values }) => {
    let cells = [];
    for (let header of headers) {
        cells.push(
            <TableCell key={header} align="center" style={{ padding: '0.3rem', width: '250px', fontSize: '0.8rem' }}>
                <b>{values[header]}</b>
            </TableCell>
        );
    }
    return cells;
}

const Header = ({ headers, groups }) => {
    let HeaderCellList = [];
    for (let header of headers) {
        let isGroup = Boolean(groups.find(column => column.displayField === header.displayField));
        if (!isGroup) {
            HeaderCellList.push(<TableCell key={header.displayField} align="center" style={{ padding: '0.3rem', heigth: 0 }}><b>{header.label.toUpperCase()}</b></TableCell>)
        }
    }
    return HeaderCellList;
}

const styles = theme => ({
    root: {
        overflowX: 'auto',
        margin: '0.5rem'
    },
    table: {
        minWidth: 700,
    }
});

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

TableDynamic.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableDynamic);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import TableResponsive from '../../utils/TableResponsive';
import Profile from '../../utils/Profile';
import { filterUserActive } from '../../utils/FilterList';

import { getProcessesCompleted } from '../../../api/consumer/query';
import { getNameMonth, getMonths } from '../../utils/DataFormat';
import { getUser } from '../../../api/consumer/user';

export class ProcessCompleted extends Component {
    state = {
        processesCompleted: [],
        loading: false,
        idArithmetician: '',
        users: [],
        loadingUsers: true,
        year: new Date().getFullYear(),
        month: (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1,
        showCalculistaInativos: false
    };

    clickUsers = false;

    componentDidMount() {
        this.loading();
    }

    loading = async () => {
        try {
            if (this.state.year === '' && this.state.month !== '') {
                global.SnackBar.show('WARNING', 'Informe a ano');
                return;
            }

            const filter = {
                year: this.state.year,
                month: this.state.month,
                idArithmetician: this.state.idArithmetician
            };

            this.setState({ loading: true });
            const response = await getProcessesCompleted(filter);
            this.setState({
                processesCompleted: response.processesCompleteds,
                loading: false
            });
        } catch (error) {
            this.setState({
                processesCompleted: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    data = () => {
        var data = [];
        let totCompleted = 0;
        this.state.processesCompleted.forEach(processCompleted => {
            let dateCell = {
                name: processCompleted.name,
                year: processCompleted.year,
                month: getNameMonth(processCompleted.month).dsMonth,
                completed: processCompleted.completed
            };

            totCompleted += parseInt(processCompleted.completed);

            data.push(dateCell);
        });

        if (data.length > 0) {
            data.push({
                name: <b>Total</b>,
                year: '',
                month: '',
                completed: <b>{totCompleted}</b>
            });
        }

        return data;
    };

    loadingUsers = async () => {
        try {
            const response = await getUser();
            this.setState({
                users: response.users,
                loadingUsers: false
            });
        } catch (error) {
            this.setState({
                users: [],
                loadingUsers: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    onClickArithmetician = () => {
        if (this.state.users && !this.clickUsers) {
            this.clickUsers = true;
            this.loadingUsers();
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridList} spacing={16} direction="column">
                <Paper className={classes.paper} elevation={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" color="inherit" className={classes.fontTitle}>
                            Processos Concluídos
                        </Typography>
                    </Grid>
                    <Grid container spacing={16} direction="row" style={{ paddingTop: '1rem' }}>
                        <Grid item xs={4} sm={6} md={2} lg={2} xl={1}>
                            <TextField
                                fullWidth
                                label="Ano"
                                value={this.state.year}
                                onChange={e => this.setState({ year: e.target.value })}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} lg={3} xl={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="select-month">Mês</InputLabel>
                                <Select
                                    value={this.state.month}
                                    onChange={event => {
                                        this.setState({ month: event.target.value });
                                    }}
                                    inputProps={{ id: 'select-month' }}
                                >
                                    <MenuItem value={''}></MenuItem>
                                    {getMonths().map((month, index) => (
                                        <MenuItem value={month.cdMonth} key={index}>
                                            {month.dsMonth}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} sm={11} md={4} lg={3} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="select-arithmetician">Calculista</InputLabel>
                                <Select
                                    value={this.state.idArithmetician}
                                    onChange={event => {
                                        this.setState({ idArithmetician: event.target.value });
                                    }}
                                    inputProps={{ id: 'select-arithmetician' }}
                                    onClick={this.onClickArithmetician}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <MenuItem
                                            value={''}
                                            style={{
                                                width: '20%'
                                            }}
                                        ></MenuItem>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '80%' }}>
                                            <Typography
                                                variant="h6"
                                                color="inherit"
                                                style={{
                                                    textAlign: 'center',
                                                    fontFamily: 'Arial',
                                                    fontSize: '0.9rem',
                                                    color: 'rgba(0, 0, 0, 0.65)',
                                                    marginRight: '0.6rem'
                                                }}
                                            >
                                                Mostrar inativos
                                            </Typography>

                                            <Switch
                                                checked={this.state.showCalculistaInativos}
                                                onChange={e =>
                                                    this.setState({
                                                        showCalculistaInativos: e.target.checked
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    {this.state.loadingUsers ? (
                                        <MenuItem value={''}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    style={{
                                                        textAlign: 'center',
                                                        fontFamily: 'Arial',
                                                        fontSize: '1.05rem',
                                                        color: 'rgba(0, 0, 0, 0.65)',
                                                        marginRight: '0.6rem'
                                                    }}
                                                >
                                                    Buscando...
                                                </Typography>
                                                <CircularProgress size={20} color="secondary" />
                                            </div>
                                        </MenuItem>
                                    ) : (
                                        filterUserActive(this.state.users, this.state.showCalculistaInativos).map(
                                            (user, index) =>
                                                Profile.isArithmetician(user.profile) && (
                                                    <MenuItem
                                                        value={user.idUser}
                                                        key={index}
                                                        style={{
                                                            textDecoration: user.inactive ? 'line-through' : undefined
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                textDecoration: user.inactive ? 'line-through' : undefined
                                                            }}
                                                        >
                                                            {user.name}
                                                        </span>
                                                    </MenuItem>
                                                )
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                            <IconButton onClick={this.loading}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '1rem' }}>
                        <TableResponsive columns={columns} values={this.data()} loading={this.state.loading} fontContent={{ fontSize: '0.9rem' }} />
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

const columns = [
    {
        id: 'name',
        label: 'CALCULISTA'
    },
    {
        id: 'year',
        label: 'ANO'
    },
    {
        id: 'month',
        label: 'MÊS'
    },
    {
        id: 'completed',
        label: 'CONCLUÍDOS'
    }
];

const styles = {
    root: {
        flexGrow: 1
    },
    fontTitle: {
        fontSize: '1.1rem',
        textAlign: 'center'
    },
    paper: {
        padding: '20px'
    }
};

ProcessCompleted.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProcessCompleted);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import NotDeleteIcon from '@material-ui/icons/DeleteForever';

import DateTextField from '../../utils/DateTextField';
import feesReceivedApi from '../../../api/consumer/feesReceived';

import { formatMoney, formatMoneySymbol } from '../../utils/NumberFormat';

export class RegisterFeesReceived extends Component {
    state = {
        feesReceiveds: [],
        loading: false,
        saving: false,
        saved: false,
        totalReceived: 0
    };

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.process) {
                this.setState({ saved: false });
                this.loadingValues(props.process);
            }
        }
    }

    loadingValues = async process => {
        try {
            this.setState({
                feesReceiveds: [],
                loading: true
            });
            const { idProcess } = process;
            const reponse = await feesReceivedApi('GET', { idProcess });
            let feesReceiveds = reponse.listFeesReceived;
            feesReceiveds.map(feesReceived2 => {
                feesReceived2.received = formatMoney(feesReceived2.received);
                feesReceived2.updated = false;
                feesReceived2.deleted = false;
                return feesReceived2;
            });
            this.setState({
                feesReceiveds: feesReceiveds,
                loading: false
            });
            this.calculateTotal();
        } catch (error) {
            this.setState({
                feesReceiveds: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    saveFeesReceived = async () => {
        const { feesReceiveds } = this.state;
        try {
            this.setState({
                saving: true
            });

            for (let fees of feesReceiveds) {
                if (fees.receiptDate === '') {
                    throw new Error('Data de recebimento é obrigatório');
                }
                if (fees.received === '') {
                    throw new Error('Valor de recebimento é obrigatório');
                }
            }
            for (let fees of feesReceiveds) {
                if (fees.deleted) {
                    await feesReceivedApi('DELETE', {
                        ...fees
                    });
                } else {
                    if (fees.updated) {
                        await feesReceivedApi('CREATE', {
                            ...fees,
                            received: fees.received === '' ? null : parseFloat(fees.received)
                        });
                    }
                }
            }

            this.setState({
                saving: false,
                saved: true
            });
            //this.loadingValues(this.props.process);
            this.props.openOrClose(null, true);
            global.SnackBar.show('SUCCESS', 'Registro salvo');
        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    deleteFeesReceived = (index, feesReceived) => {
        if (feesReceived.idFeesReceived) {
            this.changeValue(!feesReceived.deleted, index, 'deleted');
        } else {
            let array = this.state.feesReceiveds;
            array.splice(index, 1);
            this.setState({
                feesReceiveds: array
            });
        }
    };

    addFeesReceived = () => {
        const { process } = this.props;
        let newFeesReceived = {
            idProcess: process.idProcess,
            receiptDate: '',
            received: '',
            updated: true
        };
        const array = this.state.feesReceiveds;
        array.push(newFeesReceived);
        this.setState({
            feesReceiveds: array
        });
    };

    getValueDate = date => {
        if (date === undefined) {
            return '';
        }
        if (date === null) {
            return '';
        }
        if (date === '') {
            return '';
        }
        return new Date(date);
    };

    changeValue = (value, index, attribute) => {
        if (value === null) {
            value = '';
        }
        let array = this.state.feesReceiveds;
        array[index][attribute] = value;
        array[index].updated = true;
        this.setState({
            feesReceiveds: array
        });
    };

    calculateTotal = () => {
        const { feesReceiveds } = this.state;
        let total = 0;
        for (let fees of feesReceiveds) {
            if (fees.received !== '') {
                total += parseFloat(fees.received);
            }
        }
        this.setState({
            totalReceived: total
        });
    };

    render() {
        const { classes, fullScreen, process } = this.props;
        if (!this.props.open) {
            return null;
        }
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.openOrClose(null, this.state.saved)}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    fullWidth
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">
                        Honorários Recebidos
                        <Fab
                            size="small"
                            color="secondary"
                            aria-label="Add"
                            style={{ marginLeft: '1rem' }}
                            onClick={this.addFeesReceived}
                            disabled={this.state.loading || this.state.saving}
                        >
                            <AddIcon />
                        </Fab>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.loading ? (
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem' }}
                            >
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Arial',
                                        fontSize: '1.05rem',
                                        color: 'rgba(0, 0, 0, 0.65)',
                                        marginRight: '0.6rem'
                                    }}
                                >
                                    Buscando...
                                </Typography>
                                <CircularProgress size={20} color="secondary" />
                            </div>
                        ) : (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Paper className={classes.paperHeader} elevation={1}>
                                        <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                            Número: {process ? process.number : ''}
                                        </Typography>
                                        <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                            Vara Trabalhista: {process ? `${process.LaborCourt.code} - ${process.LaborCourt.name}` : ''}
                                        </Typography>
                                        <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                            Honorários arbitrado: <b>{process ? formatMoneySymbol(process.arbitratedFees) : ''}</b>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '1rem' }}>
                                    {this.state.feesReceiveds.map((feesReceived, index) => (
                                        <Grid container direction="row" spacing={16} key={index}>
                                            <Grid
                                                item
                                                xs={1}
                                                sm={1}
                                                md={1}
                                                lg={1}
                                                xl={1}
                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color="inherit"
                                                    style={{ fontSize: '0.85rem', color: 'rgba(0, 0, 0, 0.50)' }}
                                                >
                                                    #{index + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={5} md={5} lg={5} xl={5}>
                                                <DateTextField
                                                    fullWidth
                                                    label="Data Recebimento"
                                                    value={this.getValueDate(feesReceived.receiptDate)}
                                                    onChange={date => this.changeValue(date, index, 'receiptDate')}
                                                    disabled={feesReceived.deleted}
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                <FormControl fullWidth disabled={feesReceived.deleted}>
                                                    <InputLabel htmlFor="fees-received-value">Valor</InputLabel>
                                                    <Input
                                                        id="fees-received-value"
                                                        type="number"
                                                        value={feesReceived.received}
                                                        onBlur={() => {
                                                            this.changeValue(formatMoney(feesReceived.received), index, 'received');
                                                            this.calculateTotal();
                                                        }}
                                                        onChange={event => this.changeValue(event.target.value, index, 'received')}
                                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <IconButton onClick={e => this.deleteFeesReceived(index, feesReceived)}>
                                                    {feesReceived.deleted ? (
                                                        <NotDeleteIcon className={classes.iconEdit} />
                                                    ) : (
                                                        <DeleteIcon className={classes.iconEdit} />
                                                    )}
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <div className={classes.divFooter}>
                            <Typography color="inherit" style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.90)' }}>
                                Total: <b>{formatMoneySymbol(this.state.totalReceived)}</b>
                            </Typography>
                            <div>
                                <Button
                                    onClick={() => this.props.openOrClose(null, this.state.saved)}
                                    color="primary"
                                    disabled={this.state.loading || this.state.saving}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={this.saveFeesReceived}
                                    color="primary"
                                    variant="contained"
                                    disabled={this.state.loading || this.state.saving}
                                    style={{ marginLeft: '0.7rem' }}
                                >
                                    {this.state.saving && <CircularProgress size={20} color="secondary" style={{ marginRight: '0.35rem' }} />}
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1
    },
    paperHeader: {
        padding: '1rem'
    },
    divFooter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
};

RegisterFeesReceived.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterFeesReceived));

export function formatDateTimeBRZ (data) {

	if (!data) {
		return '';
    }

    data = new Date(data);

    let dia = data.getDate().toString()
    let diaF = (dia.length === 1) ? '0' + dia : dia
    let mes = (data.getMonth() + 1).toString()
    let mesF = (mes.length === 1) ? '0' + mes : mes
    let anoF = data.getFullYear()
    let hora = data.getHours().toString()
    let horaF = (hora.length === 1) ? '0' + hora : hora
    let minute = data.getMinutes().toString()
    let minuteF = (minute.length === 1) ? '0' + minute : minute
    let second = data.getSeconds().toString()
    let secondF = (second.length === 1) ? '0' + second : second
    return diaF + '/' + mesF + '/' + anoF + ' ' + horaF + ':' + minuteF + ':' + secondF
}

export function formatDateBRZ (data) {    

	if (!data) {
		return '';
	}
	
	data = new Date(data);
	
	let dia = data.getDate().toString();
	let diaF = (dia.length === 1) ? '0' + dia : dia;
	let mes = (data.getMonth() + 1).toString();
	let mesF = (mes.length === 1) ? '0' + mes : mes;
	let anoF = data.getFullYear();

	return diaF + '/' + mesF + '/' + anoF;
}

export function formatDateExtBRZ (data) {    

	if (!data) {
		return '';
	}
	
	data = new Date(data);
	
	let dia = data.getDate().toString();
	let diaF = (dia.length === 1) ? '0' + dia : dia;	
	let mesF = getNameMonth(data.getMonth() + 1).dsMonth;
	let anoF = data.getFullYear();

	return diaF + ' de ' + mesF + ' de ' + anoF;
}

export function getMonths () {
    let months = []
    months.push({cdMonth: '01', dsMonth: 'Janeiro'})
    months.push({cdMonth: '02', dsMonth: 'Fevereiro'})
    months.push({cdMonth: '03', dsMonth: 'Março'})
    months.push({cdMonth: '04', dsMonth: 'Abril'})
    months.push({cdMonth: '05', dsMonth: 'Maio'})
    months.push({cdMonth: '06', dsMonth: 'Junho'})
    months.push({cdMonth: '07', dsMonth: 'Julho'})
    months.push({cdMonth: '08', dsMonth: 'Agosto'})
    months.push({cdMonth: '09', dsMonth: 'Setembro'})
    months.push({cdMonth: '10', dsMonth: 'Outubro'})
    months.push({cdMonth: '11', dsMonth: 'Novembro'})
    months.push({cdMonth: '12', dsMonth: 'Dezembro'})
    return months
}

export const getNameMonth = (cdMonth) => {      
    return getMonths().find(month => parseInt(month.cdMonth) === parseInt(cdMonth)) || '';        
}

export function getLastDayMounth (date) {
    date.setDate(1)
    date.setMonth(date.getMonth() + 1)
    date.setDate(date.getDate() - 1)
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date;
}
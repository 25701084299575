import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { FiArrowLeftCircle, FiArrowRightCircle, FiArrowUpCircle, FiArrowDownCircle, FiMenu } from 'react-icons/fi';
import { Divider } from '@material-ui/core';

export class DefineColumns extends Component {

    constructor(props) {
        super(props);
        let fullColumns = clone(props.parameters.fields);
        for (let i = 0; i < fullColumns.length; i++) {
            if (this.props.selectedColumns.find(column => column.displayField === fullColumns[i].displayField)) {
                fullColumns.splice(i, 1);
                i--;
            }
        }
        this.state = {
            fullColumns,
            selectedIndexFullColumns: '',
            selectedIndexSelectedColumns: ''
        }
    }

    addColumns = () => {
        let fullColumns = clone(this.state.fullColumns);
        let selectedColumns = clone(this.props.selectedColumns);
        for (let i = 0; i < fullColumns.length; i++) {
            if (i === this.state.selectedIndexFullColumns) {
                fullColumns[i].selected = false;
                selectedColumns.push(fullColumns[i]);
                fullColumns.splice(i, 1);
                this.setState({
                    selectedIndexFullColumns: ''
                });
                break;
            }
        }
        this.setState({
            fullColumns
        });
        this.props.setSelectedColumns(selectedColumns);
    }

    removeColumns = () => {
        let fullColumns = clone(this.state.fullColumns);
        let selectedColumns = clone(this.props.selectedColumns);
        for (let i = 0; i < selectedColumns.length; i++) {
            if (i === this.state.selectedIndexSelectedColumns) {
                selectedColumns[i].selected = false;
                fullColumns.push(selectedColumns[i]);
                selectedColumns.splice(i, 1);
                this.setState({
                    selectedIndexSelectedColumns: ''
                });
                break;
            }
        }
        this.setState({
            fullColumns
        })
        this.props.setSelectedColumns(selectedColumns);
    }

    upColumn = () => {
        let selectedColumns = clone(this.props.selectedColumns);
        for (let i = 0; i < selectedColumns.length; i++) {
            if (i === this.state.selectedIndexSelectedColumns) {
                if (i > 0 && selectedColumns.length > 1) {
                    let next = selectedColumns[i - 1];
                    let prev = selectedColumns[i];
                    selectedColumns[i - 1] = prev;
                    selectedColumns[i] = next;
                    this.setState({
                        selectedIndexSelectedColumns: i - 1
                    });
                }
                break;
            }
        }
        this.props.setSelectedColumns(selectedColumns);
    }

    downColumn = () => {
        let selectedColumns = clone(this.props.selectedColumns);
        for (let i = 0; i < selectedColumns.length; i++) {
            if (i === this.state.selectedIndexSelectedColumns) {
                if (i + 1 < selectedColumns.length && selectedColumns.length > 1) {
                    let next = selectedColumns[i + 1];
                    let prev = selectedColumns[i];
                    selectedColumns[i] = next;
                    selectedColumns[i + 1] = prev;
                    this.setState({
                        selectedIndexSelectedColumns: i + 1
                    });
                }
                break;
            }
        }
        this.props.setSelectedColumns(selectedColumns);
    }

    render() {
        return (
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FiMenu fontSize="22" style={{ transform: 'rotate(90deg)' }} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: '1.2rem' }}>Colunas</Typography>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <List component="nav" style={{ border: '1px solid #d3d4d4', height: '15rem', overflowY: 'scroll' }} subheader={<Typography style={{ textAlign: 'center' }}><b>Todas Colunas</b></Typography>}>
                                {this.state.fullColumns.map((param, index) => (
                                    <ListItem
                                        style={this.state.selectedIndexFullColumns === index ? { backgroundColor: '#00A4EB' } : null}
                                        divider
                                        key={index}
                                        button
                                        selected={this.state.selectedIndexFullColumns === index}
                                        onClick={() => this.setState({ selectedIndexFullColumns: index })}
                                    >
                                        <ListItemText primary={<span style={this.state.selectedIndexFullColumns === index ? { color: '#FFF' } : null}>{param.label}</span>} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <IconButton onClick={this.addColumns}>
                                    <FiArrowRightCircle />
                                </IconButton>
                                <IconButton onClick={this.removeColumns}>
                                    <FiArrowLeftCircle />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <List component="nav" style={{ border: '1px solid #d3d4d4', height: '15rem', overflowY: 'scroll'  }} subheader={<Typography style={{ textAlign: 'center' }}><b>Colunas Selecionadas</b></Typography>}>
                                {this.props.selectedColumns.map((param, index) => (
                                    <ListItem
                                        style={this.state.selectedIndexSelectedColumns === index ? { backgroundColor: '#00A4EB' } : null}
                                        color="primary"
                                        divider
                                        key={index}
                                        button
                                        selected={this.state.selectedIndexSelectedColumns === index}
                                        onClick={() => this.setState({ selectedIndexSelectedColumns: index })}
                                    >
                                        <ListItemText primary={<span style={this.state.selectedIndexSelectedColumns === index ? { color: '#FFF' } : null}>{param.label}</span>} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <IconButton onClick={this.upColumn}>
                                    <FiArrowUpCircle />
                                </IconButton>
                                <IconButton onClick={this.downColumn}>
                                    <FiArrowDownCircle />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

};

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const styles = {}

DefineColumns.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DefineColumns);

import api from '../api';

export async function getUserAuth() {
    try {
        const response = await api.get('/user/authenticated');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getUser(allUsers = false) {
    try {
        const response = await api.get(`/user${(allUsers ? '?allUsers=true' : '')}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getOneUser(idUser) {
    try {
        const response = await api.get(`/user/?idUser=${idUser}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getUserAccesses() {
    try {
        const response = await api.get('/user/accesses');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveUser(user) {
    try {
        let response;
        if (user.idUser === null) {
            response = await api.post('/user', user);
        } else {
            response = await api.put(`/user/${user.idUser}`, user);
        }
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function deleteUser(idUser) {
    try {
        const response = await api.delete(`/user/${idUser}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
import { formatDateBRZ, formatDateTimeBRZ } from '../../utils/DataFormat';
import { formatMoneySymbol, formatMoney } from '../../utils/NumberFormat';
import { getTypeDateValues } from '../../../resources/types/typeConditionDynamicReport';

export const agroup = (index, groups, fields, values) => {
    let grouped = []
    let valueGroups = [];
    let lastValue;

    for (let i = 0; i < values.length; i++) {
        if (lastValue !== values[i][groups[index].displayField]) {
            valueGroups.push(values[i][groups[index].displayField]);
        }
        lastValue = values[i][groups[index].displayField];
    }

    for (let valGroup of valueGroups) {
        let grp = {
            isGroup: true,
            groupName: valGroup,
            groupId: groups[index].displayField,
            expanded: false,
            count: 0,
            totals: {},            
            values: [],
        }

        for (let i = 0; i < values.length; i++) {
            if (values[i][groups[index].displayField] === valGroup) {
                delete values[i][groups[index].displayField];
                grp.values.push(values[i]);
            }
        }

        let nextIndex = index + 1;
        if (nextIndex < groups.length) {
            grp.count += grp.values.length;
            grp.totals = generateTotals(fields, grp.values);
            grp.values = agroup(nextIndex, groups, fields, grp.values);
            grouped.push(grp);
        } else {
            grp.count += grp.values.length;
            grp.totals = generateTotals(fields, grp.values);
            grp.values = formatNumeric(fields, grp.values);
            grouped.push(grp);
        }
    }

    return grouped;
}

export const generateTotals = (fields, values) => {
    let totals = {};
    for (let i = 0; i < values.length; i++) {
        for (let columnName in values[i]) {
            const field = fields.find(fieldStruct => fieldStruct.displayField === columnName);
            if (field) {
                switch (field.dataType) {
                    case 'INTEGER':
                        if (totals[columnName] === undefined) {
                            totals[columnName] = 0;
                        }
                        if (values[i][columnName] !== null) {
                            totals[columnName] = parseInt(totals[columnName]) + parseInt(values[i][columnName])
                        }
                        break;
                    case 'FLOAT':
                        if (totals[columnName] === undefined) {
                            totals[columnName] = 0;
                        }
                        if (values[i][columnName] !== null) {
                            totals[columnName] = parseFloat(totals[columnName]) + parseFloat(values[i][columnName])
                        }
                        break;
                    case 'MONEY':
                        if (totals[columnName] === undefined) {
                            totals[columnName] = 0;
                        }
                        if (values[i][columnName] !== null) {
                            totals[columnName] = parseFloat(totals[columnName]) + parseFloat(values[i][columnName])
                        }
                        break;
                    default:
                }
            }
        }
    }

    for (let columnName in totals) {
        const field = fields.find(fieldStruct => fieldStruct.displayField === columnName);
        if (field) {
            switch (field.dataType) {
                case 'FLOAT':
                    totals[columnName] = formatMoney(totals[columnName]);
                    break;
                case 'MONEY':
                    totals[columnName] = formatMoneySymbol(totals[columnName]);
                    break;
                default:
            }
        }
    }

    return totals;
}

export function formatNumeric(fields, values) {
    for (let i = 0; i < values.length; i++) {
        for (let columnName in values[i]) {
            const field = fields.find(fieldStruct => fieldStruct.displayField === columnName);
            if (field) {
                switch (field.dataType) {
                    case 'FLOAT':
                        values[i][columnName] = formatMoney(values[i][columnName]);
                        break;
                    case 'MONEY':
                        values[i][columnName] = formatMoneySymbol(values[i][columnName]);
                        break;
                    default:
                }
            }
        }
    }
    return values;
}

export const formatValues = (fields, values) => {
    for (let i = 0; i < values.length; i++) {
        for (let columnName in values[i]) {
            const field = fields.find(fieldStruct => fieldStruct.displayField === columnName);
            if (field) {
                switch (field.dataType) {
                    case 'TEXT':
                        values[i][columnName] = (values[i][columnName] !== null ? values[i][columnName].toString().trim() : null);
                        break;
                    case 'DATE':
                        values[i][columnName] = formatDateBRZ(values[i][columnName]);
                        break;
                    case 'DATETIME':
                        values[i][columnName] = formatDateTimeBRZ(values[i][columnName]);
                        break;
                    default:
                }
            }
        }
    }
    return values;
}

export const formatField = (fields, columnName, value) => {

    const field = fields.find(fieldStruct => fieldStruct.displayField === columnName);
    if (field) {
        switch (field.dataType) {
            case 'TEXT':
                return (value).toString().trim();
            case 'DATE':
                if (value.typeDateValue === '@FIXED') {
                    return formatDateBRZ(value.dateValue);
                } else {
                    return getTypeDateValues(value.typeDateValue).description;
                }                
            case 'DATETIME':
                return formatDateTimeBRZ(value);
            default:
        }
    }

    return value;
}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import Redirector from '../../Redirector';

import { verifyEmail } from '../../api/consumer/auth';
import BadgeAllPasso from '../utils/BadgeAllPasso';

class VerifyEmail extends React.Component {
    state = {
        waitVerifyEmail: false,
        password: '',
        password2: ''
    };

    recoverPassword = async () => {
        try {
            if (!this.props.match.params.token) {
                global.SnackBar.show('WARNING', 'Nenhum token informado!');
                return;
            }

            if (this.state.password === '') {
                global.SnackBar.show('WARNING', 'Senha é obrigatória!');
                return;
            }

            if (this.state.password2 === '') {
                global.SnackBar.show('WARNING', 'Confirmação de senha é obrigatório!');
                return;
            }

            if (this.state.password !== this.state.password2) {
                global.SnackBar.show('WARNING', 'Senhas são diferentes!');
                return;
            }

            this.setState({ waitVerifyEmail: true });
            const message = await verifyEmail(this.props.match.params.token, this.state.password);
            this.setState({ password: '', password2: '', waitRecoverPassword: false });
            global.SnackBar.show('SUCCESS', message);

            setTimeout(() => {
                Redirector.login(this, 401);
            }, 3000);
        } catch (error) {
            this.setState({ waitVerifyEmail: false });
            if (error.code === undefined) {
                global.SnackBar.show('ERROR', 'Sem conexão com o servidor de dados!');
            } else {
                global.SnackBar.show('ERROR', `${error.code} - ${error.message}`);
            }
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.bodyContainer}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Paper className={classes.paperContainer} elevation={15} square={true}>
                            <Typography variant="h5" component="h3" className={classes.fontHeader}>
                                Confirmação de Cadastro
                            </Typography>
                            <Grid container className={classes.gridForm}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <TextField
                                        label="E-mail"
                                        className={classes.textField}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        fullWidth
                                        value={this.props.match.params.email}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <TextField
                                        label="Senha"
                                        className={classes.textField}
                                        type="password"
                                        autoComplete="current-password"
                                        fullWidth
                                        value={this.state.password}
                                        onChange={event => {
                                            this.setState({ password: event.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <TextField
                                        label="Confirme a Senha"
                                        className={classes.textField}
                                        type="password"
                                        autoComplete="current-password"
                                        fullWidth
                                        value={this.state.password2}
                                        onChange={event => {
                                            this.setState({ password2: event.target.value });
                                        }}
                                    />
                                </Grid>
                                {this.state.waitVerifyEmail ? (
                                    <Grid item xs={12} className={classes.gridProgress}>
                                        <LinearProgress color="secondary" />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} className={classes.gridButtonLogin}>
                                        <Button variant="contained" color="primary" fullWidth onClick={this.recoverPassword}>
                                            Salvar
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                        <div className={classes.fromText}>
                            Powered by <BadgeAllPasso />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = {
    bodyContainer: {
        height: '100vh',
        backgroundColor: '#e6e9ea'
    },
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    fontHeader: {
        color: '#373737',
        marginLeft: '12px',
        marginTop: '10px'
    },
    fontLogo: {
        color: '#FFF',
        fontFamily: 'FontLogo',
        fontSize: '1.90rem',
        margin: '12px'
    },
    centered: {
        textAlign: 'center'
    },
    gridContainer: {
        height: '95vh',
        padding: '10px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paperContainer: {
        padding: '20px',
        borderRadius: '9px 9px 9px 9px'
    },
    gridForm: {
        padding: '15px'
    },
    gridItem: {
        marginBottom: '21px'
    },
    gridButtonLogin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    gridProgress: {
        marginTop: '25px',
        flexGrow: 1
    },
    paperFooter: {
        backgroundColor: '#1E004A',
        padding: '20px',
        borderRadius: '12px 12px 12px 12px'
    },
    fontMarq: {
        color: '#FFF',
        textAlign: 'center'
    },
    buttonRecover: {
        marginTop: '15px',
        textDecoration: 'none',
        color: 'black'
    },
    fromText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '0.875rem',
        color: '#6B6B6B',
        paddingTop: '20px'
    }
};

VerifyEmail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VerifyEmail);

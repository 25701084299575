import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Routes from './route/Routes';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

console.log(`Enviroment: ${process.env.REACT_APP_ENV}`);
console.log(`VERSION_FRONTEND: ${new Date(process.env.REACT_APP_VERSION).toLocaleString()}`);
console.log(`API: ${process.env.REACT_APP_API_URL}`);

serviceWorker.register(); //

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#3F51B5'
        },
        secondary: {
            main: '#673AB7'
        }
    },
    typography: {
        useNextVariants: true
    }
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Routes />
    </MuiThemeProvider>,
    document.getElementById('root')
);

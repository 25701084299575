import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import OpenReport from '../../Report/OpenReport';
import Profile from '../../../utils/Profile';

import { getMonths } from '../../../utils/DataFormat';
import { filterUserActive } from '../../../utils/FilterList';
import { getUser } from '../../../../api/consumer/user';

export class OpenR002 extends Component {
    state = {
        year: new Date().getFullYear(),
        month: (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1,
        users: [],
        loadingUsers: true,
        idArithmetician: '',
        showCalculistaInativos: false
    };

    openReport = () => {
        let { month, year, idArithmetician } = this.state;

        if (month === '') {
            global.SnackBar.show('WARNING', 'Informe o mês de pagamento');
            return;
        }

        if (year === '') {
            global.SnackBar.show('WARNING', 'Informe o ano de pagamento');
            return;
        }

        if (Profile.isAdmin(this.props.user.profile)) {
            if (idArithmetician === '') {
                global.SnackBar.show('WARNING', 'Informe o calculista do pagamento');
                return;
            }
        } else {
            idArithmetician = this.props.user.idUser;
        }

        const params = {
            month,
            year,
            idArithmetician
        };

        OpenReport('R002', params);
    };

    loadingUsers = async () => {
        try {
            const response = await getUser();
            this.setState({
                users: response.users,
                loadingUsers: false
            });
        } catch (error) {
            this.setState({
                users: [],
                loadingUsers: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    onClickArithmetician = e => {
        if (this.state.users && !this.clickUsers) {
            this.clickUsers = true;
            this.loadingUsers();
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Demonstrativo de Pagamento</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-month">Mês</InputLabel>
                                    <Select
                                        value={this.state.month}
                                        onChange={event => {
                                            this.setState({ month: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-month' }}
                                    >
                                        {getMonths().map((month, index) => (
                                            <MenuItem value={month.cdMonth} key={index}>
                                                {month.dsMonth}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                <TextField
                                    fullWidth
                                    label="Ano"
                                    value={this.state.year}
                                    onChange={e => this.setState({ year: e.target.value })}
                                    type="number"
                                />
                            </Grid>
                            {Profile.isAdmin(this.props.user.profile) && (
                                <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="select-arithmetician">Calculista</InputLabel>
                                        <Select
                                            value={this.state.idArithmetician}
                                            onChange={event => {
                                                event.stopPropagation();
                                                this.setState({ idArithmetician: event.target.value });
                                            }}
                                            inputProps={{ id: 'select-arithmetician' }}
                                            onClick={this.onClickArithmetician}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                <MenuItem
                                                    value={''}
                                                    style={{
                                                        width: '60%'
                                                    }}
                                                ></MenuItem>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Arial',
                                                            fontSize: '0.9rem',
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                            marginRight: '0.6rem'
                                                        }}
                                                    >
                                                        Mostrar inativos
                                                    </Typography>

                                                    <Switch
                                                        checked={this.state.showCalculistaInativos}
                                                        onChange={e =>
                                                            this.setState({
                                                                showCalculistaInativos: e.target.checked
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            {this.state.loadingUsers ? (
                                                <MenuItem value={''}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography
                                                            variant="h6"
                                                            color="inherit"
                                                            style={{
                                                                textAlign: 'center',
                                                                fontFamily: 'Arial',
                                                                fontSize: '1.05rem',
                                                                color: 'rgba(0, 0, 0, 0.65)'
                                                            }}
                                                        >
                                                            Buscando...
                                                        </Typography>
                                                        <CircularProgress size={20} color="secondary" />
                                                    </div>
                                                </MenuItem>
                                            ) : (
                                                filterUserActive(this.state.users, this.state.showCalculistaInativos).map(
                                                    (user, index) =>
                                                        Profile.isArithmetician(user.profile) && (
                                                            <MenuItem value={user.idUser} key={index}>
                                                                <span
                                                                    style={{
                                                                        textDecoration: user.inactive ? 'line-through' : undefined
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </span>
                                                            </MenuItem>
                                                        )
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Fab variant="extended" color="secondary" className={classes.fab} onClick={this.openReport}>
                                    <PrintIcon style={{ marginRight: '0.5rem' }} />
                                    Gerar
                                </Fab>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        padding: '1rem'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    fab: {
        margin: theme.spacing.unit
    }
});

OpenR002.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenR002);

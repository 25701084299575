import Token from './api/Token'

export default {
  login(contexto, code = '') {
    if (code.toString() === '401') {
      Token.removeToken();
      contexto.props.history.push('/login');
      return true;
    }
    return false;
  },
  main(contexto) {
    contexto.props.history.push('/');
  },
  admin(contexto) {
    contexto.props.history.push('/admin');
  },
  to(contexto, route) {
    contexto.props.history.push(route);
  }
}
import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import { formatDateTimeBRZ, formatDateBRZ } from '../../../../utils/DataFormat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import feesOfProcesses from '../../../../../api/consumer/feesOfProcesses';
import { formatMoneySymbol } from '../../../../utils/NumberFormat';
export class R001 extends Component {
    state = {
        loading: true,
        error: null,
        receivedFeesTotal: 0.0,
        processes: []
    };

    componentWillMount() {
        this.loadingProcesses();
    }

    loadingProcesses = async () => {
        try {
            const { tpSort, LaborCourt } = this.props.params;
            if (tpSort === undefined) {
                this.setState({
                    loading: false,
                    error: 'Tipo de ordenação é obrigatória.'
                });
                return;
            }

            let param = {
                order: tpSort === 'idProcess' ? [['idProcess', 'ASC']] : [['receiptDate', 'DESC']]
            };
            if (LaborCourt) {
                param.idLaborCourt = LaborCourt.idLaborCourt;
            }
            const response = await feesOfProcesses('GET', param);
            let processes = response.feesOfProcesses;

            let receivedFeesTotal = 0;
            processes.forEach(process => {
                if (process.received > 0) {
                    receivedFeesTotal += parseFloat(process.received);
                }
            });

            this.setState({
                processes,
                receivedFeesTotal,
                loading: false
            });
        } catch (error) {
            let descError = 'Erro ao consultar pagamentos';
            if (error !== undefined) {
                if (error.message !== undefined) {
                    descError = error.message;
                } else {
                    descError = error;
                }
            }
            this.setState({
                loading: false,
                error: descError
            });
        }
    };

    render() {
        const { user, person } = this.props;
        const { tpSort, LaborCourt } = this.props.params;
        const reportName = 'Demonstrativo Fechamento de Honorários';
        const textCellHeader = styles.textCellHeader;
        const textCellRow = styles.textCellRow;

        console.log(person.cellPhone.indexOf('_'));

        let descLaborCourt = '';
        if (LaborCourt) {
            descLaborCourt = `${LaborCourt.code} - ${LaborCourt.name}`;
        }

        if (this.state.error != null) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Erro ao buscar processos: {JSON.stringify(this.state.error)}
                    </Typography>
                </Paper>
            );
        } else if (this.state.loading) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Buscando processos...
                    </Typography>
                    <CircularProgress size={20} color="secondary" />
                </Paper>
            );
        } else {
            return (
                <PDFViewer width="100%" height="100%" style={{ border: 0, padding: 0 }}>
                    <Document title={reportName}>
                        <Page size="A4" orientation="portrait" style={styles.page}>
                            <View style={styles.viewTitle}>
                                {person.logo && (
                                    <View style={{ flex: 1 }}>
                                        <Image style={styles.imageLogo} src={person.logo} />
                                    </View>
                                )}
                                <View style={{ flex: 3, display: 'flex' }}>
                                    <Text style={styles.textReportName}>{reportName}</Text>
                                    <Text style={styles.textSocialReason}>{person.socialReason}</Text>
                                    <Text style={styles.textAdditionalDescription}>{person.additionalDescription}</Text>
                                    <Text style={styles.textAdditionalDescription}>CNPJ: {person.registrationNumber}</Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.telephone.indexOf('_') < 0 && person.telephone}
                                        {person.telephone.indexOf('_') < 0 && person.cellPhone.indexOf('_') && '/'}
                                        {person.cellPhone.indexOf('_') < 0 && person.cellPhone}
                                    </Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.city} - {person.province}
                                    </Text>
                                </View>
                                {person.logo && <View style={{ flex: 1 }} />}
                            </View>
                            <View style={styles.viewInfo}>
                                <Text style={styles.textParamPeriod}>Ordenação: {tpSort === 'idProcess' ? 'Processo' : 'Data de Recebimento'}</Text>
                                <Text style={styles.textParamPeriod}>Origem: {descLaborCourt}</Text>
                                <Text style={styles.textIssued}>
                                    Emitido por: {user.name} - {formatDateTimeBRZ(new Date())}
                                </Text>
                            </View>
                            <View fixed style={styles.tableHeader}>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Origem</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Número</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Reclamante</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Reclamado</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 1 }}>
                                    <Text>Dt.Recebimento</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 1 }}>
                                    <Text>Recebido</Text>
                                </View>
                            </View>
                            {this.state.processes.map((process, index) => (
                                <View style={styles.tableRow} key={index} wrap={false}>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{process.nameLoaborCourt}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{process.number}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{process.claimant}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{process.claimed}</Text>
                                    <Text style={{ ...textCellRow, flex: 1 }}>{formatDateBRZ(process.receiptDate)}</Text>
                                    <Text style={{ ...textCellRow, flex: 1 }}>{formatMoneySymbol(process.received)}</Text>
                                </View>
                            ))}
                            <View style={styles.tableRowTotal} wrap={false}>
                                <Text style={{ ...textCellRow, textAlign: 'left', flex: 8, fontSize: 7 }}>
                                    Processos: {this.state.processes.length}
                                </Text>
                                <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>TOTAL</Text>
                                <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>{formatMoneySymbol(this.state.receivedFeesTotal)}</Text>
                            </View>
                            <View
                                fixed
                                style={styles.viewPageNumber}
                                render={() => (
                                    <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <View style={{ flex: 1, paddingLeft: 15 }}>
                                            <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <View>
                                                    <Image style={styles.imageAllPasso} src="/images/logo_64X64.png" />
                                                </View>
                                                <View style={{ paddingLeft: 3 }}>
                                                    <Text>AllPasso Software {'<allpasso.com.br>'}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text
                                                style={styles.textPageNumber}
                                                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                            />
                                        </View>
                                        <View style={{ flex: 1 }} />
                                    </View>
                                )}
                            />
                        </Page>
                    </Document>
                </PDFViewer>
            );
        }
    }
}

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        backgroundColor: '#FFF',
        padding: 20,
        paddingBottom: 30
    },
    viewTitle: {
        padding: 7,
        textAlign: 'center',
        border: '1 solid #000',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row'
    },
    imageLogo: {
        width: 80
    },
    textReportName: {
        fontSize: 12
    },
    textSocialReason: {
        fontSize: 8
    },
    textAdditionalDescription: {
        fontSize: 8
    },
    viewInfo: {
        marginTop: 5,
        marginBottom: 5,
        padding: 3,
        textAlign: 'left',
        border: '1 solid #000',
        borderRadius: 4
    },
    textIssued: {
        fontSize: 8
    },
    textParamPeriod: {
        fontSize: 8
    },
    viewPageNumber: {
        position: 'absolute',
        fontSize: 6,
        bottom: 20,
        left: 20,
        right: 20
    },
    textPageNumber: {
        textAlign: 'center',
        color: '#000'
    },
    imageAllPasso: {
        width: 10
    },
    tableHeader: {
        paddingTop: 5,
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #000',
        borderRadius: 4
    },
    tableRow: {
        padding: 2,
        display: 'flex',
        flexDirection: 'row'
    },
    tableRowTotal: {
        marginTop: 3,
        padding: 3,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #000',
        borderRadius: 4
    },
    textCellHeader: {
        fontSize: 7,
        display: 'flex',
        margin: 0,
        textAlign: 'center'
    },
    textCellRow: {
        fontSize: 6,
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2
    }
});

export default R001;

import api from '../api';

export async function getProcessesPending() {
    try {
        const response = await api.get('/query/processes/pending');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getProcessesCompleted(filter) {
    try {
        const response = await api.get('/query/processes/completed',{
            params: filter
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getSituationOfProcesses(filter) {
    try {
        const response = await api.get('/query/processes/situation',{
            params: filter
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getProcessesEntry(filter) {
    try {
        const response = await api.get('/query/processes/entry',{
            params: filter
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function getProcessesOut(filter) {
    try {
        const response = await api.get('/query/processes/out',{
            params: filter
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
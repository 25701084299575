export default function open(dynamicReportId, params) {
    let url = `/dynamic-report/${dynamicReportId}`;
    
    /*if (params) {
        params =  JSON.stringify(params);
        const paramsQuery = new URLSearchParams({query: params});
        url += `?${paramsQuery.toString()}`;
    }*/

    params =  JSON.stringify(params);
    window.sessionStorage.setItem(`@queryDynamicReportDR${dynamicReportId}`, params);        
    
    window.open(url, '_blank');
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Token from '../../../api/Token';

import { saveUser } from '../../../api/consumer/user';
import { getCustomer } from '../../../api/consumer/customer';
import { typeProfile, typeProfileNotSuperAdmin } from '../../../resources/types/typeProfile'

export class RegisterUser extends Component {

    state = {
        name: '',
        email: '',
        profile: '',
        idCustomer: '',
        inactive: false,
        customers: []
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            this.loadingCustomers();
            if (props.userEdit) {
                this.setState({
                    name: props.userEdit.name,
                    email: props.userEdit.email,
                    profile: props.userEdit.profile,
                    idCustomer: props.userEdit.idCustomer,
                    inactive: props.userEdit.inactive
                });
            } else {
                this.clearUser();
            }
        }
    }

    loadingCustomers = async () => {    
        
        const { isSuperAdmin } = this.props;
        if (!isSuperAdmin) {
            return;
        }

        try {
            const response = await getCustomer();
            this.setState({
                customers: response.customers
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    clearUser = () => {
        this.setState({
            name: '',
            email: '',
            profile: '',
            idCustomer: '',
            inactive: false,
        });
    }

    saveUser = async () => {

        const { isSuperAdmin, user: userLogged } = this.props;        
        
        if (this.state.name === '') {
            global.SnackBar.show('WARNING', 'Informe o nome do usuário');
            return;
        }

        if (this.state.email === '') {
            global.SnackBar.show('WARNING', 'Informe o e-mail do usuário');
            return;
        }

        if (this.state.profile === '') {
            global.SnackBar.show('WARNING', 'Informe o perfil do usuário');
            return;
        }

        if (this.state.idCustomer === '' && isSuperAdmin) {
            global.SnackBar.show('WARNING', 'Informe o cliente do usuário');
            return;
        }

        try {
            let user = {
                idUser: (this.props.userEdit ? this.props.userEdit.idUser : null),
                name: this.state.name,
                email: this.state.email,
                profile: this.state.profile,                
                inactive: this.state.inactive,
                idCustomer: this.state.idCustomer
            }
            await saveUser(user);
            global.SnackBar.show('SUCCESS', 'Registro salvo');
            if (userLogged.idUser === user.idUser) {                
                Token.removeUserAuth();
                window.location.reload();
            }            
            this.props.openOrClose();
        } catch (error) {            
            global.SnackBar.show('ERROR', error.message, 7000);
        }
    }

    render() {
        const { classes, fullScreen, isSuperAdmin } = this.props; 
        
        var listTypeProfile;
        if (isSuperAdmin) {
            listTypeProfile = typeProfile;
        } else {
            listTypeProfile = typeProfileNotSuperAdmin();
        }        

        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">Cadastro Usuário</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    label="Nome"
                                    fullWidth
                                    value={this.state.name}
                                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    label="E-mail"
                                    fullWidth
                                    value={this.state.email}
                                    onChange={(event) => { this.setState({ email: event.target.value }) }}
                                    type="email"
                                    disabled={this.props.userEdit !== null}
                                />
                            </Grid>
                            {isSuperAdmin && 
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="type-customer">Cliente</InputLabel>
                                        <Select
                                            value={this.state.idCustomer}
                                            onChange={(event) => { this.setState({ idCustomer: event.target.value }) }}
                                            inputProps={{ id: 'type-customer' }}
                                        >
                                            {this.state.customers.map((customer, index) => (
                                                <MenuItem value={customer.idCustomer} key={index}>{customer.idCustomer} - {customer.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </ FormControl>                                
                                </Grid> 
                            }   
                            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="type-profile">Perfil</InputLabel>
                                    <Select
                                        value={this.state.profile}
                                        onChange={(event) => { this.setState({ profile: event.target.value }) }}
                                        inputProps={{ id: 'type-profile' }}
                                    >
                                        {listTypeProfile.map((type, index) => (
                                            <MenuItem value={type.idType} key={index}>{type.dsType}</MenuItem>
                                        ))}
                                    </Select>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.inactive}
                                            onChange={(event) => this.setState({ inactive: event.target.checked })}
                                        />
                                    }
                                    label="Inativo"
                                    value="top"
                                    labelPlacement="top"
                                    disabled={this.props.userEdit === null}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.saveUser} color="primary" variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterUser.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterUser));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

import DateTimeTextField from '../../utils/DateTimeTextField';
import arithmeticianPaymentValueApi from '../../../api/consumer/arithmeticianPaymentValue';

import { formatMoney } from '../../utils/NumberFormat';

export class RegisterArithmeticianPaymentValue extends Component {

    state = {
        validityDateBegin: '',
        validityDateEnd: '',
        value: '',
        arithmeticianPaymentValues: [],
        queryDate: null,
        loading: false,
        saving: false
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.arithmeticianPaymentValue) {
                this.loadingValues(props.arithmeticianPaymentValue);
            } 
        }
    }

    loadingValues = async (arithmeticianPaymentValue) => {
        try {
            this.setState({
                arithmeticianPaymentValues: [],
                loading: true
            });
            const { idGoal, idArithmetician } = arithmeticianPaymentValue;
            const reponse = await arithmeticianPaymentValueApi('GET', { currentValues: false, idGoal, idArithmetician });     
            let arithmeticianPaymentValues = reponse.arithmeticianPaymentValues;
            arithmeticianPaymentValues.reverse();
            arithmeticianPaymentValues.map((arithmeticianPaymentValue2) => {
                if (new Date(arithmeticianPaymentValue2.validityDateEnd).getTime() > new Date()) {
                    arithmeticianPaymentValue2.isEditableBeginDate = false;
                    arithmeticianPaymentValue2.isEditableEndDate = true;
                    arithmeticianPaymentValue2.isEditableValue = false;
                } else {
                    arithmeticianPaymentValue2.isEditableBeginDate = false;
                    arithmeticianPaymentValue2.isEditableEndDate = false;
                    arithmeticianPaymentValue2.isEditableValue = false;
                }
                arithmeticianPaymentValue2.value = formatMoney(arithmeticianPaymentValue2.value);
                arithmeticianPaymentValue2.isNew = false;
                return arithmeticianPaymentValue2;
            });
            this.setState({
                arithmeticianPaymentValues,
                queryDate: reponse.queryDate,
                loading: false
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    saveArithmeticianPaymentValue = async () => {        

        const arithmeticianPaymentValues = this.state.arithmeticianPaymentValues;

        let arithmeticianPaymentValuesUPDATE = []        
        let validityDateBeginPrev = null;
        for (let i = 0; i < arithmeticianPaymentValues.length; i++) {
            let {validityDateBegin, validityDateEnd, value, isNew, isEditableEndDate} = arithmeticianPaymentValues[i];
            if (isNew || isEditableEndDate) {
                if (validityDateBegin === '') {
                    global.SnackBar.show('WARNING', `Item ${i + 1} : Data de início da vigência é obrigatória`);
                    return;
                }
                if (validityDateEnd === '') {
                    global.SnackBar.show('WARNING', `Item ${i + 1} : Data de fim da vigência é obrigatória`);
                    return;
                }
                if (value === '') {
                    global.SnackBar.show('WARNING', `Item ${i + 1} : Valor é obrigatório`);
                    return;
                }
                if (!(value >= 0)) {
                    global.SnackBar.show('WARNING', `Item ${i + 1} : Valor é inválido`);
                    return;
                }     
                validityDateBegin = new Date(validityDateBegin);
                validityDateEnd = new Date(validityDateEnd);
                if (validityDateBegin.getTime() > validityDateEnd.getTime()) {
                    global.SnackBar.show('WARNING', `Item ${i + 1} : Data início da vigência não pode ser menor que a data de fim de vigência`, 7000);
                    return;
                }
                if (validityDateBeginPrev) {                    
                    if (validityDateEnd.getTime() > validityDateBeginPrev.getTime()) {
                        global.SnackBar.show('WARNING', `Item ${i + 1} : Data início da vigência não pode ser menor que a data de fim da vigência anterior`, 7000);
                        return;
                    }
                }
                arithmeticianPaymentValues[i].value = parseFloat(formatMoney(arithmeticianPaymentValues[i].value));
                arithmeticianPaymentValuesUPDATE.push(arithmeticianPaymentValues[i]);                
                validityDateBeginPrev = validityDateBegin;
            }
        }  
        
        if (arithmeticianPaymentValuesUPDATE.length === 0) {
            global.SnackBar.show('WARNING', `Nada para gravar`);
            return;
        }  

        try {
            this.setState({                        
                saving: true
            });
            await arithmeticianPaymentValueApi('UPDATE', {
                arithmeticianPaymentValues: arithmeticianPaymentValuesUPDATE,
                queryDate: this.state.queryDate
            });
            this.setState({                        
                saving: false
            });
            this.loadingValues(this.props.arithmeticianPaymentValue);
            global.SnackBar.show('SUCCESS', 'Registro salvo');            
        } catch (error) {
            this.setState({                        
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    addArithmeticianPaymentValue = () => {
        const { arithmeticianPaymentValue } = this.props;
        let newArithmeticianPaymentValue = {
            idGoal: arithmeticianPaymentValue.idGoal,
            idArithmetician: arithmeticianPaymentValue.idArithmetician,
            Goal: arithmeticianPaymentValue.Goal,
            Arithmetician: arithmeticianPaymentValue.Arithmetician,
            validityDateBegin: '',
            validityDateEnd: '',
            value: '',            
            isEditableBeginDate: true,
            isEditableEndDate: true,
            isEditableValue: true,
            isNew: true
        };
        const array = this.state.arithmeticianPaymentValues;
        array.unshift(newArithmeticianPaymentValue);
        this.setState({
            arithmeticianPaymentValues: array 
        })
    }

    getValueDate = (date) => {
        if (date === undefined) {
            return '';
        }
        if (date === null) {
            return '';
        }
        if (date === '') {
            return '';
        }
        return new Date(date);
    }

    changeValue = (value, index, attribute) => {
        if (value === null) {
            value = '';
        }
        let array = this.state.arithmeticianPaymentValues;
        array[index][attribute] = value;        
        this.setState({
            arithmeticianPaymentValues: array
        })
    }

    render() {
        const { classes, fullScreen, arithmeticianPaymentValue } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">
                        Valor Pagamento por Finalidade
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={this.addArithmeticianPaymentValue} disabled={this.state.loading || this.state.saving}>
                            <AddIcon />
                        </Fab>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.loading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '1rem' }}>
                                <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                    Buscando...
                                </Typography>
                                <CircularProgress size={20} color="secondary" />
                            </div>
                        :
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Paper className={classes.paperHeader} elevation={1}>
                                        <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                            Finalidade: {arithmeticianPaymentValue ? arithmeticianPaymentValue.Goal.description : ''}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Paper className={classes.paperHeader} elevation={1}>
                                        <Typography variant="h5" color="inherit" style={{ fontSize: '1rem' }}>
                                            Calculista: {arithmeticianPaymentValue ? arithmeticianPaymentValue.Arithmetician.name : ''}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '1rem' }}>
                                    {this.state.arithmeticianPaymentValues.map((arithmeticianPaymentValue, index) => (
                                        <Grid container direction="row" spacing={16} key={index}>
                                            <Grid item xs={12} sm={12} md={1} lg={1} xl={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Typography variant="h5" color="inherit" style={{ fontSize: '0.85rem', color: 'rgba(0, 0, 0, 0.50)' }}>
                                                    #{index + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <DateTimeTextField
                                                    fullWidth
                                                    label="Início Vigência"                                                                                            
                                                    value={this.getValueDate(arithmeticianPaymentValue.validityDateBegin)}    
                                                    onChange={(date) => this.changeValue(date, index, 'validityDateBegin')}
                                                    disabled={!arithmeticianPaymentValue.isEditableBeginDate}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <DateTimeTextField
                                                    fullWidth
                                                    label="Fim Vigência"                                                                                                
                                                    value={this.getValueDate(arithmeticianPaymentValue.validityDateEnd)}                                            
                                                    onChange={(date) => this.changeValue(date, index, 'validityDateEnd')}
                                                    disabled={!arithmeticianPaymentValue.isEditableEndDate}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                <FormControl fullWidth disabled={!arithmeticianPaymentValue.isEditableValue}>
                                                    <InputLabel htmlFor="goal-pay-value">Valor</InputLabel>
                                                    <Input
                                                        id="goal-pay-value"
                                                        type="number"
                                                        value={arithmeticianPaymentValue.value}
                                                        onBlur={() => {
                                                            this.changeValue(formatMoney(arithmeticianPaymentValue.value), index, 'value');
                                                        }}                                                    
                                                        onChange={(event) => this.changeValue(event.target.value, index, 'value')}
                                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Divider /></Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary" disabled={this.state.loading || this.state.saving}>
                            Cancelar
                        </Button>
                        <Button onClick={this.saveArithmeticianPaymentValue} color="primary" variant="contained" disabled={this.state.loading || this.state.saving}>
                            {this.state.saving && <CircularProgress size={20} color="secondary" style={{marginRight: '0.35rem'}} />}
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    },
    paperHeader: {
        padding: '1rem'
    }
}

RegisterArithmeticianPaymentValue.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterArithmeticianPaymentValue));
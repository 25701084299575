import api from '../api';

async function dynamicReport(action, param) {
    try {
        const response = await api.post('/dynamicReport', {
            action,
            payload: param
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code;
    this.message = message;
}

export default dynamicReport;
import axios from 'axios';
import Token from './Token';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000000
});

instance.interceptors.request.use(
    config => {
        config.headers.authorization = Token.getBearerToken();
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response === undefined) {
            return Promise.reject({ response: { status: 'Error', data: error.message } });
        } else {
            if (error.response.status === 401 || error.response.status === 403) {
                const path = window.location.pathname.split('/')[1];
                if (path === 'main' || path === 'report' || path === 'admin') {
                    Token.removeToken();
                    window.location.href = `/error/Acesso negado/${error.response.status} - ${error.response.data}`;
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;

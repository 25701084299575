
export const formatMoney = (number) => {
    if (number !== undefined) {
        if (number !== null) {
            if (number !== '') {
                return parseFloat(number).toFixed(2);
            }
        }
    }
    return '';
}

export const formatMoneySymbol = (number) => {
    if (number !== undefined) {
        if (number !== null) {
            if (number !== '') {
                return parseFloat(parseFloat(number).toFixed(2)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        }
    }
    return '';
}

export const setStateMoney = (context, field) => {
    if (context.state[field].toString() !== formatMoney(context.state[field]).toString()) {
        context.setState({
            [field]: formatMoney(context.state[field])
        });
    }
}

export const formatNumberProcess = (number) => {
    let valueFormat = '';
    for (let i = 0; i < number.length; i++) {
        let carac = number.substr(i, 1);
        if (isNumber(carac)) {
            valueFormat = `${valueFormat}${carac}`
        }
    }

    if (valueFormat.length === 17) { //Mascara antiga
        valueFormat = `${valueFormat.substring(0, 5)}-${valueFormat.substring(5, 9)}-${valueFormat.substring(9, 12)}-${valueFormat.substring(12, 14)}-${valueFormat.substring(14, 16)}-${valueFormat.substring(16, 17)}`;
    } else if (valueFormat.length === 20) { //Mascara nova
        valueFormat = `${valueFormat.substring(0, 7)}-${valueFormat.substring(7, 9)}.${valueFormat.substring(9, 13)}.${valueFormat.substring(13, 14)}.${valueFormat.substring(14, 16)}.${valueFormat.substring(16, 20)}`;
    }

    return valueFormat;
}

export const isValidNumberProcess = (number) => {
    if (number === null || number === undefined) {
        number = '';
    }
    let valueFormat = '';
    for (let i = 0; i < number.length; i++) {
        let carac = number.substr(i, 1);
        if (isNumber(carac)) {
            valueFormat = `${valueFormat}${carac}`
        }
    }

    if (valueFormat.length === 17) { //Mascara antiga
        return true;
    } else if (valueFormat.length === 20) { //Mascara nova
        return true;
    }

    return false;
}

export const formatSizeByte = (size) => {
    if (size < 1024) {
        return `${size} B`;
    } else if (size < 1048576) {
        size = parseInt(size / 1024);
        return `${size} KB`;
    } else if (size < 1073741824) {
        size = parseFloat(size / 1024 / 1024).toFixed(1);
        return `${size} MB`;
    } else if (size < 1099511627776) {
        size = parseFloat(size / 1024 / 1024 / 1024).toFixed(1);
        return `${size} GB`;
    } else if (size < 1125899906842624) {
        size = parseFloat(size / 1024 / 1024 / 1024 / 1024).toFixed(1);
        return `${size} TB`;
    } else {
        return 'INDEFINIDO';
    }
}

const isNumber = (value) => {
    switch (value) {
        case '0':
            return true;
        case '1':
            return true;
        case '2':
            return true;
        case '3':
            return true;
        case '4':
            return true;
        case '5':
            return true;
        case '6':
            return true;
        case '7':
            return true;
        case '8':
            return true;
        case '9':
            return true;
        default:
            return false;
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContentText from '@material-ui/core/DialogContentText';

export class RegisterCustomer extends Component {

    render() {

        if (!this.props.options) {
            return <div style={{ display: 'none' }} />
        }

        const { onClose, title, message, ok, cancel } = this.props.options;

        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={true}
                    onClose={onClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        {cancel &&
                            <Button onClick={onClose} color="primary" variant="contained">
                                Cancelar
                            </Button>
                        }
                        <Button onClick={ok !== undefined ? () => { ok(); onClose(); } : onClose} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterCustomer.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterCustomer));
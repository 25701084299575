import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { FaCloud, FaBoxOpen, FaMoneyCheckAlt, FaQrcode, FaInfoCircle, FaCheck, FaRegClock, FaTimes } from 'react-icons/fa';

import amountReceivablePerCustomerAPI from '../../../api/consumer/amountReceivablePerCustomer';
import systemInvoiceAPI from '../../../api/consumer/systemInvoice';
import Message from '../../utils/Message';
import Profile from '../../utils/Profile';
import RegisterSystemInvoice from './RegisterSystemInvoice';

import TableResponsive from '../../utils/TableResponsive';
import { formatMoneySymbol, formatSizeByte } from '../../utils/NumberFormat';
import { getTypeStatusSystemInvoice } from '../../../resources/types/typeSystemInvoice';
import { formatDateBRZ } from '../../utils/DataFormat';

export class CostInvoice extends Component {
    state = {
        amountReceivablePerCustomer: null,
        systemInvoices: [],
        systemInvoice: null,
        message: null,
        open: false,
        loadingCosts: true,
        loadingInvoice: true
    };

    componentWillMount() {
        this.loadingCosts();
        this.loadingInvoice();
    }

    loadingCosts = async () => {
        try {
            this.setState({
                loadingCosts: true
            });
            const response = await amountReceivablePerCustomerAPI('GET', {});
            const amountReceivablePerCustomer = response.amountReceivablePerCustomer;
            this.setState({
                amountReceivablePerCustomer,
                loadingCosts: false
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
            this.setState({
                loadingCosts: false
            });
        }
    };

    loadingInvoice = async () => {
        try {
            this.setState({
                loadingInvoice: true
            });
            const response = await systemInvoiceAPI('GET', {});
            const systemInvoices = response.systemInvoices;
            this.setState({
                loadingInvoice: false,
                systemInvoices
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
            this.setState({
                loadingInvoice: false
            });
        }
    };

    data = () => {
        var data = [];
        this.state.systemInvoices.forEach(systemInvoice => {
            let dateCell = {
                dueAt: this.formatDateDue(systemInvoice.dueAt),
                status: this.getTextStatus(systemInvoice),
                paymentAmount: this.getValueInvoice(systemInvoice),
                costs: systemInvoice.costs,
                action: systemInvoice.status === 'PEND' && !this.isExpiredInvoice(systemInvoice.dueAt) ? this.actions(systemInvoice) : null
            };
            if (Profile.isSuperAdmin(this.props.user.profile)) {
                dateCell.actionSuperAdmin = this.actionsSuperAdmin(systemInvoice);
            }
            data.push(dateCell);
        });
        return data;
    };

    columns = () => {
        let columns = [
            {
                id: 'dueAt',
                label: 'VENCIMENTO'
            },
            {
                id: 'status',
                label: 'SITUAÇÃO'
            },
            {
                id: 'paymentAmount',
                label: 'VALOR'
            },
            {
                id: 'action',
                label: 'QR-CODE'
            }
        ];
        if (Profile.isSuperAdmin(this.props.user.profile)) {
            columns.push({
                id: 'actionSuperAdmin',
                label: 'ADMIN'
            });
        }
        return columns;
    };

    isExpiredInvoice = dueAt => {
        const dayDueInvoice = parseInt(new Date(dueAt).getUTCDate());
        const monthDueInvoice = parseInt(new Date(dueAt).getUTCMonth());
        const yearDueInvoice = parseInt(new Date(dueAt).getUTCFullYear());
        const dayDate = parseInt(new Date().getDate());
        const monthDate = parseInt(new Date().getMonth());
        const yearDate = parseInt(new Date().getFullYear());
        const dateDueInvoice = new Date(yearDueInvoice, monthDueInvoice, dayDueInvoice);
        const dateActual = new Date(yearDate, monthDate, dayDate);
        return dateDueInvoice < dateActual;
    };

    formatDateDue = dueAt => {
        const dayDueInvoice = parseInt(new Date(dueAt).getUTCDate());
        const monthDueInvoice = parseInt(new Date(dueAt).getUTCMonth());
        const yearDueInvoice = parseInt(new Date(dueAt).getUTCFullYear());
        const dateDueInvoice = new Date(yearDueInvoice, monthDueInvoice, dayDueInvoice);
        return formatDateBRZ(dateDueInvoice);
    };

    getTextStatus = systemInvoice => {
        if (systemInvoice.status === 'PEND') {
            const color = this.isExpiredInvoice(systemInvoice.dueAt) ? '#CA0000' : '#0111A5';
            const textStatus = this.isExpiredInvoice(systemInvoice.dueAt)
                ? 'BOLETO VENCIDO - SOLICITE UM NOVO'
                : getTypeStatusSystemInvoice(systemInvoice.status).description;
            const icon = this.isExpiredInvoice(systemInvoice.dueAt) ? (
                <FaTimes style={{ marginLeft: '4px' }} />
            ) : (
                <FaRegClock style={{ marginLeft: '4px' }} />
            );
            return (
                <span style={{ color, fontWeight: 400 }}>
                    {textStatus} {icon}
                </span>
            );
        } else {
            return (
                <span style={{ color: '#007A49', fontWeight: 400 }}>
                    {getTypeStatusSystemInvoice(systemInvoice.status).description}
                    <FaCheck style={{ marginLeft: '4px' }} />
                </span>
            );
        }
    };

    getValueInvoice = systemInvoice => {
        return (
            <span style={{ fontWeight: 400 }}>
                {formatMoneySymbol(systemInvoice.paymentAmount)}
                <IconButton
                    onClick={() => {
                        this.setState({
                            message: {
                                onClose: () => this.setState({ message: null }),
                                title: 'Custos',
                                message: <span style={{ whiteSpace: 'pre-line' }}>{systemInvoice.costs}</span>
                            }
                        });
                    }}
                >
                    <FaInfoCircle fontSize={16} style={{ color: '#048FEC' }} />
                </IconButton>
            </span>
        );
    };

    actions = systemInvoice => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
                onClick={() => {
                    var image = new Image();
                    image.src = systemInvoice.urlDownload;
                    var w = window.open('');
                    w.document.write(image.outerHTML);
                    w.document.write(`<br /><br /><div style="font-weight: bold;">${systemInvoice.payloadPix || ''}</div>`);
                }}
            >
                <FaQrcode />
            </IconButton>
        </div>
    );

    actionsSuperAdmin = systemInvoice => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditSystemInvoice(systemInvoice)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(systemInvoice)}>
                <DeleteIcon />
            </IconButton>
        </div>
    );

    onEditSystemInvoice = systemInvoice => {
        this.openOrClose(systemInvoice);
    };

    showMessageDelete = (systemInvoice = null) => {
        this.setState({
            message: {
                onClose: () => this.setState({ message: null }),
                title: 'Exclusão de Fatura',
                message: <span>Deseja realmente excluir está fatura?</span>,
                ok: () => this.onDeleteSystemInvoice(systemInvoice),
                cancel: true
            }
        });
    };

    onDeleteSystemInvoice = async systemInvoice => {
        try {
            await systemInvoiceAPI('DELETE', {
                idSystemInvoice: systemInvoice.idSystemInvoice
            });
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingInvoice();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    };

    openOrClose = (systemInvoice = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            systemInvoice
        });
        if (!open) {
            this.loadingInvoice();
        }
    };

    render() {
        const { classes } = this.props;
        const { amountReceivablePerCustomer } = this.state;

        let licenseValue = 0;
        let description = '';
        let googleStorage = 0;
        let googleStorageSize = 0;
        let googleStorageValue = 0;
        let total = 0;

        if (amountReceivablePerCustomer) {
            licenseValue = amountReceivablePerCustomer.licenseValue;
            description = amountReceivablePerCustomer.description;
            googleStorage = amountReceivablePerCustomer.googleStorage;
            googleStorageSize = amountReceivablePerCustomer.googleStorageSize;
            googleStorageValue = amountReceivablePerCustomer.googleStorageValue;
            total = amountReceivablePerCustomer.total;
        }

        const isSuperAdmin = Profile.isSuperAdmin(this.props.user.profile);

        return (
            <Grid container className={classes.gridList} spacing={8}>
                <Grid item xs={12}>
                    <div>
                        <Paper className={classes.paper} elevation={1}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.9rem'
                                }}
                            >
                                <MoneyIcon style={{ marginRight: '0.6rem' }} />
                                <Typography variant="h5" color="inherit">
                                    Custos
                                </Typography>
                            </div>
                            <Grid
                                container
                                spacing={16}
                                style={{
                                    marginTop: '2rem',
                                    marginBottom: '0.8rem'
                                }}
                            >
                                {this.state.loadingCosts ? (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                color="inherit"
                                                style={{
                                                    textAlign: 'center',
                                                    fontFamily: 'Arial',
                                                    fontSize: '1.05rem',
                                                    color: 'rgba(0, 0, 0, 0.65)',
                                                    marginRight: '0.6rem'
                                                }}
                                            >
                                                Buscando...
                                            </Typography>
                                            <CircularProgress size={20} color="secondary" />
                                        </div>
                                    </Grid>
                                ) : (
                                    <Fragment>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid container spacing={16} direction="row">
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <Card
                                                        style={{
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginBottom: '0.9rem'
                                                                }}
                                                            >
                                                                <FaCloud
                                                                    fontSize={28}
                                                                    style={{
                                                                        marginRight: '0.5rem'
                                                                    }}
                                                                />
                                                                <Typography
                                                                    style={{
                                                                        fontSize: '1.3rem'
                                                                    }}
                                                                >
                                                                    Serviço
                                                                </Typography>
                                                            </div>
                                                            <Grid container spacing={16} direction="row">
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Typography color="textSecondary">{description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Typography variant="h4" component="h2" align="center">
                                                                        {formatMoneySymbol(licenseValue)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <Card
                                                        style={{
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginBottom: '0.9rem'
                                                                }}
                                                            >
                                                                <FaBoxOpen
                                                                    fontSize={28}
                                                                    style={{
                                                                        marginRight: '0.5rem'
                                                                    }}
                                                                />
                                                                <Typography
                                                                    style={{
                                                                        fontSize: '1.3rem'
                                                                    }}
                                                                >
                                                                    Armazenamento
                                                                </Typography>
                                                            </div>
                                                            <Grid container spacing={16} direction="row">
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Typography color="textSecondary">
                                                                        Valor Base: {formatMoneySymbol(googleStorage)} GB
                                                                    </Typography>
                                                                    <Typography color="textSecondary">
                                                                        Utilizado: {formatSizeByte(googleStorageSize)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Typography variant="h4" component="h2" align="center">
                                                                        {formatMoneySymbol(googleStorageValue)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <Card
                                                        style={{
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginBottom: '0.9rem'
                                                                }}
                                                            >
                                                                <FaMoneyCheckAlt
                                                                    fontSize={24}
                                                                    style={{
                                                                        marginRight: '0.5rem'
                                                                    }}
                                                                />
                                                                <Typography
                                                                    style={{
                                                                        fontSize: '1.3rem'
                                                                    }}
                                                                >
                                                                    Custo Total
                                                                </Typography>
                                                            </div>
                                                            <Grid container spacing={16} direction="row">
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <Typography
                                                                        variant="h4"
                                                                        component="h2"
                                                                        align="center"
                                                                        style={{
                                                                            fontSize: '2.5rem'
                                                                        }}
                                                                    >
                                                                        {formatMoneySymbol(total)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Paper className={classes.paper} elevation={1}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.9rem'
                                }}
                            >
                                <MoneyIcon style={{ marginRight: '0.6rem' }} />
                                <Typography variant="h5" color="inherit">
                                    Faturas
                                </Typography>
                                {isSuperAdmin && (
                                    <Fab
                                        size="small"
                                        color="secondary"
                                        aria-label="Add"
                                        style={{ marginLeft: '1rem' }}
                                        onClick={() => this.openOrClose()}
                                    >
                                        <AddIcon />
                                    </Fab>
                                )}
                            </div>
                            <Grid
                                container
                                spacing={16}
                                style={{
                                    marginTop: '2rem',
                                    marginBottom: '0.8rem'
                                }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TableResponsive
                                        columns={this.columns()}
                                        values={this.data(classes)}
                                        showTotalRecords
                                        loadingInvoice={this.state.loadingInvoice}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                <Message options={this.state.message} />
                <RegisterSystemInvoice
                    open={this.state.open}
                    openOrClose={this.openOrClose}
                    systemInvoice={this.state.systemInvoice}
                    amountReceivablePerCustomer={this.state.amountReceivablePerCustomer}
                />
            </Grid>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1
    },
    gridList: {
        padding: '0.5rem'
    },
    gridLists: {
        marginBottom: '1rem'
    },
    paper: {
        padding: '1rem'
    }
};

CostInvoice.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CostInvoice);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

export class Error extends Component {
    render() {        
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h3" component="h3" className={classes.fontHeader}>
                    {this.props.match.params.title || 'Erro'}
                </Typography>
                <Typography variant="h6" component="h6" className={classes.fontHeader}>
                    {this.props.match.params.message || 'Um erro inesperado ocorreu, tente novamente ou contate o suporte.'}
                </Typography>
                <Button variant="contained" size="small" color="primary">
                    <Link to="/" className={classes.fontWhite}>Início</Link>
                </Button>
            </div>
        )
    }
};

const styles = {
    root: {
        padding: '25px'
    },
    fontHeader: {
        color: '#666',
        marginBottom: '20px',
    },
    fontWhite: {
        color: '#FFF',
        textDecoration: 'none'
    }
}

Error.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Error);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import UserIcon from '@material-ui/icons/Person';

import RegisterUser from './RegisterUser';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';

import { getUser, deleteUser } from '../../../api/consumer/user';
import { formatDateTimeBRZ } from '../../utils/DataFormat';
import { getDsTypeProfile } from '../../../resources/types/typeProfile'

export class ListUser extends Component {

    state = {
        open: false,
        users: [],
        user: null,
        messageDelete: null
    }

    componentWillMount() {
        this.loadingUsers();
    }

    loadingUsers = async () => {

        const { isSuperAdmin } = this.props;

        try {
            const response = await getUser(isSuperAdmin);            
            this.setState({
                users: response.users
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    columns = () => {

        const { isSuperAdmin } = this.props;
    
        var columns = [];
        if (isSuperAdmin) {
            columns.push({id: 'idUser', label: 'ID'});
        }
        columns.push({id: 'name', label: 'NOME'});
        columns.push({id: 'email', label: 'E-MAIL'});
        columns.push({id: 'createdAt', label: 'DATA CRIAÇÃO'});
        columns.push({id: 'updatedAt', label: 'DATA ALTERAÇÃO'});
        columns.push({id: 'status', label: 'STATUS'});
        columns.push({id: 'verified', label: 'VERIFICADO'});
        columns.push({id: 'profile', label: 'PERFIL'});        
        if (isSuperAdmin) {
            columns.push({id: 'dtBlockAttempt', label: 'DATA BLOQUEIO'});
            columns.push({id: 'customer', label: 'CLIENTE'});
        }    
        columns.push({id: 'action', label: ''});
    
        return columns;
    }

    data = (classes) => {

        const { isSuperAdmin } = this.props;

        var data = [];
        this.state.users.forEach(user => {
            let dataCell = {                
                name: user.inactive ? <span style={{textDecoration: 'line-through'}}>{user.name}</span> : user.name,
                email: user.email,
                createdAt: formatDateTimeBRZ(new Date(user.createdAt)),
                updatedAt: formatDateTimeBRZ(user.updatedAt ? new Date(user.updatedAt) : null),
                status: user.inactive ? 'Inativo' : 'Ativo',
                verified: user.verified ? 'Sim' : 'Não',
                profile: getDsTypeProfile(user.profile).dsType,
                dtBlockAttempt: formatDateTimeBRZ(user.dtBlockAttempt ? new Date(user.dtBlockAttempt) : null),                
                action: this.actions(user)
            }
            if (isSuperAdmin) {
                dataCell = {...dataCell, customer: user.Customer.idCustomer + ' - ' + user.Customer.name, idUser: user.idUser}
            }
            data.push(dataCell);
        });
        return data;
    }

    actions = (user) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditUser(user)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(user)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (user = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            user,
        });
        if (!open) {
            this.loadingUsers();
        }
    }

    showMessageDelete = (user = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Usuário',
                message: <span>Deseja realmente excluir o usuário <b>“{user.idUser}: {user.name}” ?</b></span>,
                ok: () => this.onDeleteUser(user),
                cancel: true
            }
        });
    }

    onEditUser = (user) => {
        this.openOrClose(user);
    }

    onDeleteUser = async (user) => {
        try {
            await deleteUser(user.idUser);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingUsers();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes, user } = this.props;
        const { isSuperAdmin } = this.props;        
        return (
            <div>
                <Paper className={classes.paperListUser} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <UserIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Usuários
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={this.columns()}
                        values={this.data(classes)}
                        showTotalRecords
                    />
                </Paper>
                <RegisterUser open={this.state.open} openOrClose={this.openOrClose} userEdit={this.state.user} user={user} isSuperAdmin={isSuperAdmin}/>                
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const styles = {
    paperListUser: {
        padding: '1rem'
    }
}

ListUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListUser);

import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Main from '../components/business/Main';
import Admin from '../components/admin/Admin';
import Login from '../components/auth/Login';
import RecoverSend from '../components/auth/RecoverSend';
import RecoverPassword from '../components/auth/RecoverPassword';
import VerifyEmail from '../components/auth/VerifyEmail';
import Error from '../components/error/Error';
import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import Report from '../components/business/Report/Report';
import TableDynamicReport from '../components/business/TableDynamicReport/TableDynamicReport';

import Snackbar from '../components/utils/SnackBar';
export class Routes extends React.Component {

    render() {
        return (
            <main className="container">
                <BrowserRouter>
                    <Switch>
                        <Route exact path='/recover/send' component={RecoverSend} />
                        <Route exact path='/recover/password/:token' component={RecoverPassword} />
                        <Route exact path='/verify/email/:email/:token' component={VerifyEmail} />
                        <Route path='/error/:title/:message' component={Error} />                                      
                        <PrivateRoute exact path='/admin' component={Admin} />                                                                       
                        <LoginRoute exact path='/login' component={Login} />
                        <PrivateRoute path='/main' component={Main} />           
                        <PrivateRoute path='/report/:reportId' component={Report} />                       
                        <PrivateRoute path='/dynamic-report/:dynamicReportId' component={TableDynamicReport} />
                        <Redirect from='*' to='/main' />                        
                    </Switch>
                </BrowserRouter>
                <Snackbar ref={instance => { global.SnackBar = instance }} />
            </main>
        )
    }
};

export default Routes;

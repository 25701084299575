import api from '../api';

export async function getLaborCourt() {
    try {
        const response = await api.get('/laborcourt');
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function saveLaborCourt(laborCourt) {
    try {
        let response;
        if (laborCourt.idLaborCourt === null) {
            response = await api.post('/laborcourt', laborCourt);
        } else {
            response = await api.put(`/laborcourt/${laborCourt.idLaborCourt}`, laborCourt);
        }
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function deleteLaborCourt(idLaborCourt) {
    try {
        const response = await api.delete(`/laborcourt/${idLaborCourt}`);
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

function Exception(code, message) {
    this.code = code
    this.message = message
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import DateTextField from '../../utils/DateTextField';

import systemInvoiceAPI from '../../../api/consumer/systemInvoice';

import { setStateMoney, formatMoney } from '../../utils/NumberFormat';
import { typeStatusSystemInvoice } from '../../../resources/types/typeSystemInvoice';
import { formatMoneySymbol, formatSizeByte } from '../../utils/NumberFormat';

export class RegisterSystemInvoice extends Component {
    initialState = {
        dueAt: '',
        status: 'PEND',
        paymentAmount: '',
        costs: '',
        saving: false
    };

    state = {
        ...this.initialState
    };

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.systemInvoice) {
                this.setState({
                    dueAt: this.formatDateDue(props.systemInvoice.dueAt),
                    status: props.systemInvoice.status,
                    paymentAmount: formatMoney(props.systemInvoice.paymentAmount),
                    costs: props.systemInvoice.costs
                });
            } else {
                this.setState({
                    dueAt: '',
                    status: 'PEND',
                    paymentAmount: this.getPaymentAmount(this.props.amountReceivablePerCustomer),
                    costs: this.getDescriptionCosts(this.props.amountReceivablePerCustomer),
                    saving: false
                });
            }
        }
    }

    formatDateDue = dueAt => {
        const dayDueInvoice = parseInt(new Date(dueAt).getUTCDate());
        const monthDueInvoice = parseInt(new Date(dueAt).getUTCMonth());
        const yearDueInvoice = parseInt(new Date(dueAt).getUTCFullYear());
        return new Date(yearDueInvoice, monthDueInvoice, dayDueInvoice);
    };

    getPaymentAmount = amountReceivablePerCustomer => {
        if (!amountReceivablePerCustomer) {
            return '';
        }
        return formatMoney(amountReceivablePerCustomer.total);
    };

    getDescriptionCosts = amountReceivablePerCustomer => {
        if (!amountReceivablePerCustomer) {
            return '';
        }

        let description = 'Serviço:';
        description = `${description}\n - Valor Licença: ${formatMoneySymbol(amountReceivablePerCustomer.licenseValue)}`;
        description = `${description}\n\nArmazenamento:`;
        description = `${description}\n - Valor Base: ${formatMoneySymbol(amountReceivablePerCustomer.googleStorage)}`;
        description = `${description}\n - Utilizado: ${formatSizeByte(amountReceivablePerCustomer.googleStorageSize)}`;
        description = `${description}\n - Valor Total: ${formatMoneySymbol(amountReceivablePerCustomer.googleStorageValue)}`;
        description = `${description}\n\nTotal Fatura: ${formatMoneySymbol(amountReceivablePerCustomer.total)}`;

        return description;
    };

    saveSystemInvoice = async () => {
        if (this.state.dueAt === '') {
            global.SnackBar.show('WARNING', 'Informe a data de vencimento');
            return;
        }

        if (this.state.status === '') {
            global.SnackBar.show('WARNING', 'Informe a situação da fatura');
            return;
        }

        if (this.state.paymentAmount === '') {
            global.SnackBar.show('WARNING', 'Informe o valor da fatura');
            return;
        }

        try {
            this.setState({
                saving: true
            });

            let systemInvoice = {
                idSystemInvoice: this.props.systemInvoice ? this.props.systemInvoice.idSystemInvoice : null,
                dueAt: this.state.dueAt,
                status: this.state.status,
                paymentAmount: formatMoney(this.state.paymentAmount),
                costs: this.state.costs
            };

            await systemInvoiceAPI('UPLOAD', { ...systemInvoice });

            global.SnackBar.show('SUCCESS', 'Registro salvo');
            this.setState({
                saving: false
            });
            this.props.openOrClose();
        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.state.saving ? () => {} : this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">Fatura</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-status">Situação</InputLabel>
                                    <Select
                                        value={this.state.status}
                                        onChange={event =>
                                            this.setState({
                                                status: event.target.value
                                            })
                                        }
                                        inputProps={{ id: 'select-status' }}
                                    >
                                        {typeStatusSystemInvoice.map((status, index) => (
                                            <MenuItem value={status.id} key={index}>
                                                {status.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DateTextField
                                    fullWidth
                                    label="Data Vencimento"
                                    value={this.state.dueAt}
                                    onChange={date => this.setState({ dueAt: date })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="money-invoice-value">Valor Fatura</InputLabel>
                                    <Input
                                        id="money-invoice-value"
                                        type="number"
                                        value={this.state.paymentAmount}
                                        onBlur={() => setStateMoney(this, 'paymentAmount')}
                                        onChange={event => {
                                            this.setState({
                                                paymentAmount: event.target.value
                                            });
                                        }}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label="Custos"
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                    value={this.state.costs}
                                    onChange={e => this.setState({ costs: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary" disabled={this.state.saving}>
                            Cancelar
                        </Button>
                        <Button onClick={this.saveSystemInvoice} color="primary" variant="contained" disabled={this.state.saving}>
                            {this.state.saving ? 'Salvando...' : 'Salvar'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const styles = {
    root: {
        flexGrow: 1
    }
};

RegisterSystemInvoice.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterSystemInvoice));

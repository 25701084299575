import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Store from '@material-ui/icons/StoreRounded';
import AvatarEditor from 'react-avatar-editor';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import ImageSearch from '@material-ui/icons/Image';
import Clear from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

import MaskTextField from '../../genericTextField/MaskTextField';
import DateTextField from '../../utils/DateTextField';

import personAPI from '../../../api/consumer/person';

export class Company extends Component {

    state = {
        person: null,
        typePerson: 'F',
        registrationNumber: '',
        socialReason: '',
        additionalDescription: '',
        birthDate: '',
        telephone: '',
        cellPhone: '',
        email: '',
        cep: '',
        city: '',
        province: '',
        street: '',
        numberResidence: '',
        district: '',
        complement: '',
        image: null,
        scaleZoomLogo: 1,
        loading: false,
        saving: false,
        typePersons: [{ id: 'F', description: 'Física' }, { id: 'J', description: 'Jurídica' }]
    }

    setEditorRef = (editor) => this.editor = editor

    componentWillMount() {
        this.loadingPerson();
    }

    loadingPerson = async () => {
        try {
            this.setState({
                loading: true
            });
            const response = await personAPI('GET', { imageLogo: true });
            const person = response.person;
            if (person) {
                const { logo, ...params } = person;
                this.setState({
                    person,
                    ...params,
                    image: person.logo
                });
            }
            this.setState({
                loading: false
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
            this.setState({
                loading: false
            });
        }
    }

    modifyZoomLogo = (typeScale) => {
        if (typeScale === 'up') {
            if (this.state.scaleZoomLogo < 2) {
                this.setState({ scaleZoomLogo: this.state.scaleZoomLogo + 0.05 });
            }
        } else {
            if (this.state.scaleZoomLogo > 0.3) {
                this.setState({ scaleZoomLogo: this.state.scaleZoomLogo - 0.05 });
            }
        }
    };

    getCEPInformation = async () => {
        try {
            let { cep, city, province, street, district } = this.state;
            if (cep) {
                if (!city || !province || !street || !district) {
                    cep = cep.replace('.', '');
                    cep = cep.replace('-', '');
                    cep = cep.replace('_', '');
                    if (cep.length === 8) {
                        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
                        const infoCep = response.data;
                        if (infoCep.erro) {
                            global.SnackBar.show('WARNING', 'CEP não existe', 4000);
                            return;
                        }
                        let param = {};
                        if (!city) {
                            param.city = infoCep.localidade;
                        }
                        if (!province) {
                            param.province = infoCep.uf;
                        }
                        if (!street) {
                            param.street = infoCep.logradouro;
                        }
                        if (!district) {
                            param.district = infoCep.bairro;
                        }
                        this.setState({
                            ...param
                        });
                    }
                }
            }
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    setImageUpload = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0] !== null) {
                this.setState({
                    image: e.target.files[0]
                });
            }
        }
    }

    setImagemRemove = () => {
        this.setState({
            image: null
        });
    }

    savePerson = async () => {
        try {
            const { person, image } = this.state;
            let logoBase64 = null;
            if (this.editor) {
                if (image) {
                    logoBase64 = this.editor.getImageScaledToCanvas().toDataURL();
                }
            }
            this.setState({
                saving: true
            });
            let personParam = {
                idPerson: person ? person.idPerson : undefined,
                typePerson: this.state.typePerson,
                registrationNumber: this.state.registrationNumber,
                socialReason: this.state.socialReason,
                additionalDescription: this.state.additionalDescription,
                birthDate: this.state.birthDate,
                telephone: this.state.telephone,
                cellPhone: this.state.cellPhone,
                email: this.state.email,
                cep: this.state.cep,
                city: this.state.city,
                province: this.state.province,
                street: this.state.street,
                numberResidence: this.state.numberResidence,
                district: this.state.district,
                complement: this.state.complement,
                logo: logoBase64
            }
            if (person) {
                await personAPI('UPDATE', personParam);
            } else {
                await personAPI('CREATE', personParam);
            }
            this.setState({
                saving: false,
                scaleZoomLogo: 1
            });
            this.loadingPerson();
            global.SnackBar.show('SUCCESS', 'Cadastro atualizado com sucesso!');
        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;

        let maskRegistrationNumber;
        let labelRegistrationNumber;
        let labelSocialReason;
        if (this.state.typePerson === 'F') {
            labelRegistrationNumber = 'CPF';
            maskRegistrationNumber = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
            labelSocialReason = 'Nome';
        } else {
            labelRegistrationNumber = 'CNPJ';
            maskRegistrationNumber = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
            labelSocialReason = 'Razão Social';
        }

        return (
            <Grid container className={classes.gridList} spacing={8}>
                <Grid item xs={12}>
                    <div>
                        <Paper className={classes.paper} elevation={1}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                                <Store style={{ marginRight: '0.6rem' }} />
                                <Typography variant="h5" color="inherit">
                                    Empresa
                                </Typography>
                            </div>
                            <Grid container spacing={16} style={{ marginTop: '2rem', marginBottom: '0.8rem' }}>
                                {this.state.loading ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                                Buscando...
                                            </Typography>
                                            <CircularProgress size={20} color="secondary" />
                                        </div>
                                    </Grid>
                                    :
                                    <Fragment>
                                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                            <Grid container spacing={16} direction="column">
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <AvatarEditor
                                                        image={this.state.image}
                                                        width={200}
                                                        height={200}
                                                        border={5}
                                                        color={[200, 200, 200]}
                                                        scale={this.state.scaleZoomLogo}
                                                        rotate={0}
                                                        ref={this.setEditorRef}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <Grid container spacing={16} direction="column">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            {this.state.image &&
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <IconButton onClick={() => this.modifyZoomLogo('up')}>
                                                                        <ZoomIn />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => this.modifyZoomLogo('down')}>
                                                                        <ZoomOut />
                                                                    </IconButton>
                                                                </div>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Grid container spacing={0} direction="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: this.state.image ? 'flex-end' : 'center' }}>
                                                                    <input
                                                                        type="file"
                                                                        onChange={this.setImageUpload}
                                                                        ref={instance => { this.imageAvatar = instance }}
                                                                        style={{ display: 'none' }}
                                                                        accept="image/png, image/jpeg"
                                                                    />
                                                                    <Button color="secondary" size="small" onClick={() => {
                                                                        this.imageAvatar.click();
                                                                    }}>
                                                                        <ImageSearch style={{ marginRight: '0.3rem' }} />
                                                                        <span style={{ fontSize: '0.7rem' }}>Carregar</span>
                                                                    </Button>
                                                                </Grid>
                                                                {this.state.image &&
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>                                                                    
                                                                        <Button color="secondary" size="small" onClick={this.setImagemRemove}>
                                                                            <Clear style={{ marginRight: '0.3rem' }} />
                                                                            <span style={{ fontSize: '0.7rem' }}>Remover</span>
                                                                        </Button>                                                                    
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                                            <Grid container direction="row" spacing={16}>
                                                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="select-type-person">Pessoa</InputLabel>
                                                        <Select
                                                            value={this.state.typePerson}
                                                            onChange={(e) => this.setState({ typePerson: e.target.value })}
                                                            inputProps={{ id: 'select-type-person' }}
                                                            autoFocus
                                                        >
                                                            {this.state.typePersons.map((typePerson, index) => (
                                                                <MenuItem value={typePerson.id} key={index}>{typePerson.description}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </ FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                                                    <MaskTextField
                                                        fullWidth
                                                        label={labelRegistrationNumber}
                                                        value={this.state.registrationNumber}
                                                        onChange={(e) => this.setState({ registrationNumber: e.target.value })}
                                                        mask={maskRegistrationNumber}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-social-reason">{labelSocialReason}</InputLabel>
                                                        <Input
                                                            value={this.state.socialReason}
                                                            onChange={(event) => { this.setState({ socialReason: event.target.value }) }}
                                                            id="input-social-reason"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-additional-description">Descrição Adicional</InputLabel>
                                                        <Input
                                                            value={this.state.additionalDescription}
                                                            onChange={(event) => { this.setState({ additionalDescription: event.target.value }) }}
                                                            id="input-additional-description"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                                                    <DateTextField
                                                        fullWidth
                                                        label="Data de Nascimento"
                                                        value={this.state.birthDate}
                                                        onChange={date => this.setState({ birthDate: date })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                                                    <MaskTextField
                                                        fullWidth
                                                        label="Telefone"
                                                        value={this.state.telephone}
                                                        onChange={(e) => this.setState({ telephone: e.target.value })}
                                                        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                                                    <MaskTextField
                                                        fullWidth
                                                        label="Celular"
                                                        value={this.state.cellPhone}
                                                        onChange={(e) => this.setState({ cellPhone: e.target.value })}
                                                        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-email">E-mail</InputLabel>
                                                        <Input
                                                            value={this.state.email}
                                                            onChange={(event) => { this.setState({ email: event.target.value }) }}
                                                            id="input-email"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={1} xl={1}>
                                                    <MaskTextField
                                                        fullWidth
                                                        label="CEP"
                                                        value={this.state.cep}
                                                        onChange={(e) => this.setState({ cep: e.target.value })}
                                                        mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                        onBlur={this.getCEPInformation}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-city">Cidade</InputLabel>
                                                        <Input
                                                            value={this.state.city}
                                                            onChange={(event) => { this.setState({ city: event.target.value }) }}
                                                            id="input-city"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-province">UF</InputLabel>
                                                        <Input
                                                            value={this.state.province}
                                                            onChange={(event) => { this.setState({ province: event.target.value }) }}
                                                            id="input-province"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-street">Logradouro</InputLabel>
                                                        <Input
                                                            value={this.state.street}
                                                            onChange={(event) => { this.setState({ street: event.target.value }) }}
                                                            id="input-street"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-number-residence">Nª</InputLabel>
                                                        <Input
                                                            value={this.state.numberResidence}
                                                            onChange={(event) => { this.setState({ numberResidence: event.target.value }) }}
                                                            id="input-number-residence"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-district">Bairro</InputLabel>
                                                        <Input
                                                            value={this.state.district}
                                                            onChange={(event) => { this.setState({ district: event.target.value }) }}
                                                            id="input-district"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="input-complement">Complemento</InputLabel>
                                                        <Input
                                                            value={this.state.complement}
                                                            onChange={(event) => { this.setState({ complement: event.target.value }) }}
                                                            id="input-complement"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button onClick={this.savePerson} color="primary" variant="contained" style={{ marginRight: '1.5rem' }} disabled={this.state.saving}>
                                                {this.state.saving ? 'Salvando...' : 'Salvar'}
                                            </Button>
                                        </Grid>
                                    </Fragment>
                                }
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    },
    gridList: {
        padding: '0.5rem'
    },
    gridLists: {
        marginBottom: '1rem'
    },
    paper: {
        padding: '1rem'
    }
}

Company.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Company);
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import DefineColumns from './DefineColumns';
import DefineFilters from './DefineFilters';
import DefineGroups from './DefineGroups';
import dynamicReportApi from '../../../../api/consumer/dynamicReport';

import { typeViewDynamicReport } from '../../../../resources/types/typeViewDynamicReport';

export class RegisterDynamicReport extends Component {

    state = {
        loading: true,
        saving: false,
        name: '',
        view: '',
        selectedColumns: [],
        definedFilters: [],
        definedGroups: [],
        parameters: {
            fields: []
        }
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.dynamicReport === null) {
                this.setState({
                    loading: true,
                    saving: false,
                    name: '',
                    view: '',
                    selectedColumns: [],
                    definedFilters: [],
                    definedGroups: [],
                    parameters: {
                        fields: []
                    }
                });
            }
            this.loadingParameter(props.dynamicReport);
        }
    }

    loadingParameter = async (dynamicReport) => {
        try {

            if (this.state.view === '' && dynamicReport === null) {
                return;
            }            

            this.setState({
                loading: true
            });

            let view;
            if (dynamicReport) {
                view = dynamicReport.parameters.view;
            } else {
                view = this.state.view;
            }

            const response = await dynamicReportApi('GET_ATTRIBUTES', { reference: view });             
            let parameters = {
                fields: response[view]
            }

            let params = {};
            if (dynamicReport) {
                params.name = dynamicReport.name;
                params.view = dynamicReport.parameters.view;
                params.selectedColumns = dynamicReport.parameters.columns;
                params.definedFilters = dynamicReport.parameters.filters;
                params.definedGroups = dynamicReport.parameters.groups;

                for (let i = 0; i < params.selectedColumns.length; i++) {
                    let objColumn = parameters.fields.find(field => field.displayField === params.selectedColumns[i].displayField);
                    addProperties(objColumn, params.selectedColumns[i]);
                }

                for (let i = 0; i < params.definedFilters.length; i++) {
                    let objColumn = parameters.fields.find(field => field.displayField === params.definedFilters[i].displayField);
                    addProperties(objColumn, params.definedFilters[i]);
                }

                for (let i = 0; i < params.definedGroups.length; i++) {
                    let objColumn = parameters.fields.find(field => field.displayField === params.definedGroups[i].displayField);
                    addProperties(objColumn, params.definedGroups[i]);
                }
            }
            this.setState({
                ...params,
                parameters,
                loading: false,
            });
        } catch (error) {
            this.setState({
                processes: [],
                loading: false
            });            
            global.SnackBar.show('ERROR', error.message);
        }
    }

    saveDynamicReport = async () => {

        try {

            let { name, view, selectedColumns, definedFilters, definedGroups } = this.state;

            if (name === '') {
                global.SnackBar.show('WARNING', 'Informe o nome do relatório dinâmico');
                return;
            }

            if (view === '') {
                global.SnackBar.show('WARNING', 'Informe a visão do relatório dinâmico');
                return;
            }

            if (selectedColumns.length === 0) {
                global.SnackBar.show('WARNING', 'Informe pelo menos uma coluna');
                return;
            }

            for (let filter of definedFilters) {
                if (filter.valueFilter === '' && (filter.condition !== 'is null' && filter.condition !== 'is not null')) {
                    global.SnackBar.show('WARNING', `Informe o valor para o filtro da coluna "${filter.label}"`);
                    return;
                }
            }

            for (let i = 0; i < definedGroups.length; i++) {
                if (definedGroups[i].order === '') {
                    global.SnackBar.show('WARNING', `Informe a ordenação para o grupo da coluna "${definedGroups[i].label}"`);
                    return;
                }
                if (i >= selectedColumns.length) {
                    global.SnackBar.show('WARNING', 'Colunas para agrupamento devem estar selecionadas para exibição');
                    return;
                }
                if (definedGroups[i].displayField !== selectedColumns[i].displayField) {
                    global.SnackBar.show('WARNING', 'Colunas e grupos não seguem a mesma ordem de exibição');
                    return;
                }
            }

            this.setState({
                saving: true
            });

            selectedColumns = clone(selectedColumns);
            definedFilters = clone(definedFilters);
            definedGroups = clone(definedGroups);

            //limpar os valores inuteis para o json
            for (let i = 0; i < selectedColumns.length; i++) {
                for (let attr in selectedColumns[i]) {
                    if (attr !== 'displayField') {
                        selectedColumns[i][attr] = undefined;
                    }
                }
            }
            for (let i = 0; i < definedFilters.length; i++) {
                for (let attr in definedFilters[i]) {
                    if (attr !== 'displayField' && attr !== 'filterField' && attr !== 'condition' && attr !== 'valueFilter' && attr !== 'description' && attr !== 'label') {
                        definedFilters[i][attr] = undefined;
                    }
                }
            }
            for (let i = 0; i < definedGroups.length; i++) {
                for (let attr in definedGroups[i]) {
                    if (attr !== 'displayField' && attr !== 'order') {
                        definedGroups[i][attr] = undefined;
                    }
                }
            }

            let dynamicReport = {
                name,
                parameters: {
                    view,
                    columns: selectedColumns,
                    filters: definedFilters,
                    groups: definedGroups,
                }
            }
            if (this.props.dynamicReport) {
                dynamicReport.idDynamicReport = this.props.dynamicReport.idDynamicReport;
            }
            await dynamicReportApi(!dynamicReport.idDynamicReport ? 'CREATE' : 'UPDATE', { ...dynamicReport });
            this.setState({
                saving: false
            });
            global.SnackBar.show('SUCCESS', 'Registro salvo');
            this.props.openOrClose();
        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    setSelectedColumns = (selectedColumns) => {
        this.setState({
            selectedColumns
        });
    }

    setDefinedFilters = (definedFilters) => {
        this.setState({
            definedFilters
        });
    }

    setDefinedGroups = (definedGroups) => {
        this.setState({
            definedGroups
        });
    }

    onChangeView = (view) => {
        this.setState({
            view,
            selectedColumns: [],
            definedFilters: [],
            definedGroups: [],
            parameters: {
                fields: []
            }
        }, () => {
            this.loadingParameter(null);
        });
    }

    render() {
        const { classes, fullScreen } = this.props;        
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                    fullWidth
                    disableBackdropClick
                    scroll="body"
                >
                    <DialogTitle id="form-dialog-title">Relatório Dinâmico</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={9} md={6} lg={6} xl={7}>
                                <TextField
                                    label="Nome"
                                    fullWidth
                                    value={this.state.name}
                                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={5}>
                                <FormControl fullWidth disabled={this.props.dynamicReport !== null}>
                                    <InputLabel htmlFor="select-field-dynamic-report-view">Visão</InputLabel>
                                    <Select
                                        value={this.state.view}
                                        onChange={(event) => this.onChangeView(event.target.value)}
                                        inputProps={{ id: "select-field-dynamic-report-view" }}
                                    >
                                        {typeViewDynamicReport.map((view, index) => (
                                            <MenuItem value={view.id} key={index}>{view.description}</MenuItem>
                                        ))}
                                    </Select>
                                </ FormControl>
                            </Grid>
                            {this.state.view === '' ?
                                null
                                :
                                this.state.loading ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                            Buscando...
                                    </Typography>
                                        <CircularProgress size={20} color="secondary" />
                                    </Grid>
                                    :
                                    <Fragment>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <DefineColumns
                                                parameters={this.state.parameters}
                                                selectedColumns={this.state.selectedColumns}
                                                setSelectedColumns={this.setSelectedColumns}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <DefineFilters
                                                parameters={this.state.parameters}
                                                definedFilters={this.state.definedFilters}
                                                setDefinedFilters={this.setDefinedFilters}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <DefineGroups
                                                parameters={this.state.parameters}
                                                definedGroups={this.state.definedGroups}
                                                setDefinedGroups={this.setDefinedGroups}
                                            />
                                        </Grid>
                                    </Fragment>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary" disabled={this.state.saving}>
                            Cancelar
                        </Button>
                        <Button onClick={this.saveDynamicReport} color="primary" variant="contained" disabled={this.state.saving}>
                            {this.state.saving ? 'Salvando...' : 'Salvar'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

const addProperties = (inObj, outObj) => {
    for (let obj in inObj) {
        outObj[obj] = inObj[obj];
    }
}

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterDynamicReport.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterDynamicReport));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';

import Redirector from '../../Redirector';

import { validateAuth } from '../../api/consumer/auth';
import BadgeAllPasso from '../utils/BadgeAllPasso';

class Login extends React.Component {
    state = {
        loading: true,
        waitAuthenticate: false,
        email: '',
        password: '',
        rememberMe: false,
        hoverRecover: false,
        showPassword: false
    };

    signIn = async () => {
        try {
            if (this.state.email === '') {
                global.SnackBar.show('WARNING', 'E-mail é obrigatório!');
                return;
            }
            if (this.state.password === '') {
                global.SnackBar.show('WARNING', 'Senha é obrigatória!');
                return;
            }
            this.setState({ waitAuthenticate: true });
            await validateAuth(this.state.email, this.state.password, this.state.rememberMe);
            Redirector.main(this);
        } catch (error) {
            this.setState({ waitAuthenticate: false, password: '' });
            if (error.code === undefined) {
                global.SnackBar.show('ERROR', 'Sem conexão com o servidor de dados!');
            } else {
                global.SnackBar.show('ERROR', `${error.code} - ${error.message}`);
            }
        }
    };

    pressEnter = event => {
        if (event.keyCode === 13) {
            this.signIn();
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.bodyContainer}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Paper className={classes.paperContainer} elevation={15} square={true}>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                                <img src={require('../../resources/img/logo_perito.png')} alt="Logo AllPasso" width="50%" height="50%" />
                            </div>
                            <Typography variant="h6" component="h6" className={classes.fontHeader}>
                                Login
                            </Typography>
                            <Grid container className={classes.gridForm}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <TextField
                                        label="E-mail"
                                        className={classes.textField}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        fullWidth
                                        value={this.state.email}
                                        onChange={event => {
                                            this.setState({ email: event.target.value });
                                        }}
                                        onKeyDown={this.pressEnter}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <FormControl fullWidth className={classes.textField}>
                                        <InputLabel htmlFor="adornment-password">Senha</InputLabel>
                                        <Input
                                            id="adornment-password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.password}
                                            onChange={event => {
                                                this.setState({ password: event.target.value });
                                            }}
                                            onKeyDown={this.pressEnter}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Senha"
                                                        onClick={() => {
                                                            this.setState({ showPassword: !this.state.showPassword });
                                                        }}
                                                    >
                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.rememberMe}
                                                onChange={event => {
                                                    this.setState({ rememberMe: event.target.checked });
                                                }}
                                            />
                                        }
                                        label="Lembrar-me"
                                    />
                                </Grid>
                                {this.state.waitAuthenticate ? (
                                    <Grid item xs={12} className={classes.gridProgress}>
                                        <LinearProgress color="secondary" />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} className={classes.gridButtonLogin}>
                                        <Button variant="contained" color="primary" fullWidth onClick={this.signIn}>
                                            Entrar
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12} className={classes.gridRecover}>
                                    <Link to="/recover/send" className={classes.buttonRecover}>
                                        Esqueceu a senha?
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className={classes.fromText}>
                            Powered by <BadgeAllPasso />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    bodyContainer: {
        backgroundColor: '#e6e9ea',
        height: '100vh'
    },
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    fontHeader: {
        color: '#373737',
        marginLeft: '12px',
        marginTop: '10px'
    },
    fontLogo: {
        color: '#FFF',
        fontFamily: 'FontLogo',
        fontSize: '1.90rem',
        margin: '12px'
    },
    centered: {
        textAlign: 'center'
    },
    gridContainer: {
        height: '95vh',
        padding: '10px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paperContainer: {
        padding: '20px',
        borderRadius: '9px 9px 9px 9px'
    },
    gridForm: {
        padding: '15px'
    },
    gridItem: {
        marginBottom: '21px'
    },
    gridButtonLogin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    gridProgress: {
        marginTop: '25px',
        flexGrow: 1
    },
    paperFooter: {
        backgroundColor: '#1E004A',
        padding: '20px',
        borderRadius: '12px 12px 12px 12px'
    },
    fontMarq: {
        color: '#FFF',
        textAlign: 'center'
    },
    buttonRecover: {
        fontFamily: 'Arial',
        textDecoration: 'none',
        color: 'black',
        fontSize: '12px'
    },
    gridRecover: {
        paddingTop: '15px'
    },
    fromText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '0.875rem',
        color: '#6B6B6B',
        paddingTop: '20px'
    },
    textField: {
        flexBasis: 200,
        margin: theme.spacing.unit
    }
});

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);

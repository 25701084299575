import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Profile from '../../utils/Profile';
import ProcessPending from './ProcessPending';
import ProcessCompleted from './ProcessCompleted';
import SituationOfProcesses from './SituationOfProcesses';
import ProcessesEntry from './ProcessesEntry';
import ProcessesOut from './ProcessesOut';

export class Dashboard extends Component {
    render() {
        const { classes, user } = this.props;
        const isAdmin = Profile.isAdmin(user.profile);
        return (
            <Grid container className={classes.root} spacing={16} >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <Grid container className={classes.column} spacing={16} >
                        {isAdmin &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridPortlet} style={{ padding: '1rem' }}>
                                <ProcessPending />
                            </Grid>
                        }
                        {isAdmin &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridPortlet} style={{ padding: '1rem' }}>
                                <ProcessesEntry />
                            </Grid>
                        }
                        {isAdmin &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridPortlet} style={{ padding: '1rem' }}>
                                <ProcessesOut />
                            </Grid>
                        }                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <Grid container className={classes.column} spacing={16} >
                        {isAdmin &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridPortlet} style={{ padding: '1rem' }}>
                                <ProcessCompleted />
                            </Grid>
                        }
                        {isAdmin &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.gridPortlet} style={{ padding: '1rem' }}>
                                <SituationOfProcesses />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
};

const styles = {
    root: {
        padding: '1rem'
    },
    column: {
        padding: '0.1rem'
    },
    gridPortlet: {
        padding: '1rem'
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
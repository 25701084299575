import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Redirector from '../../Redirector';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { FiServer, FiUsers } from "react-icons/fi";
import { FaBuilding } from "react-icons/fa";

import ListCustomer from './Customer/ListCustomer';
import ListUser from '../business/User/ListUser';
import ListUserAccesses from './UserAccesses/ListUserAccesses';
import ListServer from './Server/ListServer';

export class Admin extends Component {

    state = {
        tab: 'SERVER'
    }

    render() {
        const { classes, user } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar variant="dense">
                        <IconButton onClick={() => { Redirector.main(this) }} style={{ color: 'white' }}>
                            <BackIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                            Admin: {this.props.user.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.gridListCustomer} spacing={16}>
                    <Grid item xs={12}>
                        <BottomNavigation
                            value={this.state.tab}
                            onChange={(event, newValue) => {
                                this.setState({
                                    tab: newValue
                                });
                            }}
                            showLabels
                            className={classes.root}
                        >
                            <BottomNavigationAction label="Servidor" value="SERVER" icon={<FiServer fontSize={28} />} />
                            <BottomNavigationAction label="Usuários" value="USERS" icon={<FiUsers fontSize={28} />} />
                            <BottomNavigationAction label="Clientes" value="CUSTOMERS" icon={<FaBuilding fontSize={28} />} />
                        </BottomNavigation>
                    </Grid>
                    {this.state.tab === 'SERVER' &&
                        <Fragment>
                            <Grid item xs={12}>
                                <ListServer className={classes.gridLists} isSuperAdmin />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>                            
                            <Grid item xs={12}>
                                <ListUserAccesses className={classes.gridLists} isSuperAdmin />
                            </Grid>
                        </ Fragment>
                    }
                    {this.state.tab === 'CUSTOMERS' &&
                        <Grid item xs={12}>
                            <ListCustomer className={classes.gridLists} />
                        </Grid>
                    }
                    {this.state.tab === 'USERS' &&
                        <Grid item xs={12}>
                            <ListUser className={classes.gridLists} user={user} isSuperAdmin />
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    },
    gridListCustomer: {
        padding: '0.5rem'
    },
    gridLists: {
        marginBottom: '1rem'
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Admin);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { saveCaseFile } from '../../../api/consumer/caseFile';

export class RegisterCaseFile extends Component {

    state = {        
        description: ''        
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.caseFile) {
                this.setState({                    
                    description: props.caseFile.description
                });
            } else {
                this.clearCaseFile();
            }

        }
    }

    clearCaseFile = () => {
        this.setState({
            description: ''            
        });
    }

    saveCaseFile = async () => {

        if (this.state.description === '') {
            global.SnackBar.show('WARNING', 'Informe o nome da finalidade');
            return;
        }

        try {
            let caseFile = {
                idCaseFile: (this.props.caseFile ? this.props.caseFile.idCaseFile : null),                
                description: this.state.description
            }
            await saveCaseFile(caseFile);
            global.SnackBar.show('SUCCESS', 'Registro salvo');            
            this.props.openOrClose();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">Cadastro Autos</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>                     
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label="Nome"
                                    fullWidth                                    
                                    value={this.state.description}
                                    onChange={(event) => { this.setState({ description: event.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.saveCaseFile} color="primary" variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterCaseFile.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterCaseFile));
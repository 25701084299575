import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { saveLaborCourt } from '../../../api/consumer/laborCourt';

export class RegisterLaborCourt extends Component {

    state = {
        code: '',
        name: ''        
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.laborCourt) {
                this.setState({
                    code: props.laborCourt.code,
                    name: props.laborCourt.name
                });
            } else {
                this.clearLaborCourt();
            }

        }
    }

    clearLaborCourt = () => {
        this.setState({
            code: '',
            name: ''
        });
    }

    saveLaborCourt = async () => {

        if (this.state.code === '') {
            global.SnackBar.show('WARNING', 'Informe o código da vara trabalhista');
            return;
        }

        if (this.state.name === '') {
            global.SnackBar.show('WARNING', 'Informe o nome da vara trabalhista');
            return;
        }

        try {
            let laborCourt = {
                idLaborCourt: (this.props.laborCourt ? this.props.laborCourt.idLaborCourt : null),
                code: this.state.code,
                name: this.state.name
            }
            await saveLaborCourt(laborCourt);
            global.SnackBar.show('SUCCESS', 'Registro salvo');            
            this.props.openOrClose();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="form-dialog-title">Cadastro Vara Trabalhista</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <TextField
                                    label="Código"
                                    fullWidth                                    
                                    value={this.state.code}
                                    onChange={(event) => { this.setState({ code: event.target.value }) }}
                                />
                            </Grid>                        
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <TextField
                                    label="Nome"
                                    fullWidth                                    
                                    value={this.state.name}
                                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.saveLaborCourt} color="primary" variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>                
            </div>
        );
    }
};

const styles = {
    root: {
        flexGrow: 1,
    }
}

RegisterLaborCourt.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(RegisterLaborCourt));
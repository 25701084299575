import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
//import DownloadIcon from '@material-ui/icons/SaveAltOutlined';
import { FaCloudUploadAlt, FaCheck, FaExclamation } from "react-icons/fa";
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

import Message from '../../utils/Message';

import cloudFilesAPI, { uploadCloudFile } from '../../../api/consumer/cloudFile';
import { formatDateTimeBRZ } from '../../utils/DataFormat';
import { formatSizeByte } from '../../utils/NumberFormat';

import pdfIcon from '../../../resources/img/files_icon/pdf.png';
import imageIcon from '../../../resources/img/files_icon/image.png';
import csvIcon from '../../../resources/img/files_icon/csv.png';
import txtIcon from '../../../resources/img/files_icon/txt.png';
import excelIcon from '../../../resources/img/files_icon/excel.png';
import wordIcon from '../../../resources/img/files_icon/word.png';
import zipIcon from '../../../resources/img/files_icon/zip.png';
import htmlIcon from '../../../resources/img/files_icon/html.png';
import pjcIcon from '../../../resources/img/files_icon/pjc.png';
import genericIcon from '../../../resources/img/files_icon/generic.png';

export class FileProcess extends Component {

    state = {
        cloudFiles: [],
        filesUploading: [],
        loading: true,
        saving: false,
        messageDelete: null
    }

    componentWillReceiveProps(props) {
        if (props.open) {
            if (props.process) {
                this.loadingCloudFiles(props);
            }
        }
    }

    loadingCloudFiles = async (props) => {
        try {
            this.setState({
                loading: true,
                filesUploading: []
            });

            const { process } = props;
            const response = await cloudFilesAPI('GET', { idProcess: process.idProcess });
            this.setState({
                cloudFiles: response.cloudFiles,
                tokenAccess: response.tokenAccess,
                loading: false
            });
            this.clearInpuFile();
        } catch (error) {
            this.setState({
                cloudFiles: [],
                loading: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    getIconExtension = (extension) => {
        switch (extension.toLowerCase()) {
            case 'txt':
                return <img src={txtIcon} alt="txt" />;
            case 'pdf':
                return <img src={pdfIcon} alt="pdf" />;
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'ico':
            case 'bmap':
                return <img src={imageIcon} alt="imagem" />;
            case 'csv':
                return <img src={csvIcon} alt="csv" />;
            case 'xls':
            case 'xlsm':
            case 'xlsx':
                return <img src={excelIcon} alt="Excel" />;
            case 'doc':
            case 'docx':
                return <img src={wordIcon} alt="Word" />;
            case 'zip':
            case 'rar':
            case 'gzip':
                return <img src={zipIcon} alt="zip" />;
            case 'html':
            case 'htm':
                return <img src={htmlIcon} alt="zip" />;
            case 'pjc':
                return <img src={pjcIcon} alt="pjc" />;
            default:
                return <img src={genericIcon} alt="arquvo" />;
        }
    }

    onFilesSelected = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0] !== null) {
                let filesUploading = this.state.filesUploading;
                for (let i = 0; i < e.target.files.length; i++) {
                    let file = e.target.files[i];
                    let messageError = null;
                    for (let x = 0; x < this.state.filesUploading.length; x++) {
                        let file2 = this.state.filesUploading[x];
                        if (file.name.toUpperCase() === file2.name.toUpperCase()) {
                            messageError = `Arquivo já existe`;
                            break;
                        }
                    }
                    if (!messageError) {
                        for (let file2 of this.state.cloudFiles) {
                            if (file.name.toUpperCase() === file2.name.toUpperCase()) {
                                messageError = `Arquivo já existe`;
                                break;
                            }
                        }
                    }
                    /*if (!messageError) {
                        if (file.size > 52428800) {
                            messageError = `Limite de 50 MB`;
                        }
                    }*/
                    let cloudFile = {
                        name: file.name,
                        size: file.size,
                        status: 'PEND',
                        percentage: 0,
                        file
                    }
                    if (messageError) {
                        cloudFile.status = 'ERROR';
                        cloudFile.message = messageError;
                    }
                    filesUploading.push(cloudFile);
                }
                this.setState({
                    filesUploading
                });
            }
        }
        this.clearInpuFile();
    }

    updateDataFile = (index, attr) => {
        let array = clone(this.state.filesUploading);
        let obj = clone(array[index]);
        obj = { ...obj, ...attr };
        array[index] = obj;
        this.setState({
            filesUploading: array
        });
    }

    saveCloudFile = async () => {
        try {
            this.setState({
                saving: true
            });
            const { process } = this.props;
            const filesUploading = this.state.filesUploading;
            if (filesUploading.length === 0) {
                global.SnackBar.show('WARNING', 'Nenhum arquivo para upload!');
                this.setState({
                    saving: false
                });
                return;
            }
            for (let i = 0; i < filesUploading.length; i++) {
                let file = filesUploading[i];
                if (file.status === 'ERROR') {
                    global.SnackBar.show('WARNING', 'Você tem arquivos com divergências!');
                    this.setState({
                        saving: false
                    });
                    return;
                }
            }
            let errorSave = false;
            for (let i = 0; i < filesUploading.length; i++) {
                this.updateDataFile(i, { status: 'UP', percentage: 0 });
                let file = filesUploading[i];
                const data = new FormData();
                data.append('file', file.file);
                data.append('idProcess', process.idProcess);
                const setProgress = (progress) => {
                    this.updateDataFile(i, { status: 'UP', percentage: progress });
                }
                try {
                    await uploadCloudFile(data, setProgress);
                    this.updateDataFile(i, { status: 'COMP' });
                } catch (error) {
                    errorSave = true;
                    this.updateDataFile(i, { status: 'ERROR', message: error.message });
                }
            }
            global.SnackBar.show('SUCCESS', 'Arquivos salvos com sucesso!');
            if (!errorSave) {
                this.setState({
                    saving: false
                }, () => {
                    this.loadingCloudFiles(this.props);
                });
            } else {
                this.setState({
                    saving: false
                });
            }
            this.clearInpuFile();
        } catch (error) {
            this.setState({
                saving: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    clearInpuFile = () => {
        document.getElementById('files-upload').value = '';
    }

    deleteCloudFile = async (cloudFile) => {
        try {
            const { process } = this.props;
            await cloudFilesAPI('DELETE', { idProcess: process.idProcess, idCloudFile: cloudFile.idCloudFile });
            global.SnackBar.show('SUCCESS', 'Arquivo deletado');
            this.loadingCloudFiles(this.props);
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    removeFileSelected = (index) => {
        let array = clone(this.state.filesUploading);
        array.splice(index, 1);
        this.setState({
            filesUploading: array
        });
    }

    showMessageDelete = (index, cloudFile = null) => {
        if (cloudFile.idCloudFile) {
            this.setState({
                messageDelete: {
                    onClose: () => this.setState({ messageDelete: null }),
                    title: 'Exclusão de Arquivo',
                    message: <span>Deseja realmente excluir o arquivo <b>“{cloudFile.name}”?</b><br /><span style={{ color: 'red' }}>Está operação será permanente</span></span>,
                    ok: () => this.deleteCloudFile(cloudFile),
                    cancel: true
                }
            });
        } else {
            this.removeFileSelected(index);
        }
    }

    downloadFile = async (cloudFile, preview = false) => {
        try {
            if (!cloudFile.idCloudFile) {
                global.SnackBar.show('WARNING', 'Arquivo pendente de upload');
                return;
            }

            if (preview) {
                window.open(cloudFile.urlDownload);
            } else {

                /*const { process } = this.props;
                const toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');
                const response = await downloadCloudFile(toUrlEncoded({ idProcess: process.idProcess, idCloudFile: cloudFile.idCloudFile }));
                const contentType = response.headers['content-type'];
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType, filename: cloudFile.name }));*/

                /*const response = await axios.get(cloudFile.urlDownload, {
                    responseType: 'blob',
                    onDownloadProgress: e => {
                        const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                        console.log(progress)
                    }
                });

                const contentType = response.headers['content-type'];
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType, filename: cloudFile.name }));

                const link = document.createElement('a');                
                link.href = url;                
                link.setAttribute('download', cloudFile.name);
                document.body.appendChild(link);
                link.click();*/
            }

        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    wordWrap = (word) => {
        let limit = 45;
        let newWord = '';
        for (let i = 0; i < word.length; i++) {
            if (i % limit === 0) {
                newWord = `${newWord}\n${word.substr(i, 1)}`
            } else {
                newWord = `${newWord}${word.substr(i, 1)}`
            }
        }
        return newWord;
    }

    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.openOrClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth
                    fullScreen={fullScreen}
                    disableBackdropClick={this.state.saving}
                >
                    <DialogTitle id="form-dialog-title">Arquivos
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => { this.inputFiles.click() }} disabled={this.state.saving}>
                            <AddIcon />
                        </Fab>
                        <input
                            id="files-upload"
                            name="files[]"
                            type="file"
                            onChange={this.onFilesSelected}
                            ref={instance => { this.inputFiles = instance }}
                            style={{ display: 'none' }}
                            multiple
                        />
                    </DialogTitle>
                    <DialogContent>
                        {this.state.loading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                    Buscando...
                                </Typography>
                                <CircularProgress size={20} color="secondary" />
                            </div>
                            :
                            this.state.cloudFiles.length > 0 || this.state.filesUploading.length > 0 ?
                                <Grid container spacing={0} direction="row" style={{ paddingTop: '1rem' }}>
                                    {this.state.cloudFiles.map((cloudFile, index) => (
                                        <this.File cloudFile={cloudFile} index={index} key={index} />
                                    ))}
                                    {this.state.filesUploading.map((cloudFile, index) => (
                                        <this.File cloudFile={cloudFile} index={index} key={index} />
                                    ))}
                                </Grid>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                        Sem arquivos
                                    </Typography>
                                </div>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem' }}>
                        <Button onClick={this.props.openOrClose} color="primary" disabled={this.state.saving}>
                            Sair
                        </Button>
                        <Button onClick={() => this.saveCloudFile()} color="primary" variant="contained" disabled={this.state.saving || this.state.loading}>
                            {this.state.saving ? 'Salvando...' : 'Salvar'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Message options={this.state.messageDelete} />
            </div>
        );
    }

    File = ({ cloudFile, index }) => (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {(index === 0 && (!cloudFile.status || this.state.cloudFiles.length === 0)) && <Divider />}
            <Grid container spacing={16} direction="row">
                <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                    <Button onClick={(e) => this.downloadFile(cloudFile, true)} color="inherit" style={{ width: '100%' }}>
                        <Grid container spacing={16} direction="row">
                            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {this.getIconExtension(getExtension(cloudFile.name))}
                            </Grid>
                            <Grid item xs={9} sm={9} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: '0.8rem', wordBreak: 'break-all' }} align="justify">
                                    {cloudFile.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    {formatSizeByte(cloudFile.size)}
                                </Typography>
                            </Grid>
                            {cloudFile.createdAt &&
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '0.8rem' }}>
                                        {formatDateTimeBRZ(cloudFile.createdAt)}
                                    </Typography>
                                </Grid>
                            }
                            {cloudFile.status === 'PEND' &&
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaCloudUploadAlt fontSize={35} color="#0032BA" />
                                </Grid>
                            }
                            {cloudFile.status === 'COMP' &&
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaCheck fontSize={35} color="#037329" />
                                </Grid>
                            }
                            {cloudFile.status === 'ERROR' &&
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ flex: 1 }}>
                                            <FaExclamation fontSize={22} color="#720118" />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Typography style={{ fontSize: '0.7rem' }}>
                                                {cloudFile.message}
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {cloudFile.status === 'UP' &&
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Progress
                                        type="circle"
                                        width={40}
                                        percent={cloudFile.percentage > 99 ? 99 : cloudFile.percentage}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Button>
                </Grid>
                {/*<Grid item xs={6} sm={6} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => this.downloadFile(cloudFile, false)} style={{ width: '100%', height: '100%' }} disabled={this.state.saving || !cloudFile.idCloudFile}>
                        <DownloadIcon />
                    </Button>
                </Grid>*/}
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => this.showMessageDelete(index, cloudFile)} style={{ width: '100%', height: '100%' }} disabled={this.state.saving}>
                        <DeleteIcon />
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </Grid>
    )
};

const getExtension = (fileName) => {
    let array = fileName.split('.');
    if (array.length > 0) {
        return array[array.length - 1];
    } else {
        return 'noformat';
    }
}

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    }
});

FileProcess.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(withStyles(styles)(FileProcess));

import React, { Component } from 'react';
import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, 'dd/MM/yyyy', { locale: this.locale });
    }
}

export default class DateTextField extends Component {

    render() {
        let date = this.props.value;
        if (date === '' || date === undefined) {
            date = null;
        }        
        if (date !== null) {
            date = new Date(date);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        return (
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                <div className="picker">
                    <DatePicker
                        keyboard
                        clearable
                        fullWidth={this.props.fullWidth}
                        label={this.props.label}
                        format="dd/MM/yyyy"
                        value={date}
                        onChange={(date) => {
                            if (date !== null) {
                                this.props.onChange(date);
                            } else {
                                this.props.onChange('');
                            }
                        }}
                        clearLabel="LIMPAR"
                        cancelLabel="CANCELAR"
                        mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                        animateYearScrolling={false}
                        autoOk
                        maxDate={new Date(2050, 1, 1)}
                        minDate={new Date(1990, 1, 1)}
                        invalidDateMessage="Data inválida"
                        maxDateMessage="Data máxima 01/01/2050"
                        minDateMessage="Data mínima 01/01/1990"
                        disableOpenOnEnter
                        onError={(_, error) => { this.props.onChange('') }}
                        disabled={this.props.disabled}
                    />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}
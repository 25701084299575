export const typeProfile = [{
    idType: 'A',
    dsType: 'Admin'
}, {
    idType: 'AP',
    dsType: 'Admin e perito'
}, {
    idType: 'APC',
    dsType: 'Admin, perito e calculista'
}, {
    idType: 'P',
    dsType: 'Perito'
}, {
    idType: 'PC',
    dsType: 'Perito e calculista'
}, {
    idType: 'C',
    dsType: 'Calculista'
}];

export const typeProfileNotSuperAdmin = () => {    
    return typeProfile.slice(1);
}

export const getDsTypeProfile = (idType) => {
    return typeProfile.find(type => type.idType === idType) || '';
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FileCaseIcon from '@material-ui/icons/NotesRounded';

import RegisterCaseFile from './RegisterCaseFile';
import Message from '../../utils/Message';
import TableResponsive from '../../utils/TableResponsive';

import { getCaseFile, deleteCaseFile } from '../../../api/consumer/caseFile';
import { formatDateTimeBRZ } from '../../utils/DataFormat';

export class ListCaseFile extends Component {

    state = {
        open: false,
        caseFiles: [],
        caseFile: null,
        messageDelete: null
    }

    componentWillMount() {
        this.loadingCaseFiles();
    }

    loadingCaseFiles = async () => {
        try {
            const response = await getCaseFile();
            this.setState({
                caseFiles: response.caseFiles
            });
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    data = (classes) => {
        var data = [];
        this.state.caseFiles.forEach(caseFile => {
            let dateCell = {                              
                description: caseFile.description,
                createdAt: formatDateTimeBRZ(new Date(caseFile.createdAt)),
                updatedAt: formatDateTimeBRZ(caseFile.updatedAt ? new Date(caseFile.updatedAt) : null),
                action: this.actions(caseFile)
            }
            data.push(dateCell);
        });
        return data;
    }

    actions = (caseFile) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.onEditCaseFile(caseFile)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => this.showMessageDelete(caseFile)}>
                <DeleteIcon />
            </IconButton>
        </div>
    )

    openOrClose = (caseFile = null) => {
        let open = !this.state.open;
        this.setState({
            open,
            caseFile,
        });
        if (!open) {
            this.loadingCaseFiles();
        }
    }

    showMessageDelete = (caseFile = null) => {
        this.setState({
            messageDelete: {
                onClose: () => this.setState({ messageDelete: null }),
                title: 'Exclusão de Autos',
                message: <span>Deseja realmente excluir o autos <b>“{caseFile.idCaseFile}: {caseFile.description}” ?</b></span>,
                ok: () => this.onDeleteCaseFile(caseFile),
                cancel: true
            }
        });
    }

    onEditCaseFile = (caseFile) => {
        this.openOrClose(caseFile);
    }

    onDeleteCaseFile = async (caseFile) => {
        try {
            await deleteCaseFile(caseFile.idCaseFile);
            global.SnackBar.show('SUCCESS', 'Registro deletado');
            this.loadingCaseFiles();
        } catch (error) {
            global.SnackBar.show('ERROR', error.message);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paperListCaseFile} elevation={1}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.9rem' }}>
                        <FileCaseIcon style={{marginRight: '0.6rem'}}/>
                        <Typography variant="h5" color="inherit">
                            Autos
                        </Typography>
                        <Fab size="small" color="secondary" aria-label="Add" style={{ marginLeft: '1rem' }} onClick={() => this.openOrClose()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <TableResponsive
                        columns={columns}
                        values={this.data(classes)}
                        showTotalRecords
                    />
                </Paper>
                <RegisterCaseFile open={this.state.open} openOrClose={this.openOrClose} caseFile={this.state.caseFile} />                
                <Message options={this.state.messageDelete} />
            </div>
        );
    }
};

const columns = [
    {
        id: 'description',        
        label: 'DESCRIÇÃO'
    },
    {
        id: 'createdAt',
        label: 'DATA CRIAÇÃO'
    },
    {
        id: 'updatedAt',        
        label: 'DATA ALTERAÇÃO'
    },
    {
        id: 'action',        
        label: ''
    }
]

const styles = {
    paperListCaseFile: {
        padding: '1rem'
    }
}

ListCaseFile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListCaseFile);

export default function open(reportId, params) {
    let url = `/report/${reportId}`;
    
    /*if (params) {
        params =  JSON.stringify(params);
        const paramsQuery = new URLSearchParams({query: params});
        url += `?${paramsQuery.toString()}`;
    }*/

    params =  JSON.stringify(params);
    window.sessionStorage.setItem(`@queryReport${reportId}`, params);        
    
    window.open(url, '_blank');
}
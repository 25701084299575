export const typeStatusProcess = [{
    idType: 'PEND',
    dsType: 'PENDENTE'
}, {
    idType: 'COMP',
    dsType: 'CONCLUÍDO'
}];

export const getDsTypeStatusProcess = (idType) => {
    return typeStatusProcess.find(type => type.idType === idType) || '';
}
import api from '../api';

async function cloudFile(action, param) {
    try {
        const response = await api.post('/cloudFile', {
            action,
            payload: param
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function uploadCloudFile(data, setProgress) {

    try {
        const response = await api.post('/cloudFile/upload', data, {
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                setProgress(progress);
            }
        });
        return response.data;
    } catch (error) {
        throw new Exception(error.response.status, error.response.data)
    }
}

export async function downloadCloudFile(data) {
    try {
        const response = await api.post('cloudFile/download', data, {
            responseType: 'blob',
            onDownloadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                console.log(progress)
            }
        });
        return response;
    } catch (error) {
        const message = await (new Response(error.response.data)).text();
        throw new Exception(error.response.status, message)
    }
}

function Exception(code, message) {
    this.code = code;
    this.message = message;
}

export default cloudFile;
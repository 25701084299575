import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getLaborCourt } from '../../api/consumer/laborCourt';

export class SelectLaborCourt extends Component {

    state = {
        laborCourts: [],
        loadingLaborCourts: true,
        idLaborCourt: ''
    }

    clickLaborCourts = false;

    loadingLaborCourts = async () => {
        try {
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts,
                loadingLaborCourts: false
            });
        } catch (error) {
            this.setState({
                laborCourts: [],
                loadingLaborCourts: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    }

    onClickLaborCourt = () => {
        if (this.state.laborCourts && !this.clickLaborCourts) {
            this.clickLaborCourts = true;
            this.loadingLaborCourts();
        }
    }

    render() {           
        const id = `ID${Math.trunc(Math.random() * 10000000)}`;   
        /*if (parseInt(this.props.value) > 0) {            
            this.onClickLaborCourt();
        }*/     
        return (
            <FormControl fullWidth={this.props.fullWidth} disabled={this.props.disabled}>
                <InputLabel htmlFor={id}>Vara Trabalhista</InputLabel>
                <Select
                    value={this.props.value}
                    onChange={this.props.onChange}
                    inputProps={{id}}
                    onClick={this.onClickLaborCourt}
                >
                    {this.props.emptyItem && <MenuItem value={''}></MenuItem>}
                    {this.state.loadingLaborCourts ?
                            <MenuItem value={''}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h6" color="inherit" style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: 'rgba(0, 0, 0, 0.65)', marginRight: '0.6rem' }}>
                                    Buscando...
                                </Typography>
                                <CircularProgress size={20} color="secondary" />
                            </div>
                        </MenuItem>
                        :
                        this.state.laborCourts.map((laborCourt, index) => (
                            <MenuItem value={laborCourt.idLaborCourt} key={index}>{laborCourt.code} - {laborCourt.name}</MenuItem>
                        ))
                    }
                </Select>
            </ FormControl>
        );
    }
};

export default SelectLaborCourt;

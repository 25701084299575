import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import OpenReport from '../../Report/OpenReport';
import Profile from '../../../utils/Profile';
import { filterUserActive } from '../../../utils/FilterList';

import { getLastDayMounth } from '../../../utils/DataFormat';
import DateTextField from '../../../utils/DateTextField';
import { getLaborCourt } from '../../../../api/consumer/laborCourt';
import { getUser } from '../../../../api/consumer/user';
import { getGoal } from '../../../../api/consumer/goal';

export class OpenR003 extends Component {
    state = {
        initialDateConclusion: this.getInitialDate(),
        finalDateConclusion: getLastDayMounth(this.getInitialDate()),
        laborCourts: [],
        users: [],
        goals: [],
        loadingUsers: true,
        loadingGoals: true,
        loadingLaborCourts: true,
        idArithmetician: '',
        idLaborCourt: '',
        idGoal: '',
        showCalculistaInativos: false
    };

    clickLaborCourts = false;
    clickUsers = false;
    clickGoals = false;

    getInitialDate() {
        let date = new Date();
        date.setDate(1);
        return date;
    }

    openReport = () => {
        let { initialDateConclusion, finalDateConclusion, idArithmetician, idLaborCourt, idGoal } = this.state;

        if (initialDateConclusion === '') {
            global.SnackBar.show('WARNING', 'Informe a data de início conclusão');
            return;
        }

        if (finalDateConclusion === '') {
            global.SnackBar.show('WARNING', 'Informe a data de fim conclusão');
            return;
        }

        initialDateConclusion.setHours(0);
        initialDateConclusion.setMinutes(0);
        initialDateConclusion.setSeconds(0);
        initialDateConclusion.setMilliseconds(0);
        finalDateConclusion.setHours(23);
        finalDateConclusion.setMinutes(59);
        finalDateConclusion.setSeconds(59);
        finalDateConclusion.setMilliseconds(999);

        const params = {
            initialDateConclusion: new Date(initialDateConclusion).toISOString(),
            finalDateConclusion: new Date(finalDateConclusion).toISOString()
        };

        if (idArithmetician) {
            const user = this.state.users.find(user => user.idUser === idArithmetician);
            if (user) {
                params.Arithmetician = user;
            }
        }

        if (idLaborCourt) {
            const laborCourt = this.state.laborCourts.find(laborCourt => laborCourt.idLaborCourt === idLaborCourt);
            if (laborCourt) {
                params.LaborCourt = laborCourt;
            }
        }

        if (idGoal) {
            const goal = this.state.goals.find(goal => goal.idGoal === idGoal);
            if (goal) {
                params.Goal = goal;
            }
        }

        OpenReport('R003', params);
    };

    loadingUsers = async () => {
        try {
            const response = await getUser();
            this.setState({
                users: response.users,
                loadingUsers: false
            });
        } catch (error) {
            this.setState({
                users: [],
                loadingUsers: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    loadingGoals = async () => {
        try {
            const response = await getGoal();
            this.setState({
                goals: response.goals,
                loadingGoals: false
            });
        } catch (error) {
            this.setState({
                goals: [],
                loadingGoals: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    loadingLaborCourts = async () => {
        try {
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts,
                loadingLaborCourts: false
            });
        } catch (error) {
            this.setState({
                laborCourts: [],
                loadingLaborCourts: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    onClickLaborCourt = () => {
        if (this.state.laborCourts && !this.clickLaborCourts) {
            this.clickLaborCourts = true;
            this.loadingLaborCourts();
        }
    };

    onClickArithmetician = () => {
        if (this.state.users && !this.clickUsers) {
            this.clickUsers = true;
            this.loadingUsers();
        }
    };

    onClickGoal = () => {
        if (this.state.goals && !this.clickGoals) {
            this.clickGoals = true;
            this.loadingGoals();
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Demonstrativo de Produtividade</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <DateTextField
                                    fullWidth
                                    label="Data Início"
                                    value={this.state.initialDateConclusion}
                                    onChange={date => this.setState({ initialDateConclusion: date })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <DateTextField
                                    fullWidth
                                    label="Data Fim"
                                    value={this.state.finalDateConclusion}
                                    onChange={date => this.setState({ finalDateConclusion: date })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-labor-court">Vara Trabalhista</InputLabel>
                                    <Select
                                        value={this.state.idLaborCourt}
                                        onChange={event => {
                                            this.setState({ idLaborCourt: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-labor-court' }}
                                        onClick={this.onClickLaborCourt}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        {this.state.loadingLaborCourts ? (
                                            <MenuItem value={''}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Arial',
                                                            fontSize: '1.05rem',
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                            marginRight: '0.6rem'
                                                        }}
                                                    >
                                                        Buscando...
                                                    </Typography>
                                                    <CircularProgress size={20} color="secondary" />
                                                </div>
                                            </MenuItem>
                                        ) : (
                                            this.state.laborCourts.map((laborCourt, index) => (
                                                <MenuItem value={laborCourt.idLaborCourt} key={index}>
                                                    {laborCourt.code} - {laborCourt.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-arithmetician">Calculista</InputLabel>
                                    <Select
                                        value={this.state.idArithmetician}
                                        onChange={event => {
                                            this.setState({ idArithmetician: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-arithmetician' }}
                                        onClick={this.onClickArithmetician}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <MenuItem
                                                value={''}
                                                style={{
                                                    width: '20%'
                                                }}
                                            ></MenuItem>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '80%' }}>
                                                <Typography
                                                    variant="h6"
                                                    color="inherit"
                                                    style={{
                                                        textAlign: 'center',
                                                        fontFamily: 'Arial',
                                                        fontSize: '0.9rem',
                                                        color: 'rgba(0, 0, 0, 0.65)',
                                                        marginRight: '0.6rem'
                                                    }}
                                                >
                                                    Mostrar inativos
                                                </Typography>

                                                <Switch
                                                    checked={this.state.showCalculistaInativos}
                                                    onChange={e =>
                                                        this.setState({
                                                            showCalculistaInativos: e.target.checked
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {this.state.loadingUsers ? (
                                            <MenuItem value={''}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Arial',
                                                            fontSize: '1.05rem',
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                            marginRight: '0.6rem'
                                                        }}
                                                    >
                                                        Buscando...
                                                    </Typography>
                                                    <CircularProgress size={20} color="secondary" />
                                                </div>
                                            </MenuItem>
                                        ) : (
                                            filterUserActive(this.state.users, this.state.showCalculistaInativos).map(
                                                (user, index) =>
                                                    Profile.isArithmetician(user.profile) && (
                                                        <MenuItem value={user.idUser} key={index}>
                                                            <span
                                                                style={{
                                                                    textDecoration: user.inactive ? 'line-through' : undefined
                                                                }}
                                                            >
                                                                {user.name}
                                                            </span>
                                                        </MenuItem>
                                                    )
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-goal">Finalidade</InputLabel>
                                    <Select
                                        value={this.state.idGoal}
                                        onChange={event => {
                                            this.setState({ idGoal: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-goal' }}
                                        onClick={this.onClickGoal}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        {this.state.loadingGoals ? (
                                            <MenuItem value={''}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Arial',
                                                            fontSize: '1.05rem',
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                            marginRight: '0.6rem'
                                                        }}
                                                    >
                                                        Buscando...
                                                    </Typography>
                                                    <CircularProgress size={20} color="secondary" />
                                                </div>
                                            </MenuItem>
                                        ) : (
                                            this.state.goals.map((goal, index) => (
                                                <MenuItem value={goal.idGoal} key={index}>
                                                    {goal.description}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Fab variant="extended" color="secondary" className={classes.fab} onClick={this.openReport}>
                                    <PrintIcon style={{ marginRight: '0.5rem' }} />
                                    Gerar
                                </Fab>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        padding: '1rem'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    fab: {
        margin: theme.spacing.unit
    }
});

OpenR003.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenR003);

export const typeStatusSystemInvoice = [{
    id: 'PEND',
    description: 'PENDENTE'
}, {
    id: 'PAID',
    description: 'PAGO'
}];

export const getTypeStatusSystemInvoice = (id) => {
    return typeStatusSystemInvoice.find(type => type.id === id);
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';

import OpenReport from '../../Report/OpenReport';

import { getLaborCourt } from '../../../../api/consumer/laborCourt';

export class OpenR004 extends Component {
    state = {
        idLaborCourt: '',
        loadingLaborCourts: true,
        tpSort: 'idProcess',
        laborCourts: []
    };

    clickLaborCourts = false;

    openReport = () => {
        let { tpSort, idLaborCourt } = this.state;

        let params = {
            tpSort
        };

        if (idLaborCourt) {
            const laborCourt = this.state.laborCourts.find(laborCourt => laborCourt.idLaborCourt === idLaborCourt);
            if (laborCourt) {
                params.LaborCourt = laborCourt;
            }
        }

        OpenReport('R004', params);
    };

    loadingLaborCourts = async () => {
        try {
            const response = await getLaborCourt();
            this.setState({
                laborCourts: response.laborCourts,
                loadingLaborCourts: false
            });
        } catch (error) {
            this.setState({
                laborCourts: [],
                loadingLaborCourts: false
            });
            global.SnackBar.show('ERROR', error.message);
        }
    };

    onClickLaborCourt = () => {
        if (this.state.laborCourts && !this.clickLaborCourts) {
            this.clickLaborCourts = true;
            this.loadingLaborCourts();
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Demonstrativo Fechamento de Honorários</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-labor-court">Vara Trabalhista</InputLabel>
                                    <Select
                                        value={this.state.idLaborCourt}
                                        onChange={event => {
                                            this.setState({ idLaborCourt: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-labor-court' }}
                                        onClick={this.onClickLaborCourt}
                                    >
                                        <MenuItem value={''} />
                                        {this.state.loadingLaborCourts ? (
                                            <MenuItem value={''}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontFamily: 'Arial',
                                                            fontSize: '1.05rem',
                                                            color: 'rgba(0, 0, 0, 0.65)',
                                                            marginRight: '0.6rem'
                                                        }}
                                                    >
                                                        Buscando...
                                                    </Typography>
                                                    <CircularProgress size={20} color="secondary" />
                                                </div>
                                            </MenuItem>
                                        ) : (
                                            this.state.laborCourts.map((laborCourt, index) => (
                                                <MenuItem value={laborCourt.idLaborCourt} key={index}>
                                                    {laborCourt.code} - {laborCourt.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="select-type-sort">Ordenação</InputLabel>
                                    <Select
                                        value={this.state.tpSort}
                                        onChange={event => {
                                            this.setState({ tpSort: event.target.value });
                                        }}
                                        inputProps={{ id: 'select-type-sort' }}
                                        onClick={this.onClickLaborCourt}
                                    >
                                        <MenuItem value="idProcess">Processo</MenuItem>
                                        <MenuItem value="receiptDate">Data de Recebimento</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Fab variant="extended" color="secondary" className={classes.fab} onClick={this.openReport}>
                                    <PrintIcon style={{ marginRight: '0.5rem' }} />
                                    Gerar
                                </Fab>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        padding: '1rem'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    fab: {
        margin: theme.spacing.unit
    }
});

OpenR004.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenR004);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class Login extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid className={classes.root}>
                <Grid container>
                    <Grid item xs={12} className={classes.gridContainer}>
                        <CircularProgress className={classes.white} />
                    </Grid>
                    <Grid item xs={12} className={classes.gridContainer}>
                        <Typography variant="h6" component="h5" className={classes.white} color="primary">
                            Aguarde um momento...
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: theme.palette.primary.main
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '50px'
    },
    white: {
        color: 'white'
    }
});

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import { formatDateTimeBRZ, formatDateBRZ, formatDateExtBRZ } from '../../../../utils/DataFormat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import paymentProcessAPI from '../../../../../api/consumer/paymentProcess';
import movementsOfProcessAPI from '../../../../../api/consumer/movementsOfProcess';
import Profile from '../../../../utils/Profile';

import { getOneUser } from '../../../../../api/consumer/user';
import { getNameMonth } from '../../../../utils/DataFormat';
import { formatMoneySymbol } from '../../../../utils/NumberFormat';

export class R002 extends Component {
    state = {
        loading: true,
        error: null,
        paymentProcess: null,
        arithmetician: null,
        movementsOfProcesses: [],
        paymentTotal: 0
    };

    componentWillMount() {
        this.loadingPayment();
    }

    loadingPayment = async () => {
        try {
            let { idPaymentProcess, month, year, idArithmetician } = this.props.params;

            //Procura por calculista, mês, ano  caso o id do processamento não seja passado
            if (idPaymentProcess === undefined || idPaymentProcess === '') {
                if (month === undefined || month === '') {
                    throw Object('Mês é parâmetro obrigatório.');
                }
                if (year === undefined || year === '') {
                    throw Object('Ano é parâmetro obrigatório.');
                }
                if (idArithmetician === undefined || idArithmetician === '') {
                    throw Object('Calculista é parâmetro obrigatório.');
                }

                if (!Profile.isAdmin(this.props.user.profile)) {
                    idArithmetician = this.props.user.idUser;
                }

                const response = await getOneUser(idArithmetician);
                let arithmetician = response.user;
                if (!arithmetician) {
                    throw Object(`Calculista: ${idArithmetician} - não encontrado`);
                }

                month = parseInt(month);
                year = parseInt(year);
                idArithmetician = parseInt(idArithmetician);

                let param = {
                    month,
                    year,
                    idArithmetician
                };

                const responsePayment = await paymentProcessAPI('GET', param);
                let paymentProcess = responsePayment.paymentProcess;
                if (paymentProcess) {
                    param = { idPaymentProcess: paymentProcess.idPaymentProcess };
                } else {
                    param = {
                        statusProcessMovement: 'COMP',
                        paid: 'PEND',
                        idPaymentProcess: null,
                        isAmountPaid: true,
                        concluisionMonthProcessMovement: month,
                        concluisionYearProcessMovement: year,
                        idArithmetician
                    };
                }

                const responseMovements = await movementsOfProcessAPI('GET', param);
                let movementsOfProcesses = responseMovements.movementsOfProcesses;
                let paymentTotal = 0;
                movementsOfProcesses.forEach(movementsOfProcess => {
                    paymentTotal += movementsOfProcess.amountPaid;
                });

                this.setState({
                    paymentProcess,
                    arithmetician,
                    movementsOfProcesses,
                    paymentTotal,
                    loading: false
                });
            } else {
                idPaymentProcess = parseInt(idPaymentProcess);
                const param = { idPaymentProcess };
                const response = await paymentProcessAPI('GET', param);
                let paymentProcess = response.paymentProcess;
                if (!paymentProcess) {
                    throw Object(`Processamento: ${idPaymentProcess} - não encontrado`);
                }

                const responseMovements = await movementsOfProcessAPI('GET', param);
                let movementsOfProcesses = responseMovements.movementsOfProcesses;
                if (!(movementsOfProcesses.length > 0)) {
                    throw Object(`Processamento: ${idPaymentProcess} - nenhum movimento encontrado, entre em contato com o suporte.`);
                }

                let paymentTotal = 0;
                movementsOfProcesses.forEach(movementsOfProcess => {
                    paymentTotal += movementsOfProcess.amountPaid;
                });

                this.setState({
                    paymentProcess,
                    movementsOfProcesses,
                    paymentTotal,
                    loading: false
                });
            }
        } catch (error) {
            let descError = 'Erro ao consultar pagamentos';
            if (error !== undefined) {
                if (error.message !== undefined) {
                    descError = error.message;
                } else {
                    descError = error;
                }
            }
            this.setState({
                loading: false,
                error: descError
            });
        }
    };

    render() {
        const { user, person } = this.props;
        const textCellHeader = styles.textCellHeader;
        const textCellRow = styles.textCellRow;

        let closedProcessing = false;
        if (this.state.paymentProcess) {
            closedProcessing = true;
        }

        let reportName = null;
        let arithmetician = null;
        let month = null;
        let year = null;
        if (closedProcessing) {
            reportName = 'Demonstrativo de Pagamento';
            arithmetician = this.state.paymentProcess.Arithmetician;
            month = this.state.paymentProcess.month;
            year = this.state.paymentProcess.year;
        } else {
            reportName = 'Prévia Demonstrativo de Pagamento';
            arithmetician = this.state.arithmetician;
            month = this.props.params.month;
            year = this.props.params.year;
        }

        const { showSignature } = this.props.params;

        if (this.state.error != null) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Erro ao buscar pagamento: {JSON.stringify(this.state.error)}
                    </Typography>
                </Paper>
            );
        } else if (this.state.loading) {
            return (
                <Paper
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', overflow: 'hidden' }}
                >
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '1.05rem', color: '#000', marginRight: '0.6rem' }}
                    >
                        Buscando pagamento...
                    </Typography>
                    <CircularProgress size={20} color="secondary" />
                </Paper>
            );
        } else {
            return (
                <PDFViewer width="100%" height="100%" style={{ border: 0, padding: 0 }}>
                    <Document title={reportName}>
                        <Page size="A4" orientation="portrait" style={styles.page}>
                            <View style={styles.viewTitle}>
                                {person.logo && (
                                    <View style={{ flex: 1 }}>
                                        <Image style={styles.imageLogo} src={person.logo} />
                                    </View>
                                )}
                                <View style={{ flex: 3, display: 'flex' }}>
                                    <Text style={styles.textReportName}>{reportName}</Text>
                                    <Text style={styles.textSocialReason}>{person.socialReason}</Text>
                                    <Text style={styles.textAdditionalDescription}>{person.additionalDescription}</Text>
                                    <Text style={styles.textAdditionalDescription}>CNPJ: {person.registrationNumber}</Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.telephone} / {person.cellPhone}
                                    </Text>
                                    <Text style={styles.textAdditionalDescription}>
                                        {person.city} - {person.province}
                                    </Text>
                                </View>
                                {person.logo && <View style={{ flex: 1 }} />}
                            </View>
                            <View style={styles.viewArithmetician}>
                                <Text style={styles.textArithmetician}>Calculista: {arithmetician.name}</Text>
                            </View>
                            <View style={styles.viewMonthYear}>
                                <Text style={styles.textMonthYear}>
                                    Período: {getNameMonth(month).dsMonth} / {year}
                                </Text>
                            </View>
                            <View style={styles.viewInfo}>
                                {closedProcessing ? (
                                    <React.Fragment>
                                        <Text style={styles.textParam}>Número do Processamento: {this.state.paymentProcess.idPaymentProcess}</Text>
                                        <Text style={styles.textParam}>
                                            Data do Processamento: {formatDateTimeBRZ(this.state.paymentProcess.createdAt)}
                                        </Text>
                                    </React.Fragment>
                                ) : (
                                    <Text style={styles.textIssued}>*Processamento pendente</Text>
                                )}
                                <Text style={styles.textIssued}>
                                    Emitido por: {user.name} - {formatDateTimeBRZ(new Date())}
                                </Text>
                            </View>
                            <View fixed style={styles.tableHeader}>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Vara Trabalhista</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Número</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Reclamante</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 2 }}>
                                    <Text>Reclamado</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 1 }}>
                                    <Text>Finalidade</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 1 }}>
                                    <Text>Dt.Conclusão</Text>
                                </View>
                                <View style={{ ...textCellHeader, flex: 1 }}>
                                    <Text>Valor Pago</Text>
                                </View>
                            </View>
                            {this.state.movementsOfProcesses.map((movementsOfProcess, index) => (
                                <View style={styles.tableRow} key={index} wrap={false}>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.nameLoaborCourt}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.number}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.claimant}</Text>
                                    <Text style={{ ...textCellRow, flex: 2 }}>{movementsOfProcess.claimed}</Text>
                                    <Text style={{ ...textCellRow, flex: 1 }}>{movementsOfProcess.descriptionGoal}</Text>
                                    <Text style={{ ...textCellRow, flex: 1 }}>
                                        {formatDateBRZ(movementsOfProcess.concluisionDateProcessMovement)}
                                    </Text>
                                    <Text style={{ ...textCellRow, flex: 1 }}>{formatMoneySymbol(movementsOfProcess.amountPaid)}</Text>
                                </View>
                            ))}
                            <View style={styles.tableRowTotal} wrap={false}>
                                <Text style={{ ...textCellRow, textAlign: 'left', flex: 10, fontSize: 7 }}>
                                    Realizado(s): {this.state.movementsOfProcesses.length}
                                </Text>
                                <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>TOTAL</Text>
                                <Text style={{ ...textCellRow, flex: 1, fontSize: 7 }}>{formatMoneySymbol(this.state.paymentTotal)}</Text>
                            </View>
                            {showSignature && closedProcessing && (
                                <View wrap={false}>
                                    <View style={styles.viewSignatures}>
                                        <View style={styles.viewSignature}>
                                            <Text style={styles.textSignatureName}>EMPREGADOR: {person.socialReason.toString().toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.viewSignature}>
                                            <Text style={styles.textSignatureName}>COLABORADOR: {arithmetician.name.toString().toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.viewDeclare}>
                                        {person.city} - {person.province}, {formatDateExtBRZ(new Date())}
                                    </Text>
                                </View>
                            )}
                            <View
                                fixed
                                style={styles.viewPageNumber}
                                render={() => (
                                    <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <View style={{ flex: 1, paddingLeft: 15 }}>
                                            <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <View>
                                                    <Image style={styles.imageAllPasso} src="/images/logo_64X64.png" />
                                                </View>
                                                <View style={{ paddingLeft: 3 }}>
                                                    <Text>AllPasso Software {'<allpasso.com.br>'}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text
                                                style={styles.textPageNumber}
                                                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                            />
                                        </View>
                                        <View style={{ flex: 1 }} />
                                    </View>
                                )}
                            />
                        </Page>
                    </Document>
                </PDFViewer>
            );
        }
    }
}

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        backgroundColor: '#FFF',
        padding: 20,
        paddingBottom: 30
    },
    viewTitle: {
        padding: 7,
        textAlign: 'center',
        border: '1 solid #000',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row'
    },
    imageLogo: {
        width: 80
    },
    textReportName: {
        fontSize: 12
    },
    textSocialReason: {
        fontSize: 8
    },
    textAdditionalDescription: {
        fontSize: 8
    },
    viewInfo: {
        marginTop: 5,
        marginBottom: 5,
        padding: 3,
        textAlign: 'left',
        border: '1 solid #000',
        borderRadius: 4
    },
    textIssued: {
        fontSize: 8
    },
    viewArithmetician: {
        marginTop: 8,
        marginBottom: 1,
        padding: 1,
        textAlign: 'left'
    },
    viewMonthYear: {
        marginTop: 1,
        marginBottom: 3,
        paddingLeft: 1,
        paddingRight: 1,
        textAlign: 'left'
    },
    textArithmetician: {
        fontSize: 13
    },
    textMonthYear: {
        fontSize: 9
    },
    textParam: {
        fontSize: 8
    },
    viewPageNumber: {
        position: 'absolute',
        fontSize: 6,
        bottom: 20,
        left: 20,
        right: 20
    },
    textPageNumber: {
        textAlign: 'center',
        color: '#000'
    },
    imageAllPasso: {
        width: 10
    },
    tableHeader: {
        paddingTop: 5,
        paddingBottom: 4,
        paddingLeft: 1,
        paddingRight: 1,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #000',
        borderRadius: 4
    },
    tableRow: {
        padding: 2,
        display: 'flex',
        flexDirection: 'row'
    },
    tableRowTotal: {
        marginTop: 3,
        padding: 3,
        display: 'flex',
        flexDirection: 'row',
        border: '1 solid #000',
        borderRadius: 4
    },
    textCellHeader: {
        fontSize: 7,
        display: 'flex',
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2
    },
    textCellRow: {
        fontSize: 6,
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2
    },
    viewSignatures: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 2,
        paddingRight: 2
    },
    viewSignature: {
        paddingTop: 70,
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10
    },
    textSignatureName: {
        textAlign: 'center',
        fontSize: 6,
        paddingTop: 6,
        paddingBottom: 3,
        borderTop: '1 solid #000'
    },
    viewDeclare: {
        textAlign: 'center',
        flex: 1,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 8
    }
});

export default R002;
